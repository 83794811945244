import React, { useState, useEffect } from "react"

// components
import Sidebar from "./Sidebar"
import Nav from "./Nav"

// styles
import "../styles/projects/projects.css"

// icons
import { BsThreeDotsVertical } from "react-icons/bs"
import { LiaDumbbellSolid } from "react-icons/lia";
import { FaSearch } from "react-icons/fa"
import { IoClose, IoFolderOpenOutline } from "react-icons/io5"
import AuthService from "../services/auth"

// Global context
import { useGlobalContext } from "../context/Context"
import Nodata from "./Nodata"

const ProjectsPage = () => {
  const {
    API_URL,
    searchQuery,
    itemsPerPage,
    currentPage,
    handleSearch,
    handlePage,
    isPopupOpen,
    openPopup,
    closePopup,
    message,
    setMessage
  } = useGlobalContext()

  const [projects, setProjects] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [departments, setDepartments] = useState([])
  const [currentProject, setCurrentProject] = useState({})
  const [isEditingProject, setIsEditingProject] = useState(false)
  const [user, setUser] = useState([])
  const [clients, setClients] = useState([])
  const [statuses, setStatuses] = useState([])
  const [selectedProject, setSelectedProject] = useState(null)

  // Access token
  const accessToken = AuthService.getToken();

  const sortedProjects = [...projects].sort((a, b) => {
    const nameA = a.name.toLowerCase()
    const nameB = b.name.toLowerCase()
    return nameA.localeCompare(nameB)
  });

  const filteredProjects =
    sortedProjects.length > 0 &&
    sortedProjects.filter((project) =>
      project ? project.name.toLowerCase().includes(searchQuery.toLowerCase()) : false
    );

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, filteredProjects.length)
  const currentProjects =
    filteredProjects.length > 0 && filteredProjects.slice(startIndex, endIndex)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [projectsRes, departmentRes, userRes, clientsRes, statusRes] = await Promise.all([
          fetch(`${API_URL}/api/project_list/`, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${accessToken}`
            }
          }),

          fetch(`${API_URL}/api/list_departments/`, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${accessToken}`
            }
          }),

          fetch(`${API_URL}/api/get_user_info/`, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${accessToken}`
            }
          }),

          fetch(`${API_URL}/api/get_companies/`, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${accessToken}`
            }
          }),

          fetch(`${API_URL}/api/get_status/`, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${accessToken}`
            }
          })
        ])
        const projectsData = await projectsRes.json()
        const departmentData = await departmentRes.json()
        const userData = await userRes.json()
        const clientsData = await clientsRes.json()
        const statusData = await statusRes.json()

        setProjects(projectsData)
        setDepartments(departmentData)
        setUser(userData)
        setClients(clientsData.client)
        setStatuses(statusData.status)
        setIsLoading(false)

      } catch (error) {
        console.log(error)
        setIsLoading(false)
      }
    }
    fetchData()
  }, [])

  const handleEdit = (project) => {
    const projectToEdit = {
      ...project,
      company_name: project.company ? project.company.name : "",
      department_name: project.department_name ? project.department_name : "",
      start_date: project.start_date ? project.start_date.split('T')[0] : "",
      end_date: project.end_date ? project.end_date.split('T')[0] : "",
      status_name: project.status_name ? project.status_name : ""
    }
    setCurrentProject(projectToEdit)
    openPopup()
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target

    if (name === 'department_name') {
      const selectedDepartment = departments.find(dept => dept.name === value)
      setCurrentProject((prevProject) => ({
        ...prevProject,
        department_name: selectedDepartment ? selectedDepartment.name : value
      }))
    } else if (name === "status_name") {
      setCurrentProject((prevProject) => ({
        ...prevProject,
        status_name: value
      }))
    } else {
      setCurrentProject((prevProject) => ({
        ...prevProject,
        [name]: value
      }))
    }
  }

  const formatDateToYYYYMMDD = (dateStr) => {
    const date = new Date(dateStr)
    const year = date.getFullYear()
    const month = (`0${date.getMonth() + 1}`).slice(-2)
    const day = (`0${date.getDate()}`).slice(-2)
    return `${year}-${month}-${day}`
  }

  const handleSaveChanges = async (e) => {
    e.preventDefault()
    setIsEditingProject(true)

    const selectedCompany = clients.find(client => client.company_name === currentProject.company_name)

    const projectPayload = {
      ...currentProject,
      company: selectedCompany ? selectedCompany.id : currentProject.company_name,
      start_date: formatDateToYYYYMMDD(currentProject.start_date),
      end_date: formatDateToYYYYMMDD(currentProject.end_date),
    };

    try {
      const res = await fetch(`${API_URL}/api/project_update/${currentProject.slug}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(projectPayload),
      });

      if (!res.ok) {
        const errorData = await res.json()
        setMessage(res.statusText)
        setIsEditingProject(false)
        console.error('Response error data:', errorData)
      }

      const updatedProject = await res.json()

      setProjects(prevProjects =>
        prevProjects.map(project =>
          project.slug === updatedProject.slug ? updatedProject : project
        )
      )
      setIsEditingProject(false)
      closePopup()
    } catch (error) {
      console.log(error)
      setIsEditingProject(false)
    }
  }

  const getStatusStyle = (status_name) => {
    const status = statuses.find(status => status.name === status_name)
    return status ? { color: status.color_code } : ''
  }

  // project details
  const handleViewDetails = (project) => {
    openPopup()
    setSelectedProject(project)
  }

  return (
    <section className="projects">
      {user.position_name === 'Operations' && (
        <>
          {isPopupOpen && currentProject && (
            <div className="popup">
              <div className="edit-project">
                <div className="title">
                  <h3>Edit Project</h3>
                  <div className="icon">
                    <button onClick={closePopup}>
                      <IoClose />
                    </button>
                  </div>
                </div>
                <form onSubmit={handleSaveChanges}>
                  {message && (
                    <p className="message">{message}</p>
                  )}
                  <div className="input-fields">
                    <div className="first-field-col">
                      <div className="field">
                        <label>Company Name</label>
                        <select
                          name="company_name"
                          value={currentProject.company_name || ""}
                          onChange={handleInputChange}
                        >
                          <option disabled>Select a client</option>
                          {clients.map((client) => (
                            <option key={client.id} value={client.company_name}>
                              {client.company_name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="field">
                        <label>Project Name</label>
                        <input
                          type="text"
                          name="name"
                          value={currentProject.name || ""}
                          onChange={handleInputChange}
                          placeholder="Project name"
                        />
                      </div>
                      <div className="field">
                        <label>Contact person email</label>
                        <input
                          type="email"
                          name="contact_person_email"
                          value={currentProject.contact_person_email || ""}
                          onChange={handleInputChange}
                          placeholder="Contact person email"
                        />
                      </div>
                      <div className="field">
                        <label>Employee Department</label>
                        <select
                          name="department_name"
                          value={currentProject.department_name || ""}
                          onChange={handleInputChange}
                        >
                          <option disabled>Select a department</option>
                          {departments.map((department) => (
                            <option key={department.id} value={department.name}>
                              {department.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="field">
                        <label>Start Date</label>
                        <input
                          type="date"
                          name="start_date"
                          value={currentProject.start_date || ""}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>

                    <div className="first-field-col">
                      <div className="field">
                        <label>End Date</label>
                        <input
                          type="date"
                          name="end_date"
                          value={currentProject.end_date || ""}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="field">
                        <label>Status</label>
                        <select
                          name="status_name"
                          value={currentProject.status_name || ""}
                          onChange={handleInputChange}
                        >
                          <option disabled>Select a status</option>
                          {statuses.map((status) => (
                            <option key={status.id} value={status.name}>
                              {status.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="field">
                        <label>Description</label>
                        <textarea
                          name="description"
                          value={currentProject.description || ""}
                          onChange={handleInputChange}
                          placeholder="Project Description"
                        ></textarea>
                      </div>
                    </div>
                  </div>

                  <div className="btns">
                    <button type="button" className="cancel-btn" onClick={closePopup}>
                      Discard changes
                    </button>
                    <button type="submit" className="add-btn">
                      {isEditingProject ? "Saving" : "Save Changes"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}
        </>
      )}

      {user.position_name === 'Director' && (
        <>
          {isPopupOpen && selectedProject && (
            <>
              <div className="project-details-popup">
                <div className="project-details">
                  <div className="project-row">
                    <div className="icon">
                      <IoFolderOpenOutline />
                    </div>
                    <a href={`/project-details/${selectedProject.slug}`} className="project-details-link">
                      Project details
                    </a>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}

      <div className="row">
        <div className="col col-1">
          <Sidebar />
        </div>

        <div className="col col-2">
          <section className="contents">
            <div>
              <Nav />
            </div>
            <div className="underline"></div>
          </section>
          <div className="contents-container">

            <div className='title-contents'>
              <div className='top'>
                <div className="col-title">
                  <h4>Projects</h4>
                  <p>{projects.length} Project(s)</p>
                </div>
                <div className="input-fields">
                  <div className="input">
                    <input
                      type="text"
                      value={searchQuery}
                      onChange={handleSearch}
                      placeholder='Project Name....'
                    />
                    <div className="icon">
                      <FaSearch />
                    </div>
                  </div>
                </div>
                {user.position_name === 'Operations' && (
                  <div className='col-title'>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://projects.csrlimited.com/"
                      className="add-project-link"
                    >
                      <LiaDumbbellSolid />
                      <span>Add New Project</span>
                    </a>

                  </div>
                )}
              </div>
            </div>

            <div className="table-contents">
              {isLoading ? (
                <div className="loading-container">
                  <div className="loading">Loading</div>
                  <div className="loading-dots">
                    <span className="loading-dot">.</span>
                    <span className="loading-dot">.</span>
                    <span className="loading-dot">.</span>
                  </div>
                </div>
              ) : (
                <>
                  <table>
                    <thead>
                      <tr>
                        <th>Project ID</th>
                        <th>Project Name</th>
                        <th>Department</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Status</th>
                        {(user.position_name === 'Operations' || user.position_name === 'Director') && (
                          <th>Action</th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {currentProjects && currentProjects.length > 0 ? (
                        currentProjects.map((project) => (
                          <tr key={project.id}>
                            <td className="hidden-table-col">{project.id.slice(0, 10)}</td>
                            <td>{project.name}</td>
                            <td className="hidden-table-col">{project.department_name ? project.department_name : "No department"}</td>
                            <td>{project.start_date}</td>
                            <td>{project.end_date}</td>
                            <td style={getStatusStyle(project.status_name)}>{project.status_name}</td>
                            {user.position_name === 'Operations' && (
                              <td>
                                <button onClick={() => handleEdit(project)}>
                                  <BsThreeDotsVertical />
                                </button>
                              </td>
                            )}
                            {user.position_name === 'Director' && (
                              <td>
                                <button onClick={() => handleViewDetails(project)}>
                                  <BsThreeDotsVertical />
                                </button>
                              </td>
                            )}
                          </tr>
                        ))
                      ) : (
                        <Nodata style={`no-data`} title="No Projects available..." />
                      )}
                    </tbody>
                  </table>
                  {currentProjects && currentProjects.length > 0 && (
                    <div className="pagination">
                      <button
                        className='prev-btn'
                        onClick={() => handlePage(currentPage - 1)}
                        disabled={currentPage === 1}
                      >
                        Previous
                      </button>
                      {Array.from({ length: Math.ceil(filteredProjects.length / itemsPerPage) }, (_, i) => (
                        <button
                          key={i + 1}
                          className={`page ${currentPage === i + 1 ? 'active' : 'pagination-btn'}`}
                          onClick={() => handlePage(i + 1)}
                        >
                          {i + 1}
                        </button>
                      ))}
                      <button
                        className='next-btn'
                        onClick={() => handlePage(currentPage + 1)}
                        disabled={endIndex >= filteredProjects.length}
                      >
                        Next
                      </button>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ProjectsPage