import React, { useEffect, useState } from 'react'
import DashboardContainerV2 from '../../../components/dashbaord/dashboardContainerV2.jx'
import api from '../../../utils/api'
import ProjectTrackingLoader from '../../../components/loadders/projectTracking/projecttrackingLoader'
import "../../../styles/projects/projectPage.css"
import { useGlobalContext } from "../../../context/Context"
import Nodata from "../../../components/Nodata"
import { Loading01Icon, PlusSignIcon, Search01Icon } from 'hugeicons-react'
import NewProjectForm from '../../../components/projects/newProjectForm'


const ProjectPageContent = () => {
    const {
        API_URL,
        searchQuery,
        itemsPerPage,
        currentPage,
        handlePage,
    } = useGlobalContext()

    const [isLoading, setIsLoading] = useState(true)
    const [projects, setProjects] = useState([])
    const [showNewProjectForm, setShowNewProjectForm] = useState(false)

    const [departments, setDepartments] = useState([])
    const [companies, setCompanies] = useState([])
    const [employees, setEmployees] = useState([])
    const [gettingEmployees, setGettingEmployees] = useState(true)
    const [gettingCompanies, setGettingCompanies] = useState(true)
    const [gettingDepartments, setGettingDepartments] = useState(true)

    const handleTableRowClick = (slug) => {
        window.location.href = `/v2/projects/${slug}/`
    }

    const handleShowNewProjectForm = () => {
        setShowNewProjectForm(!showNewProjectForm)
    }


    const sortedProjects = [...projects].sort((a, b) => {
        const nameA = a.title.toLowerCase()
        const nameB = b.title.toLowerCase()
        return nameA.localeCompare(nameB)
    });

    const filteredProjects = sortedProjects.length > 0 &&
        sortedProjects.filter((project) =>
            project ? project.title.toLowerCase().includes(searchQuery.toLowerCase()) : false
        );

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = Math.min(startIndex + itemsPerPage, filteredProjects.length);
    const currentProjects = filteredProjects.length > 0 && filteredProjects.slice(startIndex, endIndex);

    useEffect(() => {
        const fetchData = async () => {
            try {

                const response = await api.get(`${API_URL}/api/projects/`)
                if (response.status === 200) {
                    setProjects(response.data);
                    console.log(response.data)
                    setIsLoading(false)
                }

            } catch (error) {
                console.log(error)
                setIsLoading(false)
            }
        }
        fetchData()

        const fetchDepartments = async () => {
            try {
                const response = await api.get(`${API_URL}/api/list_departments/`)
                if (response.status === 200) {
                    setDepartments(response.data)
                    setGettingDepartments(false)
                }
            } catch (error) {
                console.log("Error getting departments", error)
            }
        }
        fetchDepartments()

        const fetchCompanies = async () => {
            try {
                const response = await api.get(`${API_URL}/api/get_companies/`)
                if (response.status === 200) {
                    setCompanies(response.data.client)
                    setGettingCompanies(false)
                }
            } catch (error) {
                console.log("Error getting companies", error)
            }
        }
        fetchCompanies()

        const fetchEmployees = async () => {
            try {
                const response = await api.get(`${API_URL}/api/list_employees/`)
                if (response.status === 200) {
                    setEmployees(response.data)
                    setGettingEmployees(false)
                }

            } catch (error) {
                console.log("Error getting employees", error)
            }
        }
        fetchEmployees()
    }, []);

    if (isLoading) {
        return <ProjectTrackingLoader />
    }



    return (
        <div className="projects">
            {
                showNewProjectForm
                    ?
                    <NewProjectForm setShowNewProjectForm={setShowNewProjectForm} companies={companies} departments={departments} employees={employees} />
                    : ''
            }
            <section className="contents">
                {/* <div>
                <Nav />
              </div> */}
                <div className="underline"></div>
            </section>

            <div className="contents-container"><div className='title-contents'>
                <div className='top'>
                    <div className="col-title">
                        <h4>Project List</h4>
                        <small>{projects.length} Project(s)</small>
                    </div>
                    <div className="search-input">
                        <Search01Icon
                            size={20}
                            variant={"stroke"}
                        />
                        <input type="text" name="globalSearch" id="globalSearch" placeholder='Search projects...' />

                    </div>
                    {
                        gettingCompanies || gettingDepartments || gettingEmployees
                            ? <button type="button" className='primary-button'>
                                <Loading01Icon />
                                <span>Getting {gettingCompanies ? 'companies' : gettingDepartments ? 'departments' : gettingEmployees ? 'employees' : 'requirements'}</span>
                            </button>
                            : <button onClick={handleShowNewProjectForm} type="button" className='primary-button'>
                                <PlusSignIcon />
                                <span>Add Project</span>
                            </button>
                    }
                </div>
            </div>
                {
                    projects &&
                    <div className="table-contents">
                        <table>
                            <thead>
                                <tr>
                                    <th>NO</th>
                                    <th>Project Name</th>
                                    <th>Department</th>
                                    <th>Start Date</th>
                                    <th>End Date</th>
                                    <th>Status</th>

                                </tr>
                            </thead>
                            <tbody>
                                {currentProjects && currentProjects.length > 0 ? (
                                    currentProjects.map((project) => (
                                        <tr onClick={() => handleTableRowClick(project.slug)} key={project.id}>
                                            <td className="hidden-table-col">{project.id.slice(0, 10)}</td>
                                            <td>{project.title || 'Not provided'}</td>
                                            <td className="hidden-table-col">{project.department ? project.department : "No department"}</td>
                                            <td>{project.start_date || 'Not provided'}</td>
                                            <td>{project.end_date || 'Not provided'}</td>
                                            <td>{project.status || "Not provided"}</td>
                                        </tr>
                                    ))) : (<Nodata style={`no-data`} title="No Projects available..." />)}
                            </tbody>
                        </table>
                        {currentProjects && currentProjects.length > 0 && (
                            <div className="pagination">
                                <button
                                    className='prev-btn'
                                    onClick={() => handlePage(currentPage - 1)}
                                    disabled={currentPage === 1}
                                >
                                    Previous
                                </button>
                                {Array.from({ length: Math.ceil(filteredProjects.length / itemsPerPage) }, (_, i) => (
                                    <button
                                        key={i + 1}
                                        className={`page ${currentPage === i + 1 ? 'active' : 'pagination-btn'}`}
                                        onClick={() => handlePage(i + 1)}
                                    >
                                        {i + 1}
                                    </button>
                                ))}
                                <button
                                    className='next-btn'
                                    onClick={() => handlePage(currentPage + 1)}
                                    disabled={endIndex >= filteredProjects.length}
                                >
                                    Next
                                </button>
                            </div>
                        )}
                    </div>
                }
            </div>
        </div>
    )
}

const AllProjectsPage = () => {
    return (
        <DashboardContainerV2 content={<ProjectPageContent />} />
    )
}

export default AllProjectsPage
