import React from 'react'
import DashboardContainerV2 from '../../../components/dashbaord/dashboardContainerV2.jx'

const BlogDetailsPageContent = () => {
    return (
        <h1>This is the details page</h1>
    )
}
const BlogDetailsPage = () => {
    return (
        <DashboardContainerV2 content={<BlogDetailsPageContent />} />
    )
}

export default BlogDetailsPage
