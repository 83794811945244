import '../styles/notFound/not-found.css';
import { Link } from 'react-router-dom';

const NotFound = () => {
    return (
        <section className='not-found'>
            <div className='container'>
                <h2>Sorry!!! This page is Not found!!!</h2>
                <Link to='/employees'>
                    <button>Go to overview</button>
                </Link>
            </div>
        </section>
    )
}

export default NotFound
