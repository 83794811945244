import React from "react"
import { Briefcase07Icon, ArrowUp02Icon } from 'hugeicons-react';

const TrainingDetailsPageHeader = ({ cohortDetails }) => {
    return (
        <div className="pageheader-main">
         <div className="pageheader-content">
            <div className="pageheader-icon">
            <Briefcase07Icon 
      size={32} 
      color={"#145C9E"}
      variant={"stroke"}
    />
            </div>

            <div className="leadership-header">
                <div className="leadership-bold">
                    <h1>{cohortDetails.title}</h1>
                    <h2>Open for Enrollment</h2>
                </div>
                <p>{cohortDetails.start_date} - {cohortDetails.end_date}</p>
            </div>

            <div className="pageheader-numbers">
                <div className="numbers-content">
                    <div className="arrow-numbers">
                    <ArrowUp02Icon 
                    size={18} 
                    color={"#000000"}
                    variant={"stroke"}
                    />
                      <h1>{cohortDetails.trainees}</h1>
                    </div>
                    <p>Trainees</p>
                </div>

                <div className="numbers-content">
                <div className="arrow-numbers">
                    <ArrowUp02Icon 
                    size={18} 
                    color={"#000000"}
                    variant={"stroke"}
                    />
                    <h1>{cohortDetails.fees_collected}</h1>
                    </div>
                    <p>Fees collected</p>
                </div>

                <div className="numbers-content">
                <div className="arrow-numbers">
                    <ArrowUp02Icon 
                    size={18} 
                    color={"#000000"}
                    variant={"stroke"}
                    />
                    <h1>{cohortDetails.open_spots}</h1>
                    </div>
                    <p>Open spot</p>
                </div>
            </div>
         </div>
        </div>
        
    )
}
export default TrainingDetailsPageHeader