import React from 'react';
import { ArrowRight02Icon, MoreVerticalIcon } from 'hugeicons-react'
import { Link } from 'react-router-dom'

const RecentTransactions = ({ cohortDetails }) => {
  const transactions = cohortDetails?.overview?.recent_transactions || [];

  return (
    <div className="recent-transactions">
      <div className="card-header">
      <h3>Recent Transactions</h3>
      <Link className='view-all' to={`/v2/training-center/`}>
         See all
         <ArrowRight02Icon size={24} />
        </Link>
       </div> 
       <div className="transactions-list">
       <div className="table-contents">               
      <table>
        <thead>
          <tr>
            <th>Transaction name</th>
            <th>Date</th>
            <th>Time</th>
            <th>Amount</th>
            <th>Payed</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {transactions.map((transaction, index) => (
            <tr key={index}>
              <td>{transaction.name}</td>
              <td>{transaction.date}</td>
              <td>{transaction.time}</td>
              <td>${transaction.amount.toLocaleString()}</td>
              <td>{transaction.status}</td>
              <td> <MoreVerticalIcon 
              size={24} 
              color={"#000000"}
              variant={"stroke"}
            /></td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>  
      </div>
    </div>
  );
};

export default RecentTransactions;
