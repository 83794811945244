import { Loading03Icon } from 'hugeicons-react'
import React from 'react'

const LoadingIcon = ({ size }) => {
    return (
        <Loading03Icon size={size} className='loading-icon' />
    )
}

export default LoadingIcon
