import React, { useEffect, useState } from "react";
import api, { API_URL } from "../../utils/api";
import { filterEmployees } from "../../utils/filterEmployeeByName";
import { Search01Icon } from "hugeicons-react";
const NewProjectForm = ({ setShowNewProjectForm, employees, companies, departments }) => {

    const [filteredEmployees, setFilteredEmployees] = useState([])
    const [searchingEmployees, setSearchingEmployees] = useState(false)
    const [startDate, setStartDate] = useState()
    const [selectedDepartment, setSelectedDepartment] = useState("")
    const [selectedCompany, setSelectedCompany] = useState("")
    const [priority, setPriority] = useState("")
    const [contactPersonEmail, setContactPersonEmail] = useState("")
    const [manager, setManager] = useState("")
    const [managerEmail, setManagerEmail] = useState("")
    const [projectName, setProjectName] = useState("")
    const [description, setDescription] = useState("")
    const [isLoading, setIsLoading] = useState(false)


    const filterEmployee = (e) => {
        const foundEMployees = filterEmployees(employees, e.target.value)
        setFilteredEmployees(foundEMployees)
    }

    const handleSelectedEmployee = (employee) => {
        setManager(`${employee.user.first_name} ${employee.user.last_name}`)
        setManagerEmail(employee.user.email)
        setSearchingEmployees(false)
    }
    const NewProject = async () => {
        // Add new project logic here
        const projectData = {
            "name": projectName,
            "description": description,
            "start_date": startDate,
            "company_name": selectedCompany,
            "contact_person_email": contactPersonEmail,
            "department_name": selectedDepartment,
            "manager_email": managerEmail,
        }

        try {
            setIsLoading(true)
            console.log("request data: ", projectData)
            const response = await api.post(`${API_URL}/api/new_project/`, projectData)
            if (response.status === 200) {
                setIsLoading(false)
                setShowNewProjectForm(false)
            }
        } catch (error) {
            setIsLoading(false)
            if (error.response.data) {
                console.log(error.response.data.error)
                alert(error.response.data.error)
            }
            console.log(error)
        }
    }
    useEffect(() => {

    }, [])


    return (
        <div className="new-project-form">
            <div className="form">
                <h1>Add new project</h1>
                <p>Add new project and its details. You will be required to the project details where you can add tasks and and more...</p>

                <form>
                    <input value={projectName} onChange={(e) => setProjectName(e.target.value)} type="text" name="projectName" id="projectName" placeholder='Project name' />
                    <div className="half">
                        <div className="input departments">
                            <label htmlFor="">Department</label>
                            <select value={selectedDepartment} onChange={(e) => setSelectedDepartment(e.target.value)} name="" id="" defaultValue={''}>
                                <option disabled value="">Select department</option>
                                {
                                    departments.map((department, index) => (

                                        <option value={department.name} key={index}>{department.name}</option>
                                    ))
                                }
                            </select>
                        </div>

                        <div className="input companies">
                            <label htmlFor="">Companies</label>
                            <select value={selectedCompany} onChange={(e) => setSelectedCompany(e.target.value)} name="" id="" defaultValue={''}>
                                <option disabled value="">Select company</option>
                                {
                                    companies.map((company, index) => (

                                        <option value={company.name} key={index}>{company.name}</option>
                                    ))
                                }
                            </select>
                        </div>

                        <div className="half">
                            <div className="input search-field">
                                <label htmlFor="projectManager">Project manager</label>
                                <input value={manager} onFocus={() => setSearchingEmployees(true)} type="text" name="projectManager" id="projectManager" placeholder="Project manager" />
                                {
                                    searchingEmployees ?
                                        <div className="search-container">
                                            {/* <Cancel01Icon onClick={setSearchingEmployees(false)} /> */}
                                            <div className="search-input">
                                                <Search01Icon />
                                                <input onChange={(e) => filterEmployee(e)} type="text" name="" id="" placeholder="Type name to search" />
                                            </div>
                                            {
                                                filteredEmployees && <div className="search-results">
                                                    {
                                                        filteredEmployees.length > 0
                                                            ? <>
                                                                {
                                                                    filteredEmployees.map((employee, index) => (
                                                                        <div className="result" onClick={() => handleSelectedEmployee(employee)} key={index}>{employee.user.first_name} {employee.user.last_name}</div>
                                                                    ))
                                                                }
                                                            </> : 'No employee found'
                                                    }
                                                </div>
                                            }

                                        </div> : ''
                                }
                            </div>
                            <div className="input location">
                                <label htmlFor="projectContactPersonEmail">Contact person email</label>
                                <input value={contactPersonEmail} onChange={(e) => setContactPersonEmail(e.target.value)} type="email" name="porjectContactPersonEmail" id="porjectContactPersonEmail" placeholder="Project person email" />
                            </div>
                        </div>
                    </div>
                    <div className="half">
                        <div className=" input start-date">
                            <label htmlFor="startDate">Start Date</label>
                            <input value={startDate} onChange={(e) => setStartDate(e.target.value)} type="date" name="startDate" id="startDate" />
                        </div>
                        <div className="input priority">
                            <label htmlFor="priority">Priority</label>
                            <select value={priority} onChange={(e) => setPriority(e.target.value)} name="" id="">
                                <option value="High">Hight</option>
                                <option value="Low">Low</option>
                                <option value="Medium">Medium</option>
                            </select>
                        </div>
                    </div>

                    <textarea value={description} onChange={(e) => setDescription(e.target.value)} name="projectDescription" id="projectDescription" placeholder='Project description'></textarea>
                    <div className="form-buttons">
                        <button onClick={() => setShowNewProjectForm(false)} type="button" className="cancel-btn">
                            Cancel
                        </button>
                        <button disabled={isLoading} onClick={NewProject} type="button" className="primary-button">
                            {
                                isLoading ? "Saving..." : "Create Project"
                            }
                        </button>
                    </div>
                </form>

            </div>
        </div>
    )
}

export default NewProjectForm;