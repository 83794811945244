import React, { useEffect, useState } from 'react'
import api from '../../../utils/api'

const DeleteBlogForm = ({ blog, handleDeletePopup }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null)
    const [successMessage, setSuccessMessage] = useState(null)
    const [userPassword, setUserPassword] = useState(null)
    const handleDeleteBlog = async () => {
        const userData = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {}

        if (!userPassword) {
            setError('Please enter your password')
            return
        }
        if (!blog) {
            setError('No blog slug provided')
            return
        }

        setIsLoading(true)
        try {
            // call cbo to check user permissions and wait for the access token

            // send delete request to csrlimited with access token

        } catch (error) {

        }
    }
    useEffect(
        () => {
            setTimeout(() => {
                setError(null)
                setSuccessMessage(null)
            }, 3000);
        },
    )
    return (
        <div className='delete-blog-form'>
            <h3>Delete blog</h3>
            <p><strong>Title: </strong> {blog.title} ?</p>
            <p>Deleted blogs won't appear in the system and on the website anymore. This action is not reversible</p>

            <form>
                Enter your password to delete this blog
                {
                    successMessage && <div className="success-message">{successMessage}</div>
                }
                {
                    error && <div className="error-message">{error}</div>
                }
                <input onChange={(e) => setUserPassword(e.target.value)} type="password" placeholder="Enter password" />
            </form>
            <div className="delete-buttons">
                <button onClick={handleDeletePopup} type="button">Cancel</button>
                <button onClick={handleDeleteBlog} type="button" className='delete'>Delete Blog</button>
            </div>
        </div>
    )
}

export default DeleteBlogForm
