import React, { useEffect, useState, useRef, useCallback } from 'react'
import DashboardContainerV2 from '../../../components/dashbaord/dashboardContainerV2.jx'
import { ArrowDown01Icon, Briefcase01Icon, IslandIcon, Menu08Icon, Search01Icon } from 'hugeicons-react'
import api, { API_URL } from '../../../utils/api'
import FormattedDate from '../../../components/formattedDate'
import '../../../styles/dashboard/jobMatch/jobsPage/jobsPage.css'

const FilterPopup = ({ tootlePopup }) => {
    return (
        <div className="filter-popup">
            <div className="filter-header">
                <h3>Filter</h3>
                <span onClick={tootlePopup}>Close</span>
            </div>
            <select name="" id="">
                <option value="">All Statuses</option>
                <option value="">Active</option>
                <option value="">Inactive</option>
            </select>
            <input type="date" name=" d" id="" />
            <button type="button " className='primary-button'>Filter</button>
        </div>
    )
}


const SortPopup = ({ tootlePopup }) => {
    return (
        <div className="filter-popup">
            <div className="filter-header">
                <h3>Sort</h3>
                <span onClick={tootlePopup}>Close</span>
            </div>
            <select name="" id="">
                <option value="">A - Z</option>
                <option value="">Z - A</option>
            </select>
            <button type="button " className='primary-button'>Filter</button>
        </div>
    )
}
const handleSearch = (searchString, jobsData) => {
    if (!searchString || searchString.length <= 2) return jobsData;

    return jobsData.filter(job => {
        return (
            (job.name && job.name.toLowerCase().includes(searchString.toLowerCase())) ||
            (job.employer && job.employer.toLowerCase().includes(searchString.toLowerCase()))
        )
    })
}
const JobsPageContent = () => {
    const [isLoading, setIsLoading] = useState(true)
    const [jobsData, setJobsData] = useState(false)
    const [filteredJobs, setFilteredJobs] = useState([])
    const [searchString, setSearchString] = useState('')
    const [isSearching, setIsSearching] = useState(false)
    const [showSortPopup, setShowSortPopup] = useState(false)
    const [showFilterPopup, setShowFilterPopup] = useState(false)

    const toggleSortPopup = () => {
        setShowSortPopup(!showSortPopup)
    }
    const toggleFilterPopup = () => {
        setShowFilterPopup(!showFilterPopup)
    }

    const search = (event) => {
        if (event && Object.keys(event.target.value).length > 1) {
            setIsSearching(true)
        } else {
            setIsSearching(false)
        }
        setSearchString(event.target.value)
        setFilteredJobs(handleSearch(searchString, jobsData.jobs))
    }


    useEffect(() => {
        const getJobs = async () => {
            try {
                const response = await api.get(`${API_URL}/api/job-match/jobs/jobs-list/`)
                if (response.status === 200) {
                    setIsLoading(false)
                    console.log(response.data)
                    setJobsData(response.data)
                }
            } catch (error) {
                setIsLoading(false)
                console.log(error)
                if (error.response) {
                    console.error('Error:', error.response.data.message)
                } else {
                    console.error('Error:', error.message)
                }
            }
        }
        getJobs()
    }, [])
    return isLoading ? '.. Loading' : (
        <div className='jobs-page contents-container'>
            {
                jobsData && jobsData.data && <div className="numbers">
                    <div className="number">
                        <div className="icon">
                            <Briefcase01Icon
                                size={24}
                                variant={"stroke"}
                            />
                        </div>
                        <div className="number-content">
                            <p>Total Jobs</p>
                            <h3>{jobsData.data.total_jobs}</h3>
                        </div>
                    </div>
                    <div className="number">
                        <div className="icon">
                            <Briefcase01Icon
                                size={24}
                                variant={"stroke"}
                            />
                        </div>
                        <div className="number-content">
                            <p>Active Jobs</p>
                            <h3>{jobsData.data.active_jobs}</h3>
                        </div>

                    </div>
                    <div className="number">
                        <div className="icon">
                            <Briefcase01Icon
                                size={24}
                                variant={"stroke"}
                            />
                        </div>
                        <div className="number-content">
                            <p>Closed Jobs</p>
                            <h3>{jobsData.data.closed_jobs}</h3>
                        </div>

                    </div>
                </div>
            }
            <div className="jobs-list">
                <div className="search-and-filters">
                    <div className="total">
                        Recent Jobs

                        <small className="number">
                            <span className='figure'>{jobsData && jobsData.jobs.length}</span>
                            <span>New jobs</span>
                        </small>
                    </div>
                    <div className="search-input">
                        <Search01Icon size={24} />
                        <input value={searchString} onChange={search} type="text" placeholder='Search by job title, employer, date posted' />
                    </div>

                    <div className="filters">
                        <div onClick={toggleFilterPopup} className="filter">
                            <Menu08Icon />
                            <span>Filter</span>
                        </div>

                        <div onClick={toggleSortPopup} className="sort filter">
                            <span> Sort by</span>
                            <ArrowDown01Icon />
                        </div>
                        {showFilterPopup && <FilterPopup tootlePopup={toggleFilterPopup} />}
                        {showSortPopup && <SortPopup tootlePopup={toggleSortPopup} />}
                    </div>

                </div>
                <div className="list">
                    {
                        jobsData && <div className="table-contents">
                            {
                                jobsData && jobsData.jobs && <table>
                                    <thead>
                                        <tr>
                                            <th>
                                                Job Title
                                            </th>
                                            <th>Employer</th>
                                            <th>Applicants</th>
                                            <th>Date posted</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    {
                                        isSearching
                                            ? <>
                                                {
                                                    filteredJobs.length > 0
                                                        ? <>

                                                            <tbody>

                                                                {
                                                                    filteredJobs.map((job, index) => (
                                                                        <tr key={index}>
                                                                            <td>{job.name || 'Not provided'}</td>
                                                                            <td>{job.employer || 'Not provided'}</td>
                                                                            <td>{job.applicants || 0}</td>
                                                                            <td>{<FormattedDate date={job.date_posted} /> || 'Not provided'}</td>
                                                                            <td>{job.status || 'Not provided'}</td>
                                                                        </tr>
                                                                    ))
                                                                }
                                                            </tbody>
                                                        </> : <div className="no-results-found">
                                                            <h3>Oppps!</h3>
                                                            No quick results found for the given search criteria.
                                                            <button type="button" className='primary-button'>Search database</button>
                                                        </div>
                                                }
                                            </>
                                            : <tbody>
                                                {
                                                    jobsData.jobs.map((job, index) => (
                                                        <tr key={index}>
                                                            <td>{job.name || 'Not provided'}</td>
                                                            <td>{job.employer || 'Not provided'}</td>
                                                            <td>{job.applicants || 0}</td>
                                                            <td>{<FormattedDate date={job.date_posted} /> || 'Not provided'}</td>
                                                            <td>{job.status || 'Not provided'}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                    }

                                </table>
                            }
                        </div>
                    }
                </div>
            </div>

        </div>
    )
}
const JobsPage = () => {
    return (
        <DashboardContainerV2 content={<JobsPageContent />} />
    )
}

export default JobsPage
