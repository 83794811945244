import React from "react";
import DashboardContainerV2 from '../../components/dashbaord/dashboardContainerV2.jx';
import Admin from "../../components/canteen/admin";
import '../../styles/canteen/adminPanel.css'

const AdminPanelContent = () => {
    return(
        <>
        <Admin/>
        </>
    )

}

const CanteenAdminPanel = () => {
    return(
        <>
            <DashboardContainerV2 content={<AdminPanelContent/>}/>
        
        </>
    )
}

export default CanteenAdminPanel;