import React from 'react'
import DashboardContainerV2 from "../../../components/dashbaord/dashboardContainerV2.jx";
import "../../../styles/trainingCenter/transactionPage/transaction_page_loader.css";

const TransactionPageContent = () => {
    return (
        <div className='transaction-table-contents'>
            <div className="titles">
                <div className="title-text"></div>
                <div className="divider"></div>
                <div className="title-text"></div>
            </div>

            <div className="transaction-filtering">
                <div className="filtering-contents">
                    <div className="transactions">
                        <div className="transaction-title"></div>
                        <div className="tansaction-numbers"></div>
                    </div>

                    <div className="search-transactions">
                        <div className="search-placeholder"></div>
                        <div className="search-icon"></div>
                    </div>

                    <div className="transaction-filter-button">
                        <div className="filter-text"></div>
                        <div className="filter-icon"></div>

                    </div>
                </div>
            </div>

            <div className="table-contents">
                <div className="table">
                    <div className="contents ">
                        <div className="content-detail"></div>
                        <div className="content-detail"></div>
                        <div className="content-detail"></div>
                        <div className="content-detail"></div>
                        <div className="content-detail"></div>
                        <div className="content-detail"></div>
                        <div className="content-detail"></div>
                    </div>
                    <div className="contents gray">
                        <div className="content-detail white"></div>
                        <div className="content-detail white"></div>
                        <div className="content-detail white"></div>
                        <div className="content-detail white"></div>
                        <div className="content-detail white"></div>
                        <div className="content-detail white"></div>
                        <div className="content-detail white"></div>
                    </div>
                    <div className="contents">
                        <div className="content-detail"></div>
                        <div className="content-detail"></div>
                        <div className="content-detail"></div>
                        <div className="content-detail"></div>
                        <div className="content-detail"></div>
                        <div className="content-detail"></div>
                        <div className="content-detail"></div>
                    </div>
                    <div className="contents gray">
                        <div className="content-detail white"></div>
                        <div className="content-detail white"></div>
                        <div className="content-detail white"></div>
                        <div className="content-detail white"></div>
                        <div className="content-detail white"></div>
                        <div className="content-detail white"></div>
                        <div className="content-detail white"></div>
                    </div>
                    <div className="contents">
                        <div className="content-detail"></div>
                        <div className="content-detail"></div>
                        <div className="content-detail"></div>
                        <div className="content-detail"></div>
                        <div className="content-detail"></div>
                        <div className="content-detail"></div>
                        <div className="content-detail"></div>
                    </div>
                    <div className="contents gray">
                        <div className="content-detail white"></div>
                        <div className="content-detail white"></div>
                        <div className="content-detail white"></div>
                        <div className="content-detail white"></div>
                        <div className="content-detail white"></div>
                        <div className="content-detail white"></div>
                        <div className="content-detail white"></div>
                    </div>
                    <div className="contents">
                        <div className="content-detail"></div>
                        <div className="content-detail"></div>
                        <div className="content-detail"></div>
                        <div className="content-detail"></div>
                        <div className="content-detail"></div>
                        <div className="content-detail"></div>
                        <div className="content-detail"></div>
                    </div>
                    <div className="contents gray">
                        <div className="content-detail white"></div>
                        <div className="content-detail white"></div>
                        <div className="content-detail white"></div>
                        <div className="content-detail white"></div>
                        <div className="content-detail white"></div>
                        <div className="content-detail white"></div>
                        <div className="content-detail white"></div>
                    </div>
                    <div className="contents">
                        <div className="content-detail"></div>
                        <div className="content-detail"></div>
                        <div className="content-detail"></div>
                        <div className="content-detail"></div>
                        <div className="content-detail"></div>
                        <div className="content-detail"></div>
                        <div className="content-detail"></div>
                    </div>
                    <div className="contents gray">
                        <div className="content-detail white"></div>
                        <div className="content-detail white"></div>
                        <div className="content-detail white"></div>
                        <div className="content-detail white"></div>
                        <div className="content-detail white"></div>
                        <div className="content-detail white"></div>
                        <div className="content-detail white"></div>
                    </div>
                </div>
                <div className="table-pagination">
                    <div className="pagination"></div>
                    <div className="pagination"></div>
                    <div className="pagination"></div>
                    <div className="pagination"></div>

                </div>
            </div>
        </div>
    )
}


const TransactionPage = () => {
    return (
        <DashboardContainerV2 content={<TransactionPageContent />} />
    )
}

export default TransactionPage