import React, { useState, useContext } from "react";

const AppContext = React.createContext()

const AppProvider = ({ children }) => {
    const [isPopupOpen, setIsPopupOpen] = useState(false)
    // mobile sidebar
    const [isMobileSidebarOpen, setMobileIsSidebarOpen] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const [isCalendarOpen, setIsCalendarOpen] = useState(false)
    const [message, setMessage] = useState('')

    // getting leaves
    const [leaves, setLeaves] = useState([])

    // calculating the leaves
    const [sickLeaves, setSickLeaves] = useState()
    const [sickLeavesPercentage, setSickLeavesPercentage] = useState()

    const [annualLeaves, setAnnualLeaves] = useState()
    const [annualLeavesPercentage, setAnnualLeavesPercentage] = useState()

    const [compassionateLeaves, setCompassionateLeaves] = useState()
    const [compassionateLeavesPercentage, setCompassionateLeavesPercentage] = useState()

    const [maternityLeaves, setMaternityLeaves] = useState()
    const [maternityLeavesPercentage, setMaternityLeavesPercentage] = useState()

    const [marriage, setMarriage] = useState()
    const [marriagePercentage, setMarriagePercentage] = useState()

    const [paternityLeaves, setPaternityLeaves] = useState()
    const [paternityLeavesPercentage, setPaternityLeavesPercentage] = useState()

    // development url
    const BASE_URL = process.env.REACT_APP_BASE_URL

    // production api
    const API_URL = process.env.REACT_APP_API_URL

    // pagination
    const itemsPerPage = 15;
    const handlePage = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
        setCurrentPage(1);
    };

    const closePopup = () => {
        setIsPopupOpen(false)
    }

    const openPopup = () => {
        setIsPopupOpen(true)
    }

    const openSidebar = () => {
        setMobileIsSidebarOpen(true)
    }

    const closeSidebar = () => {
        setMobileIsSidebarOpen(false)
    }

    const openCalendar = () => {
        setIsCalendarOpen(true)
    }

    const closeCalendar = () => {
        setIsCalendarOpen(false)
    }

    return (
        <AppContext.Provider
            value={{
                openPopup,
                closePopup,
                openSidebar,
                closeSidebar,
                handlePage,
                handleSearch,
                openCalendar,
                closeCalendar,
                setMessage,
                setSickLeaves,
                setSickLeavesPercentage,
                setAnnualLeaves,
                setAnnualLeavesPercentage,
                setCompassionateLeaves,
                setCompassionateLeavesPercentage,
                setLeaves,
                setMaternityLeaves,
                setMaternityLeavesPercentage,
                setMarriage,
                setMarriagePercentage,
                setPaternityLeaves,
                setPaternityLeavesPercentage,
                isPopupOpen,
                isMobileSidebarOpen,
                isCalendarOpen,
                currentPage,
                searchQuery,
                BASE_URL,
                API_URL,
                itemsPerPage,
                message,
                sickLeaves,
                sickLeavesPercentage,
                annualLeaves,
                annualLeavesPercentage,
                compassionateLeaves,
                compassionateLeavesPercentage,
                maternityLeaves,
                maternityLeavesPercentage,
                marriage,
                marriagePercentage,
                paternityLeaves,
                paternityLeavesPercentage,
                leaves
            }}
        >
            {children}
        </AppContext.Provider>
    )
}

export const useGlobalContext = () => {
    return useContext(AppContext)
}

export { AppContext, AppProvider }