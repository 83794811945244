import axios from "axios";
import Cookies from "js-cookie";
export const MAX_FILE_SIZE = 12 * 1024 * 1024; // 12 MB in bytes

export const API_URL = process.env.REACT_APP_API_URL;
export const AuthClientId = process.env.REACT_APP_MS_CLIENT_ID
export const Auth_CompStaffing_Tenant = process.env.REACT_APP_MS_COMPSTAFIFNG_TENANT
export const Auth_Ms_redirectUri = process.env.REACT_APP_MS_REDIRECT_URL
export const TINYEMCE_API_KEY = process.env.REACT_APP_TINYEMCE_API_KEY
export const CSR_API_ENDPOINT = process.env.REACT_APP_CSR_API


const api = axios.create({
    baseURL: API_URL
})

api.interceptors.request.use(
    (config) => {
        const accessToken = Cookies.get("accessToken");
        if (accessToken) {
            config.headers.Authorization = `Bearer ${accessToken}`
        } return config
    }, (error) => {
        return Promise.reject(error)
    }
)
api.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response && (error.response.status === 403 || error.response.status === 401)) {
            //show un authorized message
            Cookies.remove('accessToken');
            Cookies.remove('refreshToken');

            window.location.href = '/';
        }
        return Promise.reject(error);
    }
);
export default api
