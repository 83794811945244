import React, { useState, useEffect } from "react";
import Categories from "./categories";
import Menu from "./menu";
import { Order } from "./order";
import { Link } from "react-router-dom";



const CanteenOrder = () => {
  const [activeCategory, setActiveCategory] = useState(0);
  const [showOrderDetails, setShowOrderDetails] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [isAdmin, setIsAdmin] = useState(localStorage.getItem('role') === 'staff');

  const checkOrderData = () => {
    const savedOrderData = sessionStorage.getItem('orderData');
    if (savedOrderData) {
      const parsedOrderData = JSON.parse(savedOrderData).map((order) => ({
        ...order,
        quantity: Number(order.quantity),
        price: Number(order.price),
      }));
      setShowOrderDetails(true);
    } else {
      setShowOrderDetails(false);
    }
  }


  useEffect(() => {
    checkOrderData();

    const handleOrderDataUpdated = () => {
      checkOrderData();
    }

    window.addEventListener('orderDataUpdated', handleOrderDataUpdated);

    return () => {
      window.removeEventListener('orderDataUpdated', handleOrderDataUpdated);
    }
    
  }, []);


  useEffect(() => {
    console.log(searchQuery);
  }, [searchQuery])



  return (
      <main>
        <div className="main-div">
          <div className="first-row">
            <Categories
              activeCategory={activeCategory}
              setActiveCategory={setActiveCategory}
            />
            <Menu activeCategory={activeCategory} searchQuery={searchQuery}/>
          </div>
          {showOrderDetails && !isAdmin && (
            <div className="order-details">
              <Order/>
            </div>
          )}
        </div>

        {!isAdmin && (
          <div className="view-order">
          <Link to={"/details"} className="button">
            View Order Details
          </Link>
        </div>
        )}
        
      </main>
  );
};

export default CanteenOrder;