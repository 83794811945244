import React from 'react'
import '../../../styles/loaders/leaves/leavesOverview.css';
const LeavesOverviewLoadder = () => {
    return (
        <div className='leaves-overview-loader'>
            
            <div className='overviews'>
                <div className='cards'>
                    <div className="card">
                        <div className="icon loader"></div>
                        <div className="title-text">
                            <div className="title loader"></div>
                            <div className="text-midle loader"></div>
                            <div className="text-bottom loader"></div>
                        </div>

                    </div>
                    <div className="card">
                        <div className="icon loader"></div>
                        <div className="title-text">
                            <div className="title loader"></div>
                            <div className="text-midle loader"></div>
                            <div className="text-bottom loader"></div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="icon loader"></div>
                        <div className="title-text">
                            <div className="title loader"></div>
                            <div className="text-midle loader"></div>
                            <div className="text-bottom loader"></div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="icon loader"></div>
                        <div className="title-text">
                            <div className="title loader"></div>
                            <div className="text-midle loader"></div>
                            <div className="text-bottom loader"></div>
                        </div>
                    </div>

                </div>
                <div className="charts">
                <div className="chart-title">
                        <div className="title-text-chart loader"></div>
                        <div className="button-chart loader"></div>
                    </div>
                    <div className="chart-box-legend">
                        <div className="chart-boxes">
                            <div className="chart-box loader"></div>
                            <div className="chart-box loader"></div>
                            <div className="chart-box loader"></div>
                            <div className="chart-box loader"></div>
                            <div className="chart-box loader"></div>
                            <div className="chart-box loader"></div>
                            <div className="chart-box loader"></div>
                            <div className="chart-box loader"></div>
                            <div className="chart-box loader"></div>
                            <div className="chart-box loader"></div>
                        </div>
                        <div className="legends">
                            <div className="legend">
                                <div className="big loader"></div>
                                <div className="small loader"></div>
                            </div>
                            <div className="legend">
                                <div className="big loader"></div>
                                <div className="small loader"></div>
                            </div>
                            <div className="legend">
                                <div className="big loader"></div>
                                <div className="small loader"></div>
                            </div>
                            <div className="legend" id='fourth-chart-bar'>
                                <div className="big loader"></div>
                                <div className="small loader"></div>
                            </div>
                            <div className="legend" id='fifth-chart-bar'>
                                <div className="big loader"></div>
                                <div className="small loader"></div>
                            </div>
                            <div className="legend" id='sixth-chart-bar'>
                                <div className="big loader"></div>
                                <div className="small loader"></div>
                            </div>
                            <div className="legend" id='second-to-last-chart-bar'>
                                <div className="big loader"></div>
                                <div className="small loader"></div>
                            </div>
                            <div className="legend" id='last-chart-bar'>
                                <div className="big loader"></div>
                                <div className="small loader"></div>
                            </div>
                            {/* <div className="legend">
                                <div className="big loader"></div>
                                <div className="small loader"></div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="contents">
                <div className="left">
                    <div className="content-title">
                        <div className="title-text loader"></div>
                        <div className="button loader"></div>
                    </div>

                    <div className="content-body">
                        <div className="content-text ">
                            <div className="text-field loader"></div>
                            <div className="text-field loader"></div>
                            <div className="text-field loader"></div>
                            <div className="text-field loader"></div>
                        </div>
                        <div className="content-text ">
                            <div className="text-field loader"></div>
                            <div className="text-field loader"></div>
                            <div className="text-field loader"></div>
                            <div className="text-field loader"></div>
                        </div>
                        <div className="content-text ">
                            <div className="text-field loader"></div>
                            <div className="text-field loader"></div>
                            <div className="text-field loader"></div>
                            <div className="text-field loader"></div>
                        </div>
                        <div className="content-text ">
                            <div className="text-field loader"></div>
                            <div className="text-field loader"></div>
                            <div className="text-field loader"></div>
                            <div className="text-field loader"></div>
                        </div>
                        <div className="content-text ">
                            <div className="text-field loader"></div>
                            <div className="text-field loader"></div>
                            <div className="text-field loader"></div>
                            <div className="text-field loader"></div>
                        </div>
                    </div>
                </div>

                <div className="right">
                    <div className="content-title">
                        <div className="title-text loader"></div>
                        <div className="button loader"></div>
                    </div>

                    <div className="content-body">
                    <div className="content-text ">
                            <div className="text-field loader"></div>
                            <div className="text-field loader"></div>
                            <div className="text-field loader"></div>
                            {/* <div className="text-field loader"></div> */}
                        </div>
                        <div className="content-text ">
                            <div className="text-field loader"></div>
                            <div className="text-field loader"></div>
                            <div className="text-field loader"></div>
                            {/* <div className="text-field loader"></div> */}
                        </div>
                        <div className="content-text ">
                            <div className="text-field loader"></div>
                            <div className="text-field loader"></div>
                            <div className="text-field loader"></div>
                            {/* <div className="text-field loader"></div> */}
                        </div>
                        <div className="content-text ">
                            <div className="text-field loader"></div>
                            <div className="text-field loader"></div>
                            <div className="text-field loader"></div>
                            {/* <div className="text-field loader"></div> */}
                        </div>
                        <div className="content-text ">
                            <div className="text-field loader"></div>
                            <div className="text-field loader"></div>
                            <div className="text-field loader"></div>
                            {/* <div className="text-field loader"></div> */}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default LeavesOverviewLoadder
