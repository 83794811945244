import React, { useState, useEffect } from 'react';
import '../../styles/employees/employees.css';
import { IoClose } from "react-icons/io5";
import { FaSearch } from "react-icons/fa";
import { useGlobalContext } from '../../context/Context';
import AuthService from '../../services/auth';
import api, { API_URL } from '../../utils/api';
import axios from 'axios';
import Rating from 'react-rating';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Nodata from '../Nodata';


const EvaluatePerformance = ({ employees, isOpen, onClose }) => {

    const [searchQuery, setSearchQuery] = useState('');
    const [message, setMessage] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [addPerformance, setAddPerformance] = useState(false);
    const accessToken = AuthService.getToken();
    const [currentStep, setCurrentStep] = useState(1);
    const [formData, setFormData] = useState({
        selectedEmployee: null,
        job_understanding: '',
        job_understanding_comments: '',
        job_performance: '',
        job_performance_comments: '',
        job_productivity: '',
        job_productivity_comments: '',
        dependability: '',
        dependability_comments: '',
        cooperation: '',
        cooperation_comments: '',
        introductory_period_completed: false,
        recommend_extension: false
    });

    const kpiValues = [
        { "name": "Select Level", value: 1 },
        { "name": "Unacceptable", value: 1 },
        { "name": "Below standards", value: 2 },
        { "name": "Meet standards", value: 3 },
        { "name": "Above standards", value: 4 },
        { "name": "Exceeds standards", value: 5 }
    ];
    const totalSteps = 6;
    const progress = (currentStep / totalSteps) * 100;

    const handleRowClick = (employee) => {
        setFormData((prevData) => ({
            ...prevData,
            selectedEmployee: employee,
        }));
    };

    const handleNext = () => {
        setCurrentStep((prevStep) => prevStep + 1);
    };
    const handlePreviousStep = () => {
        setCurrentStep(currentStep > 1 ? currentStep - 1 : 1);
    };
    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: typeof value === 'string' ? value.trim() : value,
        }));
    }; const handleIntroductoryPeriodClick = () => {
        setFormData(prevState => ({
            ...prevState,
            introductory_period_completed: !prevState.introductory_period_completed,
        }));
    };
    const handleRecommendExtensionClick = () => {
        setFormData(prevState => ({
            ...prevState,
            recommend_extension: !prevState.recommend_extension,
        }));
    };

    const employeeId = formData.selectedEmployee?.employee_id;
    console.log("Employee ID:", employeeId);

    const token = localStorage.getItem('accessToken');

    const submitEvaluation = async () => {
        const {
            selectedEmployee,
            job_understanding,
            job_understanding_comments,
            job_performance,
            job_performance_comments,
            job_productivity,
            job_productivity_comments,
            dependability,
            dependability_comments,
            cooperation,
            cooperation_comments,
            introductory_period_completed,
            recommend_extension
        } = formData;
        if (!selectedEmployee) {
            console.error("No employee selected");
            return;
        }
        if (!job_understanding || !job_understanding_comments || !job_performance || ! job_performance_comments || !job_productivity  || ! job_productivity_comments || !dependability || ! dependability_comments || !cooperation || ! cooperation_comments) {
            console.error("Please fill in all required fields");
            return;
        }
        const employeeId = selectedEmployee.employee_id;
        if (!employeeId) {
            console.error("Invalid employee ID");
            return;
        }

        const url = `${API_URL}/api/evaluations/performance/${employeeId}/new/`;
        console.log("Request URL:", url);
        console.log("Form Data:", formData);
        console.log("Authorization Token:", token);

        try {
            setLoading(true)
            console.log("request data: ", formData)
            const response = await api.post(`${API_URL}/api/evaluations/performance/${employeeId}/new/`, formData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            if (response.status === 200) {
                console.log("Evaluation submitted successfully:", response.data);
                onClose();
            }
        } catch (error) {
            if (error.response) {
                console.error('Response data:', error.response.data);
                console.error('Response status:', error.response.status);
                console.error('Response headers:', error.response.headers);
            } else if (error.request) {
                console.error('Request data:', error.request);
            } else {
                console.error('Error message:', error.message);
            }
            console.error('Config:', error.config);
        } finally {
            setLoading(false);
        }
    };



    const sortedEmployees = [...employees].sort((a, b) => {
        const nameA = a.user?.first_name.toLowerCase() + ' ' + a.user?.last_name.toLowerCase();
        const nameB = b.user?.first_name.toLowerCase() + ' ' + b.user?.last_name.toLowerCase();
        return nameA.localeCompare(nameB);
    });
    const filteredEmployees = sortedEmployees.length > 0 && sortedEmployees.filter((employee) =>
        employee.user ? employee.user.first_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            employee.user.last_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            employee.employee_id.toLowerCase().includes(searchQuery.toLowerCase())
            : ''
    );
    const itemsPerPage = 3;
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = Math.min(startIndex + itemsPerPage, filteredEmployees.length);
    const currentEmployees = filteredEmployees.length > 0 && filteredEmployees.slice(startIndex, endIndex);


    if (!isOpen) return null;


    const getRatingDescription = (rating) => {
        if (rating >= 4.5) return 'Exceeds standards';
        if (rating >= 3.5) return 'Above standards';
        if (rating >= 3) return 'Meet standards';
        if (rating >= 1) return 'Below standards';
        return 'Unacceptable';
    };
    const calculateAverageRating = () => {
        const ratings = [
            parseInt(formData.job_understanding),
            parseInt(formData.job_performance),
            parseInt(formData.job_productivity),
            parseInt(formData.dependability),
            parseInt(formData.cooperation)
        ];
        const validRatings = ratings.filter(rating => rating > 1);
        if (validRatings.length === 0) return 0;
        const total = validRatings.reduce((sum, rating) => sum + rating, 0);
        return total / validRatings.length;
    };
    const dateToday = () => {
        const now = new Date();
        const options = {
            weekday: 'long',
            month: 'numeric',
            day: 'numeric',
            year: 'numeric',
            timeZone: 'Africa/Kigali',
        };
        return new Intl.DateTimeFormat('en-US', options).format(now);
    };


    console.log("Payload data:", formData);




    return (
        <div className="popup">
            <div className="performance">
                <form onSubmit={(e) => { e.preventDefault(); submitEvaluation(); }} id='form'>
                    {currentStep === 1 && (
                        <div className="form-step">
                            <div className="title">
                                <h3>90  Days Performance ratings</h3>
                                <p>{`${dateToday()}`}</p>
                            </div>
                            <div className="input-fields">
                                <div className="input">
                                    <label>Select employee:</label>
                                    <div className="icon">
                                        <FaSearch />
                                    </div>
                                    <input
                                        type="text"
                                        value={searchQuery}
                                        onChange={handleSearch}
                                        placeholder="Search employee Name..."
                                    />
                                </div>
                            </div>
                            <div className='table-contents'>
                                {loading ? (
                                    <div className="loading-container">
                                        <div className="loading">Loading</div>
                                        <div className="loading-dots">
                                            <span className="loading-dot">.</span>
                                            <span className="loading-dot">.</span>
                                            <span className="loading-dot">.</span>
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Employee Name</th>
                                                    <th>Position</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {currentEmployees && currentEmployees.length > 0 ? (
                                                    currentEmployees.map((employee) => (
                                                        <tr
                                                            key={employee.employee_id}
                                                            onClick={() => handleRowClick(employee)}
                                                            style={{ cursor: 'pointer', backgroundColor: formData.selectedEmployee?.employee_id === employee.employee_id ? '#f0f0f0' : 'transparent' }}
                                                        >
                                                            <td>
                                                                <span>
                                                                    {employee.user?.first_name?.charAt(0)}{employee.user?.last_name?.charAt(0)}
                                                                </span>
                                                                {' '}
                                                                {employee.user?.first_name} {employee.user?.last_name}
                                                            </td>
                                                            <td className='hidden-table-col'>{employee.position_name.name}</td>
                                                        </tr>
                                                    ))
                                                ) : <Nodata
                                                    style={`no-data`}
                                                    title='No employees data available...'
                                                />}
                                            </tbody>
                                        </table>
                                    </>
                                )}
                            </div>
                            <div className="btns">
                                <button className='cancel-btn' onClick={onClose} disabled={loading}>Cancel</button>
                                <button className='add-btn' onClick={handleNext}>Continue</button>
                            </div>
                        </div>
                    )}
                    {currentStep === 2 && (
                        <div className="form-step">
                            <div className="title2">
                                <div className="icon">
                                    <button onClick={onClose} disabled={loading}>
                                        <IoClose />
                                    </button>
                                </div>
                                <div>
                                    <h3>90  Days Performance ratings</h3>
                                    <p>{`${dateToday()}`}</p>
                                </div>
                            </div>
                            <Box sx={{ width: '100%' }} className="progress-container">
                                <LinearProgress className="progress-bar" variant="determinate" value={progress} />
                            </Box>
                            <div className='selectOption'>
                                <div className='title2'>
                                    <h3><span>1.</span> Job Understanding</h3>
                                    <p>
                                        Employee possesses a clear knowledge of the responsibilities and the task(s) <br />
                                        he or she must perform.
                                    </p>
                                </div>
                                <select
                                    className='selectOptions'
                                    value={formData.job_understanding}
                                    onChange={(e) => setFormData(prevData => ({ ...prevData, job_understanding: e.target.value }))}

                                >
                                    {kpiValues.map((kpi, key) => (
                                        <option key={key} value={kpi.value}>{kpi.name}</option>
                                    ))}
                                </select>
                                <div className="comments-section">
                                    <label>Comment:</label>
                                    <textarea
                                        placeholder="Add your comment"
                                        rows="4"
                                        onChange={(e) => setFormData(prevData => ({ ...prevData, job_understanding_comments: e.target.value }))}
                                        value={formData.job_understanding_comments}

                                    />
                                </div>
                            </div>
                            <div className="btns">
                                <button className='cancel-btn' onClick={handlePreviousStep}>Previous</button>
                                <button className='add-btn' onClick={handleNext}>Next</button>
                            </div>
                        </div>
                    )}
                    {currentStep === 3 && (
                        <div className="form-step">
                            <div className="title2">
                                <div className="icon">
                                    <button onClick={onClose} disabled={loading}>
                                        <IoClose />
                                    </button>
                                </div>
                                <div>
                                    <h3>90  Days Performance ratings</h3>
                                    <p>{`${dateToday()}`}</p>
                                </div>

                            </div>
                            <Box sx={{ width: '100%' }} className="progress-container">
                                <LinearProgress className="progress-bar" variant="determinate" value={progress} />
                            </Box>
                            <div className='selectOption'>
                                <div className='title2'>
                                    <h3><span>2.</span> Job Performance</h3>
                                    <p>
                                        The neatness, thoroughness, and accuracy of the employee work.
                                    </p>
                                </div>
                                <select
                                    className='selectOptions'
                                    value={formData.job_performance}
                                    onChange={(e) => setFormData(prevData => ({ ...prevData, job_performance: e.target.value }))}
                                >
                                    {kpiValues.map((kpi, key) => (
                                        <option key={key} value={kpi.value}>{kpi.name}</option>
                                    ))}
                                </select>
                                <div className="comments-section">
                                    <label>Comment:</label>
                                    <textarea
                                        placeholder="Add your comment"
                                        rows="4"
                                        onChange={(e) => setFormData(prevData => ({ ...prevData, job_performance_comments: e.target.value }))}
                                        value={formData.job_performance_comments}
                                    />
                                </div>
                            </div>
                            <div className="btns">
                                <button className='cancel-btn' onClick={handlePreviousStep}>Previous</button>
                                <button className='add-btn' onClick={handleNext}>Next</button>
                            </div>
                        </div>
                    )}
                    {currentStep === 4 && (
                        <div className="form-step">
                            <div className="title2">
                                <div className="icon">
                                    <button onClick={onClose} disabled={loading}>
                                        <IoClose />
                                    </button>
                                </div>
                                <div>
                                    <h3>90  Days Performance ratings</h3>
                                    <p>{`${dateToday()}`}</p>
                                </div>

                            </div>
                            <Box sx={{ width: '100%' }} className="progress-container">
                                <LinearProgress className="progress-bar" variant="determinate" value={progress} />
                            </Box>
                            <div className='selectOption'>
                                <div className='title2'>
                                    <h3><span>3.</span> Job Productivity</h3>
                                    <p>
                                        The quality of the employee’s work in terms of volume and overall accomplishments.
                                    </p>
                                </div>
                                <select
                                    className='selectOptions'
                                    value={formData.job_productivity}
                                    onChange={(e) => setFormData(prevData => ({ ...prevData, job_productivity: e.target.value }))}

                                >
                                    {kpiValues.map((kpi, key) => (
                                        <option key={key} value={kpi.value}>{kpi.name}</option>
                                    ))}
                                </select>
                                <div className="comments-section">
                                    <label>Comment:</label>
                                    <textarea
                                        placeholder="Add your comment"
                                        rows="4"
                                        onChange={(e) => setFormData(prevData => ({ ...prevData, job_productivity_comments: e.target.value }))}
                                        value={formData.job_productivity_comments}
                                    />
                                </div>
                            </div>
                            <div className="btns">
                                <button className='cancel-btn' onClick={handlePreviousStep}>Previous</button>
                                <button className='add-btn' onClick={handleNext}>Next</button>
                            </div>
                        </div>
                    )}
                    {currentStep === 5 && (
                        <div className="form-step">
                            <div className="title2">
                                <div className="icon">
                                    <button onClick={onClose} disabled={loading}>
                                        <IoClose />
                                    </button>
                                </div>
                                <div>
                                    <h3>90  Days Performance ratings</h3>
                                    <p>{`${dateToday()}`}</p>
                                </div>

                            </div>
                            <Box sx={{ width: '100%' }} className="progress-container">
                                <LinearProgress className="progress-bar" variant="determinate" value={progress} />
                            </Box>
                            <div className='selectOption'>
                                <div className='title2'>
                                    <h3><span>4.</span> Dependability</h3>
                                    <p>
                                        Can this employee be relied upon in terms of being on time and in the completion of  <br /> assigned task(s)?
                                    </p>
                                </div>
                                <select
                                    className='selectOptions'
                                    value={formData.dependability}
                                    onChange={(e) => setFormData(prevData => ({ ...prevData, dependability: e.target.value }))}

                                >
                                    {kpiValues.map((kpi, key) => (
                                        <option key={key} value={kpi.value}>{kpi.name}</option>
                                    ))}
                                </select>
                                <div className="comments-section">
                                    <label>Comment:</label>
                                    <textarea
                                        placeholder="Add your comment"
                                        rows="4"
                                        onChange={(e) => setFormData(prevData => ({ ...prevData, dependability_comments: e.target.value }))}
                                        value={formData.dependability_comments}
                                    />
                                </div>
                            </div>
                            <div className="btns">
                                <button className='cancel-btn' onClick={handlePreviousStep}>Previous</button>
                                <button className='add-btn' onClick={handleNext}>Next</button>
                            </div>
                        </div>
                    )}
                    {currentStep === 6 && (
                        <div className="form-step">
                            <div className="title2">
                                <div className="icon">
                                    <button onClick={onClose} disabled={loading}>
                                        <IoClose />
                                    </button>
                                </div>
                                <div>
                                    <h3>90  Days Performance ratings</h3>
                                    <p>{`${dateToday()}`}</p>
                                </div>

                            </div>
                            <Box sx={{ width: '100%' }} className="progress-container">
                                <LinearProgress className="progress-bar" variant="determinate" value={progress} />
                            </Box>
                            <div className='selectOption'>
                                <div className='title2'>
                                    <h3><span>5.</span> Cooperation</h3>
                                    <p>
                                        The ability to work willingly with associates, subordinates, supervisors, and others
                                    </p>
                                </div>
                                <select
                                    className='selectOptions'
                                    value={formData.cooperation}
                                    onChange={(e) => setFormData(prevData => ({ ...prevData, cooperation: e.target.value }))}

                                >
                                    {kpiValues.map((kpi, key) => (
                                        <option key={key} value={kpi.value}>{kpi.name}</option>
                                    ))}
                                </select>
                                <div className="comments-section">
                                    <label>Comment:</label>
                                    <textarea
                                        placeholder="Add your comment"
                                        rows="4"
                                        onChange={(e) => setFormData(prevData => ({ ...prevData, cooperation_comments: e.target.value }))}
                                        value={formData.cooperation_comments}
                                    />
                                </div>
                            </div>
                            <div className="btns">
                                <button className='cancel-btn' onClick={handlePreviousStep} >Previous</button>
                                <button className='add-btn' onClick={handleNext}>Next</button>
                            </div>
                        </div>
                    )}
                    {currentStep === 7 && (
                        <div className="form-step">
                            <div className="title2">
                                <div className="icon">
                                    <button onClick={onClose}>
                                        <IoClose />
                                    </button>
                                </div>
                                <div>
                                    <h3>90  Days Performance ratings</h3>
                                    <p>{`${dateToday()}`}</p>
                                </div>
                            </div>
                            <div className='employee-performance'>
                                <div className='title'>
                                    <span>{formData.selectedEmployee?.user?.first_name?.charAt(0)}{formData.selectedEmployee?.user?.last_name?.charAt(0)}</span>
                                    <div>
                                        <p>{formData.selectedEmployee?.user?.first_name} {formData.selectedEmployee?.user?.last_name}</p>
                                        <small>{formData.selectedEmployee?.position_name?.name}</small>
                                    </div>
                                </div>
                                <div className='ratings'>
                                    <div className='ratings-stars'>
                                        <p> Overall Rating </p>
                                        <Rating
                                            emptySymbol={<span className="star">☆</span>}
                                            fullSymbol={<span className="starfull">☆</span>}
                                            initialRating={calculateAverageRating()}
                                            readonly
                                        />
                                    </div>
                                    <div className='ratings-bttn'>
                                        <p>{calculateAverageRating()}</p>
                                        <h5> {getRatingDescription(calculateAverageRating())} </h5>
                                    </div>
                                </div>
                                <div className='checkIf'>
                                    <label>Check if  it’s yes</label>
                                    <button
                                        type="button"
                                        className={`ratings-bttn ${formData.introductory_period_completed ? 'selected' : ''}`}
                                        onClick={handleIntroductoryPeriodClick}
                                    >
                                        <code></code>
                                        <p> Was employee’s probation completed satisfactory  </p>
                                    </button>
                                    <button
                                        type="button"
                                        className={`ratings-bttn ${formData.recommend_extension ? 'selected' : ''}`}
                                        onClick={handleRecommendExtensionClick}
                                    >
                                        <code></code>
                                        <p> Do you recommend 30 day extension to the probation period </p>
                                    </button>
                                </div>
                            </div>
                            <div className="btns">
                                <button className='add-btn' onClick={submitEvaluation} >
                                    {addPerformance ? (
                                        <div className="requesting-container">
                                            <div className='requesting'>Submitting...</div>
                                            <div className="requesting-dots">
                                                <span className="requesting-dot">.</span>
                                                <span className="requesting-dot">.</span>
                                                <span className="requesting-dot">.</span>
                                            </div>
                                        </div>
                                    ) : 'Submit Evaluation'}
                                </button>
                            </div>
                        </div>
                    )}
                </form>
            </div>
        </div>
    );
};

export default EvaluatePerformance;