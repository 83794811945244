import React, { useState } from 'react';
import OverviewContent from '../../../pages/dashboard/trainingCenter/cohorts/ovierviewContent';
import "../../../styles/components/cohortdetailstab.css"
import { UserGroupIcon, DocumentValidationIcon, DepartementIcon, TransactionIcon, Quiz04Icon, DashboardSquare01Icon, Settings02Icon } from 'hugeicons-react';
import TrainingCenterApplicationsPage from '../../../pages/dashboard/trainingCenter/trainingCenterApplications';
import TrainingCenterApplications from '../../../pages/dashboard/trainingCenter/trainingCenterApplications';

const CohortDetailsTabs = ({ cohortDetails }) => {
  const [activeTab, setActiveTab] = useState('overview');

  const tabContent = {
    overview: <OverviewContent cohortDetails={cohortDetails} />,
    application: <TrainingCenterApplications cohortDetails={cohortDetails}  />,
    courses: <h1>Hello</h1>,
    department: <h1>Hello</h1>,
    transaction: <h1>Hello</h1>,
    examQuizzes: <h1>Hello</h1>,
    settings: <h1>Hello</h1>
  };

  return (
    <div className="cohort-details-tabs">
      <div className="cohort-tab-buttons">
        <div className={`tab-button ${activeTab === 'overview' ? 'active' : ''}`}
          onClick={() => setActiveTab('overview')}>
          <DashboardSquare01Icon
            size={20}
            variant={"stroke"}
          />
          <p>
            Overview
          </p>
        </div>
        <div
          className={`tab-button ${activeTab === 'application' ? 'active' : ''}`}
          onClick={() => setActiveTab('application')}
        >
          <UserGroupIcon
            size={24}
            variant={"stroke"}
          />
          <p>Applicants</p>
        </div>
        <div className={`tab-button ${activeTab === 'courses' ? 'active' : ''}`}
          onClick={() => setActiveTab('courses')}>
          <DocumentValidationIcon
            size={24}
            variant={"stroke"}
          />
          <p>
            Courses
          </p>
        </div>
        <div className={`tab-button ${activeTab === 'department' ? 'active' : ''}`}
          onClick={() => setActiveTab('department')}>
          <DepartementIcon
            size={20}
            variant={"stroke"}
          />
          <p>

            Department
          </p>
        </div>
        <div className={`tab-button ${activeTab === 'transaction' ? 'active' : ''}`}
          onClick={() => setActiveTab('transaction')}>
          <TransactionIcon
            size={20}
            variant={"stroke"}
          />
          <p>

            Transaction
          </p>
        </div>
        <div className={`tab-button ${activeTab === 'examQuizzes' ? 'active' : ''}`}
          onClick={() => setActiveTab('examQuizzes')}>
          <Quiz04Icon
            size={20}
            variant={"stroke"}
          />
          <p>
            Exam & Quizzes
          </p>
        </div>
        <div className={`tab-button ${activeTab === 'settings' ? 'active' : ''}`}
          onClick={() => setActiveTab('settings')}>
          <Settings02Icon
            size={20}
            variant={"stroke"}
          />
          <p>
            Settings
          </p>
        </div>
      </div>
      <div className="tab-content">
        {tabContent[activeTab]}
      </div>
    </div>
  );
};

export default CohortDetailsTabs;