import { useState } from "react"
import { useMsal } from "@azure/msal-react"
import '../styles/login/login.css'
import { useNavigate } from "react-router-dom"
import AuthService from "../services/auth"
import { CiMail } from "react-icons/ci"
import { FaKey } from "react-icons/fa"
import axios from "axios"
import { useGlobalContext } from "../context/Context"
import Cookies from "js-cookie"

const Login = () => {
  const { instance } = useMsal()
  const { API_URL } = useGlobalContext()
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [isLoggingIn, setIsLoggingIn] = useState(false)
  const [message, setMessage] = useState('')

  const navigate = useNavigate()

  const handleAuth = async (e) => {
    e.preventDefault()
    setIsLoggingIn(true)
    setMessage('')

    try {
      const res = await AuthService.login(username, password)

      if (res.error) {
        setMessage(res.error)
      } else {
        console.log(res.user, res.permissions)
        AuthService.checkPermissions(setIsLoggingIn)
        navigate('/')
      }
    } catch (error) {
      setMessage(error.message)
    } finally {
      setTimeout(() => {
      }, 3000)
    }
  };

  const handleLoginWithMicrosoft = async () => {
    try {
      const loginRequest = {
        scopes: ["openid", "profile", "email", "User.Read"],
        prompt: "select_account",
      };
      const loginResponse = await instance.loginPopup(loginRequest);
      console.log(loginResponse)
      const accessToken = loginResponse.accessToken;
      await fetchUserDetails(accessToken);
    } catch (error) {

      // setIsLoading(false);
      console.log(error);
    }
  }
  const fetchUserDetails = async (accessToken) => {
    try {
      const response = await axios.get(`${API_URL}/api/token/ms/${accessToken}/`)
      const data = await response.data
      console.log('user data: ', data)
      Cookies.set('accessToken', response.data.access, { secure: true });
      Cookies.set('refreshToken', response.data.refresh, { secure: true });
      localStorage.setItem('permissions', JSON.stringify(response.data.permissions))
      window.location.href = '/'
    } catch (error) {
      console.log("Error during login");
    }
  }

  return (
    <section className='login-section'>
      <img src="/images/csr-limited-Rwanda-staff.jpg" alt="" />
      <div className='col'>
        {/* <img src="/images/logo-init.svg" alt="" /> */}
        <h1>CSR <span>Back</span> Office</h1>
        <form method='post' onSubmit={handleAuth}>

          <div className="login-form">
            <p>Please enter your email to access CSR back office.</p>
            {message && (
              <p className='message'>{message}</p>
            )}
            <div className='input'>
              <div className='icon'>
                <CiMail />
              </div>
              <input
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder='Enter your email'
                name="name"
                required
              />
            </div>
            <div className='input'>
              <div className='icon'>
                <FaKey />
              </div>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder='Enter your password'
                name="password"
                required
              />
            </div>
            <div className='forgot-password'>
              {/* <button type="button" onClick={openResetPasswordForm}>Forgot password?</button> */}
            </div>
            <button type="submit" disabled={isLoggingIn} className="login-btn">
              {isLoggingIn ? 'Logging in...' : 'Login'}
            </button>
          </div>
        </form>
        {/* Or
        <div className='social-media'>
          <button onClick={handleLoginWithMicrosoft} className='google-btn'>Sign in with your company email</button>
        </div> */}
      </div>
    </section>
  );
};

export default Login;
