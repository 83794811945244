import { useState, useEffect } from 'react'
import { useGlobalContext } from '../context/Context'
import '../styles/leaveRecord/leave-record.css'
import '../styles/leaveRecord/leave-requests.css'
import { FaSearch, FaRegTrashAlt } from "react-icons/fa"
import { IoFilter, IoClose, IoCheckmark } from "react-icons/io5"
import { BsThreeDots } from "react-icons/bs"
import { CiUser } from "react-icons/ci"
import LeaveNumbers from './LeaveNumbers'
import Nodata from './Nodata'

// to be replaced with data from the database
import AuthService from '../services/auth'


const LeaveRequests = () => {
  const {
    currentPage,
    handlePage,
    itemsPerPage,
    isPopupOpen,
    openPopup,
    closePopup,
    leaves,
    API_URL,
    setLeaves
  } = useGlobalContext()

  // state values
  const [openLeaveDetails, setOpenLeaveDetails] = useState(false)
  const [loading, setLoading] = useState(true)
  const [acceptedLeaves, setAcceptedLeaves] = useState([])
  const [rejectedLeaves, setRejectedLeaves] = useState([])
  const [user, setUser] = useState(null)
  const [positions, setPositions] = useState([])

  // variables declaration
  const startIndex = (currentPage - 1) * itemsPerPage
  const endIndex = Math.min(startIndex + itemsPerPage, leaves.length)
  const currentLeaves = leaves.length > 0 && leaves.slice(startIndex, endIndex)
  const accessToken = AuthService.getToken()

  const handleLeaveRequestDetails = () => {
    setOpenLeaveDetails(true)
  }

  const closeLeaveRequestDetails = () => {
    setOpenLeaveDetails(false)
  }

  // leaves approval
  const handleLeaveApproval = async (leaveId, statusName) => {
    setAcceptedLeaves([...acceptedLeaves, leaveId])
    try {
      await fetch(`${API_URL}/api/update_leave_status/${leaveId}`, {
        method: 'PATCH',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ 'status_name': statusName })
      })
      setLeaves((prev) => prev.filter((leave) => leave.id !== leaveId))
      setAcceptedLeaves((prevIds) => prevIds.filter((id) => id !== leaveId))
    } catch (error) {
      console.log(error)
      setAcceptedLeaves((prevIds) => prevIds.filter((id) => id !== leaveId))
    }
  }

  // reject leave requests
  const handleLeaveRejection = async (leaveId, statusName) => {
    setRejectedLeaves([...rejectedLeaves, leaveId])
    try {
      await fetch(`${API_URL}/api/update_leave_status/${leaveId}`, {
        method: 'PATCH',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ 'status_name': statusName })
      })
      setLeaves((prev) => prev.filter((leave) => leave.id !== leaveId))
      setRejectedLeaves((prevIds) => prevIds.filter((id) => id !== leaveId))
    } catch (error) {
      console.log(error)
      setRejectedLeaves((prevIds) => prevIds.filter((id) => id !== leaveId))
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [userRes, leaveRequestsRes, positionsRes] = await Promise.all([
          fetch(`${API_URL}/api/get_user_info`, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${accessToken}`
            }
          }),
          fetch(`${API_URL}/api/employee/leaves/`, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${accessToken}`
            }
          }),
          fetch(`${API_URL}/api/list_positions/`, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${accessToken}`
            }
          })
        ])
        const userData = await userRes.json()
        const leavesData = await leaveRequestsRes.json()
        const positionsData = await positionsRes.json()

        let filteredLeaves = leavesData

        if(userData.position_name === 'Director') {
          filteredLeaves = leavesData.filter(leave => leave.approval_level === 'none' || leave.status_name === 'pending')
        } else if (userData.position_name === 'Operations') {
          filteredLeaves = leavesData.filter(leave => leave.approval_level === 'departmental')
        } else if (userData.position_name === 'Human resources') {
          filteredLeaves = leavesData.filter(leave => leave.approval_level === 'operations')
        }


        setLoading(false)
        setUser(userData)
        setLeaves(filteredLeaves)
        setPositions(positionsData)

      } catch (error) {
        console.log(error)
      }
    }
    fetchData()
  }, [])

  const managementPositions = positions.filter((position) =>
    position.name === 'Director' ||
    position.name === 'Operations' ||
    position.name === 'Human resources'
  )
  const displayAcceptNumbers = user && managementPositions.some(position => position.name === user.position_name)

  return (
    <>
      {isPopupOpen && (
        <div className="actions-popup-menu" onClick={closePopup}>
          <div className="actions-popup">
            <button onClick={handleLeaveRequestDetails}>
              <span><CiUser /></span> Leave details
            </button>
            <button>
              <span><FaRegTrashAlt /></span> Remove
            </button>
          </div>
        </div>
      )}

      {/* leave */}
      {openLeaveDetails && (
        <div className="leave-details-popup" onClick={closeLeaveRequestDetails}>
          <div className="leave-details">
            <div className="row">
              <div className="col">
                <h1>Leave Details</h1>
              </div>
              <div className="col">
                <button onClick={closeLeaveRequestDetails}>
                  <IoClose />
                </button>
              </div>
            </div>
            <div className='employee-details'>
              <div className='row-1'>
                <div className='col-1'>
                  <img src="/images/user-photo.jpg" alt="profile" />
                </div>
                <div className='col-1'>
                  <h4>Mugisha Yvan</h4>
                  <p>Software Developer</p>
                </div>
              </div>
              <div className="line"></div>
              <div className='col-1'>
                <h4>Available Leaves</h4>
                <p>28</p>
              </div>
              <div className="line"></div>
              <div className='col-1'>
                <h4>Taken Leaves</h4>
                <p>28</p>
              </div>
            </div>
            <div className="leave-dates">
              <div className="col-1">
                <h5>Type</h5>
                <p>Annual Leave</p>
              </div>
              <div className="col-1">
                <h5>Leave date</h5>
                <p>17 June 2023</p>
              </div>
              <div className="col-1">
                <h5>Return date</h5>
                <p>17 June 2023</p>
              </div>
            </div>
            <div className="leave-reason">
              <div className="col-1">
                <h5>Reason</h5>
                <p>Attend significant family events</p>
              </div>
              <div className="col-btns">
                <button className='close-btn'>
                  <IoClose />
                </button>
                <button className='accept-btn'>
                  <span>
                    <IoCheckmark />
                  </span>
                  Accept
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <LeaveNumbers />

      <div className="contents-container">
        <div className='title-contents'>
          <div className='top'>
            <div className="col">
              <h4>Leave Requests</h4>
              <p><span>{leaves.length}</span> leave Requested</p>
            </div>
            <div className="input-fields">
              <div className="input">
                <form>
                  <input type="text" placeholder='Employee Name  , Leave type ,.....' />
                </form>
                <div className="icon">
                  <FaSearch />
                </div>
              </div>
            </div>
            <div className='col'>
              <button><span className='icon'><IoFilter /></span> Recent</button>
            </div>
          </div>
        </div>

        <div className="leave-requests-data">
          {loading ? (
            <div className="loading-container">
              <div className="loading">Loading</div>
              <div className="loading-dots">
                <span className="loading-dot">.</span>
                <span className="loading-dot">.</span>
                <span className="loading-dot">.</span>
              </div>
            </div>
          ) : (
            currentLeaves && currentLeaves.length > 0 ? (
              <>
                {currentLeaves.map((leave) => (
                  <div className="leave-request">
                    <div className="col-profile">
                      <div className="user-names">
                        <p>
                          {leave.employee_name.user.first_name.charAt(0)}{leave.employee_name.user.last_name.charAt(0)}
                        </p>
                      </div>
                      <div className="user-details">
                        <h4>{leave.employee_name.user.first_name} {leave.employee_name.user.last_name}</h4>
                        <p>{leave.leave_type.created_at.slice(0, 10)}</p>
                      </div>
                    </div>
                    <div className="col">
                      <h4 className="title">Department</h4>
                      <p>{leave.employee_name.department_name.name}</p>
                    </div>
                    <div className="col">
                      <h4 className="title">Type</h4>
                      <p>{leave.leave_type.name}</p>
                    </div>
                    <div className="col">
                      <h4 className="title">Leave date</h4>
                      <p>{leave.start_date.slice(0, 10)}</p>
                    </div>
                    <div className="col">
                      <h4 className="title">Return date</h4>
                      <p>{leave.end_date.slice(0, 10)}</p>
                    </div>
                    <div className="col">
                    </div>
                    {displayAcceptNumbers && (
                      <div className="col-btns">
                        <button className='close-btn' onClick={() => handleLeaveRejection(leave.id, 'rejected')}>
                          {rejectedLeaves.includes(leave.id) ? (
                            <div className="requesting-container">
                              <div className='requesting'>Saving</div>
                              <div className="requesting-dots">
                                <span className="requesting-dot">.</span>
                                <span className="requesting-dot">.</span>
                                <span className="requesting-dot">.</span>
                              </div>
                            </div>
                          ) : (
                            <IoClose />
                          )}

                        </button>
                        <button className='accept-btn' onClick={() => handleLeaveApproval(leave.id, 'approved')}>
                          {acceptedLeaves.includes(leave.id) ? (
                            <div className="requesting-container">
                              <div className='requesting'>Saving</div>
                              <div className="requesting-dots">
                                <span className="requesting-dot">.</span>
                                <span className="requesting-dot">.</span>
                                <span className="requesting-dot">.</span>
                              </div>
                            </div>
                          ) : (
                            <>
                              <span>
                                <IoCheckmark />
                              </span>
                              Accept
                            </>
                          )}
                        </button>
                        {/* <button className='actions-btn' onClick={openPopup}>
                          <BsThreeDots />
                        </button> */}
                      </div>
                    )}
                  </div>
                ))}
              </>
            ) : (
              <Nodata
                style={`no-data`}
                title='No current Leaves requests'
              />
            )
          )}
        </div>

        <div className="pagination">
          <button
            className='prev-btn'
            onClick={() => handlePage(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          {Array.from({ length: Math.ceil(leaves.length / itemsPerPage) }, (_, i) => (
            <button
              key={i + 1}
              className={`page ${currentPage === i + 1 ? 'active' : ''}`}
              onClick={() => handlePage(i + 1)}>
              {i + 1}
            </button>
          ))}
          <button
            className='next-btn'
            onClick={() => handlePage(currentPage + 1)}
            disabled={endIndex >= leaves.length}
          >
            Next
          </button>
        </div>
      </div>
    </>
  )
}

export default LeaveRequests
