import React, {useState, useEffect} from "react";
import '../../styles/canteen/home.css'
import { Order } from "./order";
import api, { API_URL} from "../../utils/api";



const MenuItem = ({ menuItemImage, menuItemName, price, servedIn}) => {
    const handleImageError = (e) => {
        e.target.src = '/images/image-placeholder.svg'
    }

    return(
        <div className="menu-item">
            <div className="menu-image">
                <img src={menuItemImage} alt={menuItemName} onError={handleImageError} />
            </div>
            <div className="menu-item-description">
                <p>{menuItemName}</p>
                <div className="last-row">
                    <div className="price-div">
                        <p className="price">{price}</p>
                        <span className="currency">Rwf</span>
                    </div>
                    


                    <p className="served">{servedIn}</p>
                </div>
                
            </div>
        </div>
    )
}

const Menu = ({ activeCategory, searchQuery }) => {
    const [categoryData, setCategoryData] = useState({categories: []});
    const [menuData, setMenuData] = useState({menu_items: []});

    useEffect(() => {
        const fetchMenuData = async () => {
            try{
                const res = await api.get(`${API_URL}/api/canteen/`);

                if(res.status === 200){
                    setMenuData(res.data)
                    console.log(res.data)
                }
                
            } catch(err){
                console.error('Error fetching menu data', err)
            }
        }

        fetchMenuData()
    }, [])

      
      
      const handleMenuItemClick = (item) => {
        const existingOrderData = sessionStorage.getItem('orderData');
        let orderData = existingOrderData ? JSON.parse(existingOrderData) : [];

        const existingItemIndex = orderData.findIndex(orderItem => orderItem.id === item.id);
        if (existingItemIndex >= 0) {
            orderData[existingItemIndex].quantity += 1;
        } else {
            orderData.push({ ...item, quantity: 1 });
        }

        sessionStorage.setItem('orderData', JSON.stringify(orderData));

        const event = new Event('orderDataUpdated');
        window.dispatchEvent(event);
    };

   
    return(
        <>
        <div className="menu-title">
            <h2>Coffee Menu</h2>
            <div className="count">
                {menuData.menu_items.length}
            </div>
        </div>
        

        <div className="menu" id="menu">
        {menuData.menu_items.map((item) => (
          <div key={item.id} onClick={() => handleMenuItemClick(item)} style={{cursor: 'pointer'}} >
            <MenuItem
              menuItemImage={item.image}
              menuItemName={item.name}
              price={item.price}
              servedIn={item.servedIn}
            />
          </div>
        ))}
        </div>
        </>
    )
}

export default Menu;