import React, { useState, useEffect } from "react";
import { IoBriefcase } from "react-icons/io5";

const ProjectDetailsContainer = ({ project }) => {
    return (
        <div className="project-details">
            <div className="col">
                <div className="col-top">
                    <div className="project-title">
                        <div className="icon">
                            <IoBriefcase />
                        </div>
                        <div className="title">
                            <h4>{project?.title}</h4>
                            <p>{project?.company_name}</p>
                        </div>
                    </div>
                    <div className="timeline">
                        <h5>Timeline</h5>
                        <p>{project?.start_date} - {project?.end_date}</p>
                    </div>
                    <div className="department">
                        <h5>Department</h5>
                        <p>{project?.department_name}</p>
                    </div>
                </div>
                <div className="description">
                    <h5>Description</h5>
                    <p>{project?.description}</p>
                </div>
            </div>
            <div className="col">
                <button className="status-btn">Change Status</button>
            </div>
        </div>
    )
}
export default ProjectDetailsContainer