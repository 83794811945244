import React, { useEffect, useState } from 'react';
import DashboardContainerV2 from '../../components/dashbaord/dashboardContainerV2.jx';
import Categories from '../../components/canteen/categories';
import Menu from '../../components/canteen/menu';

const CanteenPageContent = () => {
    return(
        <>
        <Categories/>
        <Menu/>
        </>
    )
}

const CanteenPage = () => {
    return(
        <>
            <DashboardContainerV2 content={<CanteenPageContent/>}/>
        
        </>
    )
}

export default CanteenPage;