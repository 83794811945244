import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import api, { API_URL } from '../../../utils/api'
import '../../../styles/trainingCenter/cohorts/cohorts.css'
import { BsBookmarkFill } from 'react-icons/bs'
import DashboardContainerV2 from '../../../components/dashbaord/dashboardContainerV2.jx'
import { ArrowRight02Icon, Calendar01Icon, Layers02Icon, UserMultiple02Icon } from 'hugeicons-react'
import FormattedDate from '../../../components/formattedDate'

// const TrainingCenterPageContent = () => {
//     const { API_URL } = useGlobalContext()
//     const [loggedIn, setLoggedIn] = useState(false)
//     const [cohorts, setCohorts] = useState([])
//     const [fetchingData, setFetchingData] = useState(true)

//     useEffect(() => {
//         const fetchCohorts = async () => {
//             try {
//                 const response = await api.get(`${API_URL}/api/training-center/cohorts/`)
//                 if (response.status === 200) {
//                     setCohorts(response.data)
//                     console.log(response.data)
//                     setFetchingData(false)
//                 }
//             } catch (error) {
//                 console.error(error)
//                 setFetchingData(false)
//                 console.log('Error fetching cohorts:', error)
//             }
//         }
//         fetchCohorts()
//     }, [])
//     return (
//         <section className="contents">
//             <div className='underline'></div>
//             <div className="contents-container">
//                 {
//                     fetchingData
//                         ? '..getting data'
//                         : <>
//                             <div className='trainingApplicantsCards'>
//                                 {cohorts && cohorts.map((cohort, index) => (
//                                     <Link key={index} to={`/training-center/cohorts/${cohort.id}/applications/`} className='trainingApplicantsCard'>
//                                         <div className='trainingApplicantsCardsTitle'>
//                                             <div className='trainingApplicantsCardsTitleImg'>
//                                                 <BsBookmarkFill />
//                                             </div>
//                                             <h4>{cohort.name}</h4>
//                                         </div>
//                                         <div className='trainingApplicantsInfo'>
//                                             <div className='trainingApplicantsInfoCard'>
//                                                 <p className='infoCardp1'>Applications</p>
//                                                 <p className='infoCardp2'>{cohort.start_date}</p>
//                                             </div>
//                                             <div className='trainingApplicantsInfoCard'>
//                                                 <p className='infoCardp1'>Trainings</p>
//                                                 <p className='infoCardp2'>{cohort.trainings.length}</p>
//                                             </div>
//                                         </div>
//                                     </Link>
//                                 ))}
//                             </div>
//                         </>
//                 }
//             </div>
//         </section>

//     )
// }

const TrainingCenterPageOverviewContent = () => {
    const [isLoading, setIsLoading] = useState(true)
    const [overviewData, setOverviewData] = useState([])
    const [quizzes, setQuizzes] = useState([])

    const quizzesData = [
        {
            'title': 'Basic Troubleshooting',
            'date': '27/07/2024',
            'attendees': '120',
            'type': 'Exam',
            'department': 'Tech support'
        },
        {
            'title': 'Web design',
            'date': '7/07/2024',
            'attendees': '10',
            'type': 'Quiz',
            'department': 'Software Dev'
        },
        {
            'title': 'Finance accounting',
            'date': '27/05/2024',
            'attendees': '90',
            'type': 'Exam',
            'department': 'Accounting & Finance'
        }
    ]
    useEffect(() => {
        const getOverviewData = async () => {
            try {
                const response = await api.get(`${API_URL}/api/training-center/`)
                if (response.status === 200) {
                    setOverviewData(response.data.data)
                    setQuizzes(quizzesData)
                    console.log(response.data.data)
                    setIsLoading(false)
                }
            } catch (error) {
                console.log(error)
                setIsLoading(false)
            }
        }
        getOverviewData()
    }, [])


    return isLoading ? (
        <div>...loading</div>
    ) : (
        <div className='jobs-page contents-container'>
            {/* add breadcrumbs here */}
            <div className="numbers">
                <div className="number">
                    <div className="icon">
                        <UserMultiple02Icon />
                    </div>
                    <div className="number-content">
                        <p>Total Trainees</p>
                        <h3>{overviewData.trainings_count}</h3>
                    </div>
                </div>
                <div className="number">
                    <div className="icon">
                        <Layers02Icon />
                    </div>
                    <div className="number-content">
                        <p>Total Earnings</p>
                        <h3>{overviewData.total_fees}Rwf</h3>
                    </div>
                </div>
                <div className="number">
                    <div className="icon">
                        <BsBookmarkFill />
                    </div>
                    <div className="number-content">
                        <p>Total Cohorts</p>
                        <h3>{overviewData.cohorts_count}</h3>
                    </div>
                </div>
                <div className="number">
                    <div className="icon">
                        <Layers02Icon />
                    </div>
                    <div className="number-content">
                        <p>Total Departments</p>
                        <h3>{overviewData.departments_count}</h3>
                    </div>
                </div>
            </div>

            <div className="cohorts-and-quizzes-container">
                <div className="cohorts-container">
                    <div className="card-header">
                        <h3>Cohorts</h3>
                        <Link className='view-all' to={`/v2/training-center/cohorts/`}>
                            See all
                            <ArrowRight02Icon size={24} />
                        </Link>
                    </div>
                    <div className="cohorts-list">
                        {
                            overviewData.cohorts &&
                            overviewData.cohorts.map((cohort, index) => (
                                <Link to={`/v2/training-center/cohorts/${cohort.id}/`} key={index} className="cohort-card">
                                    <div className="title-status">
                                        <h4>{cohort.name}</h4>
                                        <div className={`status ${cohort.is_active ? 'active' : ''}`}>
                                            {cohort.is_active ? <p>Open</p> : <p>Closed</p>}
                                        </div>
                                    </div>
                                    <div className="dates">
                                        <p>{cohort.start_date} - {cohort.end_date}</p>
                                    </div>

                                    <div className="cohort-numbers">
                                        <div className="number">
                                            <p>Trainings</p>
                                            <h4>{cohort.trainees_count}</h4>
                                        </div>
                                        <div className="number">
                                            <p>Open spots</p>
                                            <h4>{cohort.open_spots}</h4>
                                        </div>
                                    </div>
                                </Link>
                            ))
                        }
                    </div>
                </div>
                <div className="quizzes-container">
                    <div className="card-header">
                        <h3>QUizzes and exams</h3>
                        <Link className='view-all' to={`/v2/training-center/`}>
                            See all
                            <ArrowRight02Icon size={24} />
                        </Link>
                    </div>
                    <div className="quizzes-list">
                        {
                            quizzes.map((quiz, index) => (
                                <div key={index} className="quiz">
                                    <div className="title-date">
                                        <h4>{quiz.title}</h4>
                                        <div className="date-attendees">
                                            <div className="date"> <Calendar01Icon size={12} /> <p>{quiz.date}</p></div>
                                            <div className="attendees"> <UserMultiple02Icon size={12} /> <p>{quiz.attendees}</p></div>
                                        </div>
                                    </div>
                                    <div className="type-department">
                                        <small className='type'>{quiz.type}</small>
                                        <small className="department">{quiz.department}</small>
                                    </div>
                                </div>
                            ))
                        }
                    </div>

                </div>
            </div>

            <div className="department-transactions">
                {
                    overviewData.departments && <div className="departments">
                        <div className="card-header">
                            <h3>Departments</h3>
                            <Link className='view-all' to={`/v2/training-center/`}>
                                See all
                                <ArrowRight02Icon size={24} />
                            </Link>
                        </div>
                        <div className="departments-list">
                            {overviewData.departments.map((department, index) => (
                                <div key={index} className="department">
                                    <div className="title">
                                        <div className="shape"></div>
                                        <h4 className='department-name'>{department.name}</h4>
                                    </div>
                                    <div className="trainees-count">
                                        <p>{department.trainees}</p>
                                        <div className="percentage">44%</div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>


                }
                {
                    overviewData.recent_transactions && <div className='recent-transactions'>
                        <div className="card-header">
                            <h3>Recent Transactions</h3>
                            <Link className='view-all' to={`/v2/training-center/`}>
                                See all
                                <ArrowRight02Icon size={24} />
                            </Link>
                        </div>
                        <div className="transactions-list">
                            <div className="table-contents">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Program</th>
                                            <th>Date/Time</th>
                                            <th>Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            overviewData.recent_transactions.map((transaction, index) => (
                                                <tr key={index}>
                                                    <td>{transaction.user}</td>
                                                    <td>{transaction.training}</td>
                                                    <td><FormattedDate date={transaction.timestamp} /></td>
                                                    <td>{transaction.amount}Rwf</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

const TrainingCenterPage = () => {
    return (
        <DashboardContainerV2 content={<TrainingCenterPageOverviewContent />} />
    )
}
export default TrainingCenterPage
