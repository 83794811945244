export const authorsArray = [
    {
        "name": "John Doe",
        "id": 1
    },
    {
        "name": "Jane Smith",
        "id": 2
    },
    {
        "name": "Mike Johnson",
        "id": 3
    },
    {
        "name": "Sarah Brown",
        "id": 4
    },
    {
        "name": "David Wilson",
        "id": 5
    },
    {
        "name": "Emily Davis",
        "id": 6
    },
    {
        "name": "Michael Wilson",
        "id": 7
    },
    {
        "name": "Sarah Johnson",
        "id": 8
    },
    {
        "name": "David Johnson",
        "id": 9
    },
    {
        "name": "Emily Smith",
        "id": 10
    }
]
