import React from 'react';
import { PieChart, Pie, Cell, Tooltip, Label } from 'recharts';
import { Typography, Box } from '@mui/material';

const CoursesCard = ({ cohortDetails }) => {

  const totalCourses = cohortDetails.overview.courses.total;
  const completedCourses = 40; 
  const upcomingCourses = cohortDetails.overview.courses.upcoming;
  const completedPercentage = cohortDetails.overview.courses.completed_percentage;


  const data = [
    { name: 'Completed', value: completedCourses },
    { name: 'Upcoming', value: upcomingCourses }
  ];

 
  const colors = ['#F87C47', '#145C9E']; 

  return (
    <Box className='coursescard-main' sx={{ padding: 2 }}>
      <Box className="courses-view">
      <h4>Courses</h4>
       <h3>VIEW ALL</h3>
      </Box>

      <Box className="piechart-numbers" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
   
        <PieChart width={150} height={150} className='pie-chart'>
          <Pie
            data={data}
            dataKey="value"
            innerRadius={30}
            outerRadius={50}
            fill="#8884d8"
            paddingAngle={5}
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={colors[index]} />
            ))}
            <Label
              value={totalCourses}
              position="center"
              fontSize="18px"
              fontWeight="bold"
              fill="#000"
            />
          </Pie>
          <Tooltip />
        </PieChart>
        

        <Box className="completed-numbers" sx={{ marginLeft: 2 }}>
          <Box className="completed-text" sx={{ marginBottom: 1 }}>
           <h3>Completed</h3>
            <Box className='completed-percentage'>
              <div className="orange-line"></div>
              <h4>{completedCourses}</h4>
              <p>{completedPercentage}%</p>
            </Box>
          </Box>

          <Box className="completed-text">
            <h3>Upcoming</h3>
            <Box className='completed-percentage'>
            <div className="blue-line"></div>
              <h4>{upcomingCourses}</h4>
              <p>{completedPercentage}%</p>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default CoursesCard;


