import { AuthClientId, Auth_CompStaffing_Tenant, Auth_Ms_redirectUri } from "../utils/api";

export const msalConfig = {
    auth: {
        clientId: AuthClientId,
        authority: `https://login.microsoftonline.com/${Auth_CompStaffing_Tenant}`,
        redirectUri: Auth_Ms_redirectUri,
        scopes: ['user.read']
    },
    cache: {
        cacheLocation: "sessionStorage",
    },
};