import { useState, useEffect } from 'react'
import '../styles/employees/employees.css'
import { useGlobalContext } from '../context/Context'
import { FaSearch, FaUserPlus } from "react-icons/fa"
import { IoClose } from "react-icons/io5"
import { BsThreeDotsVertical } from "react-icons/bs"
import Sidebar from './Sidebar'
import Nav from './Nav'
import AuthService from '../services/auth'
import Nodata from './Nodata'


const Employess = () => {
    const {
        isPopupOpen,
        closePopup,
        openPopup,
        API_URL,
        itemsPerPage,
        searchQuery,
        message,
        currentPage,
        handlePage,
        handleSearch,
        setMessage
    } = useGlobalContext()

    const [employees, setEmployees] = useState([])
    const [loading, setLoading] = useState(true)
    const [userPosition, setUserPosition] = useState('')
    const [positions, setPositions] = useState([])
    const [departments, setDepartments] = useState([])
    const [isAddingEmployee, setIsAddingEmployee] = useState(false)

    // state values of adding an employee
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [address, setAddress] = useState('')
    const [dateOfBirth, setDateOfBirth] = useState()
    const [joinedDate, setJoinedDate] = useState()
    const [salary, setSalary] = useState()
    const [skills, setSkills] = useState()
    const [activeStatus, setActiveStatus] = useState(false)
    const [position, setPosition] = useState()
    const [manager, setManager] = useState(false)
    const [department, setDepartment] = useState()

    // access token
    const accessToken = AuthService.getToken()

    const sortedEmployees = [...employees].sort((a, b) => {
        const nameA = a.user?.first_name.toLowerCase() + ' ' + a.user?.last_name.toLowerCase()
        const nameB = b.user?.first_name.toLowerCase() + ' ' + b.user?.last_name.toLowerCase()
        return nameA.localeCompare(nameB)
    })



    const filteredEmployees = sortedEmployees.length > 0 && sortedEmployees.filter((employee) =>
        employee.user ? employee.user.first_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            employee.user.last_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            employee.employee_id.toLowerCase().includes(searchQuery.toLowerCase())
            : ''
    )

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = Math.min(startIndex + itemsPerPage, filteredEmployees.length);
    const currentEmployees = filteredEmployees.length > 0 && filteredEmployees.slice(startIndex, endIndex);

    const handleAddEmployee = async (e) => {
        e.preventDefault()
        const accessToken = AuthService.getToken()
        setIsAddingEmployee(true)

        try {
            const res = await fetch(`${API_URL}/api/employee_api/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                },
                body: JSON.stringify({
                    'name': name,
                    'email': email,
                    'phone': phone,
                    'address': address,
                    'date_of_birth': dateOfBirth,
                    'join_date': joinedDate,
                    'salary': salary,
                    'skills': skills,
                    'active_status': activeStatus,
                    'position_name': position,
                    'manager': manager,
                    'department_name': department
                })
            })
            const data = await res.json()
            console.log(data)
            if (!res.ok || data.error) {
                setMessage(data.error)

                setTimeout(() => {
                    setMessage(data.message)
                    closePopup()
                }, 2000)
            }
            else {
                setName('')
                setEmail('')
                setPhone('')
                setAddress('')
                setDateOfBirth('')
                setJoinedDate('')
                setSalary('')
                setSkills('')
                setActiveStatus('')
                setPosition('')
                setManager('')
                setDepartment('')

                setTimeout(() => {
                    setMessage(data.message)
                    closePopup()
                }, 2000)
            }
        } catch (error) {
            console.error(error)
        }
    }


    useEffect(() => {
        const fetchData = async () => {
            try {
                const [employeesRes, departmentRes, positionRes, userRes] = await Promise.all([
                    fetch(`${API_URL}/api/list_employees/`, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${accessToken}`
                        }
                    }),

                    fetch(`${API_URL}/api/list_departments/`, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${accessToken}`
                        }
                    }),

                    fetch(`${API_URL}/api/list_positions/`, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${accessToken}`
                        }
                    }),

                    fetch(`${API_URL}/api/get_user_info/`, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${accessToken}`
                        }
                    }),

                ])

                const employeesData = await employeesRes.json()
                const departmentData = await departmentRes.json()
                const positionData = await positionRes.json()
                const userData = await userRes.json()

                setLoading(false)
                setEmployees(employeesData)
                setDepartments(departmentData)
                setPositions(positionData)
                setUserPosition(userData)


            } catch (error) {
                console.error(error)
                setLoading(false)
            }
        }
        fetchData()
    }, [])


    return (
        <section className='employees-section'>
            {isPopupOpen && (
                <div className="popup">
                    <div className="add-employee">
                        <div className="title">
                            <h3>Add Employee</h3>
                            <div className="icon">
                                <button onClick={closePopup}>
                                    <IoClose />
                                </button>
                            </div>
                        </div>
                        <form onSubmit={handleAddEmployee}>
                            {message && (
                                <p className='message'>{message}</p>
                            )}
                            <div className="input-fields">
                                <div className="field">
                                    <label>Employee Name</label>
                                    <input
                                        type="text"
                                        placeholder='Employee name'
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </div>
                                <div className="field">
                                    <label>Employee Email</label>
                                    <input type="email"
                                        placeholder='Employee email'
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </div>
                            </div>

                            <div className="input-fields">
                                <div className="field">
                                    <label>Phone number</label>
                                    <input type="text"
                                        placeholder='Phone number'
                                        value={phone}
                                        onChange={(e) => setPhone(e.target.value)}
                                    />
                                </div>
                                <div className="field">
                                    <label>Address</label>
                                    <input type="text"
                                        placeholder='Address'
                                        value={address}
                                        onChange={(e) => setAddress(e.target.value)}
                                    />
                                </div>
                            </div>

                            <div className="input-fields">
                                <div className="field">
                                    <label>Employee Position</label>
                                    <select
                                        name="position"
                                        value={position}
                                        onChange={(e) => setPosition(e.target.value)}
                                    >
                                        <option value="" disabled selected>Select a position</option>
                                        {positions.map((position) => (
                                            <option value={position.name}>{position.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="field">
                                    <label>Employee Department</label>
                                    <select
                                        name="department"
                                        value={department}
                                        onChange={(e) => setDepartment(e.target.value)}
                                    >
                                        <option value="" disabled selected>Select a department</option>
                                        {departments.map((department) => (
                                            <option value={department.name}>{department.name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            <div className="input-fields">
                                <div className="field">
                                    <label>Date of birth</label>
                                    <input type="date"
                                        value={dateOfBirth}
                                        onChange={(e) => setDateOfBirth(e.target.value)}
                                    />
                                </div>
                                <div className="field">
                                    <label>Joined date</label>
                                    <input type="date"
                                        value={joinedDate}
                                        onChange={(e) => setJoinedDate(e.target.value)}
                                    />
                                </div>
                            </div>

                            <div className="input-fields">
                                <div className="field">
                                    <label>Employee Salary</label>
                                    <input type="number"
                                        value={salary}
                                        placeholder='Salary'
                                        onChange={(e) => setSalary(e.target.value)}
                                    />
                                </div>
                                <div className="field">
                                    <label>Skills</label>
                                    <input type="text"
                                        placeholder='Skills'
                                        value={skills}
                                        onChange={(e) => setSkills(e.target.value)}
                                    />
                                </div>
                            </div>



                            <div className="radio-inputs">
                                <div className='radio-input-field'>
                                    <input type="radio"
                                        name='active status'
                                        value={activeStatus}
                                        onChange={(e) => setActiveStatus(e.target.value)}
                                    />
                                    <label>Active status</label>
                                </div>

                                <div className='radio-input-field'>
                                    <input type="radio"
                                        value={manager}
                                        onChange={(e) => setManager(e.target.value)}
                                    />
                                    <label>Manager</label>
                                </div>
                            </div>

                            <div className="btns">
                                <button className='cancel-btn' onClick={closePopup}>Cancel</button>
                                <button className='add-btn'>
                                    {isAddingEmployee ? ('processing...') : 'Add Employee'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
            <div className='row'>
                <div className="col col-1">
                    <Sidebar />
                </div>
                <div className="col col-2">
                    <section className="contents">
                        <div>
                            <Nav />
                        </div>
                        <div className='underline'></div>
                        <div className="contents-container">
                            <div className='title-contents'>
                                <div className='top'>
                                    <div className="col-title">
                                        <h4>Employees</h4>
                                        <p>{filteredEmployees.length} Employee(s)</p>
                                    </div>
                                    <div className="input-fields">
                                        <div className="input">
                                            <input
                                                type="text"
                                                value={searchQuery}
                                                onChange={handleSearch}
                                                placeholder='Employee Name....'
                                            />
                                            <div className="icon">
                                                <FaSearch />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-title'>
                                        {userPosition.position_name === 'Human resources' && (
                                            <button onClick={openPopup}><span><FaUserPlus /></span> Add employee</button>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className='table-contents'>
                                {loading ? (
                                    <div className="loading-container">
                                        <div className="loading">Loading</div>
                                        <div className="loading-dots">
                                            <span className="loading-dot">.</span>
                                            <span className="loading-dot">.</span>
                                            <span className="loading-dot">.</span>
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        <table>
                                            <thead>
                                                <tr>
                                                    {/* <th className='hidden-table-col'>ID</th> */}
                                                    <th>Employee Name</th>
                                                    <th>Employee Email</th>
                                                    <th>Date Joined</th>
                                                    <th>Position</th>
                                                    <th>Department</th>
                                                    {/* <th>Action</th> */}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {currentEmployees && currentEmployees.length > 0 ? (
                                                    currentEmployees.map((employee) => (
                                                        <tr key={employee.employee_id}>
                                                            {/* <td className='hidden-table-col'>{employee.employee_id.slice(0, 10)}</td> */}
                                                            <td>
                                                                <span>
                                                                    {employee.user?.first_name?.charAt(0)}{employee.user?.last_name?.charAt(0)}
                                                                </span>
                                                                {' '}
                                                                {employee.user?.first_name} {employee.user?.last_name}
                                                            </td>
                                                            <td>{employee.user.email}</td>
                                                            <td className='hidden-table-col'>{employee.join_date}</td>
                                                            <td className='hidden-table-col'>{employee.position_name.name}</td>
                                                            <td className='hidden-table-col'>{employee.department_name.name}</td>
                                                            {/* <td>
                                                                <button>
                                                                    <BsThreeDotsVertical />
                                                                </button>
                                                            </td> */}
                                                        </tr>
                                                    ))
                                                ) : <Nodata
                                                    style={`no-data`}
                                                    title='No employees data available...'
                                                />}

                                            </tbody>
                                        </table>
                                        {currentEmployees && currentEmployees.length > 0 && (
                                            <div className="pagination">
                                                <button
                                                    className='prev-btn'
                                                    onClick={() => handlePage(currentPage - 1)}
                                                    disabled={currentPage === 1}
                                                >
                                                    Previous
                                                </button>
                                                {Array.from({ length: Math.ceil(filteredEmployees.length / itemsPerPage) }, (_, i) => (
                                                    <button
                                                        key={i + 1}
                                                        className={`page ${currentPage === i + 1 ? 'active' : 'pagination-btn'}`}
                                                        onClick={() => handlePage(i + 1)}
                                                    >
                                                        {i + 1}
                                                    </button>
                                                ))}
                                                <button
                                                    className='next-btn'
                                                    onClick={() => handlePage(currentPage + 1)}
                                                    disabled={endIndex >= filteredEmployees.length}
                                                >
                                                    Next
                                                </button>
                                            </div>
                                        )}


                                    </>
                                )}

                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </section>
    );
};

export default Employess;
