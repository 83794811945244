import React from 'react'

const Nodata = ({ title, style }) => {
    return (
        <>
            <p className={style}>
                {title}
            </p>
        </>
    )
}

export default Nodata
