import { useState, useEffect } from "react"
import { Link, useLocation } from "react-router-dom"
import { useGlobalContext } from "../context/Context"
import { useNavigate } from "react-router-dom"
import { useParams } from "react-router-dom"
import AuthService from "../services/auth"
import { useAuthentication } from "../context/authContext"

// icons
import { FaSearch } from "react-icons/fa"
import { CiBellOn } from "react-icons/ci"
import { IoMdMenu } from "react-icons/io"
import { FaAngleDown } from "react-icons/fa"

const Nav = () => {
    const location = useLocation()
    const path = location.pathname.substring(1)
    const { openSidebar, API_URL } = useGlobalContext()
    const accessToken = AuthService.getToken()
    const navigate = useNavigate()
    const { slug } = useParams()

    // state values
    const [userData, setUserData] = useState(null)
    const [project, setProject] = useState(null)
    const [loading, setLoading] = useState(true)
    const [isUserDetailsOpen, setIsUserDetailsOpen] = useState(false)

    const openUSerDetails = () => {
        setIsUserDetailsOpen(true)
    }

    const closeUserDetails = () => {
        setIsUserDetailsOpen(false)
    }

    // logout function
    const handleLogout = () => {
        try {
            AuthService.logout()
            navigate('/login')
        } catch (error) {
            console.error(error)
        }
    }
    const firstNameInitial = userData?.first_name?.charAt(0) || '';
    const lastNameInitial = userData?.last_name?.charAt(0) || '';

    useEffect(() => {
        const getUser = async () => {
            try {
                const res = await fetch(`${API_URL}/api/get_user_info/`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`
                    }
                })
                const data = await res.json()
                setUserData(data)
            } catch (error) {
                console.error(error)
            } finally {
                setLoading(false)
            }
        }

        getUser()
    }, [])

    useEffect(() => {
        const getProject = async () => {
            try {
                const res = await fetch(`${API_URL}/api/single_project/${slug}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`
                    }
                })
                const data = await res.json()
                setProject(data.project)
            } catch (error) {
                console.log(error)
            }
        }
        getProject()
    }, [])

    return (
        <nav>
            {isUserDetailsOpen && (
                <div className="user-details-popup" onClick={closeUserDetails}>
                    <div className="user-profile-details">
                        <div className="user-row">
                            <div className="col col-1">
                                <p>{firstNameInitial}{lastNameInitial}</p>
                            </div>
                            <div className="col">
                                <p>{userData.first_name} {userData.last_name}</p>
                                <p className="email">{userData.email}</p>
                            </div>
                        </div>
                        <div className="popup-links-container">
                            <div className="popup-link">
                                <img src="images/profile-icon.svg" alt="" />
                                <button>
                                    <Link to='/employees'>My Profile</Link>
                                </button>
                            </div>
                            <div className="popup-link">
                                <img src="images/settings.svg" alt="" />
                                <button>
                                    <Link to='/employees'>Settings</Link>
                                </button>
                            </div>
                            <div className="popup-link">
                                <img src="images/logout.svg" alt="" />
                                <button className="signout" onClick={handleLogout}>
                                    <Link to='/employees'>
                                        Sign Out
                                    </Link>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <button className="menu-btn" onClick={openSidebar}>
                <IoMdMenu />
            </button>
            {path === '' ? (
                <h3>Leave records</h3>
            ) : path.startsWith('project-details') ? (
                <h3>{project?.name}</h3>
            ) : (
                <h3>{path} records</h3>
            )}

            <form>
                <div className="input">
                    <div className="icon">
                        <FaSearch />
                    </div>
                    <input type="text" placeholder="Search something" />
                </div>
            </form>
            <div className="logo">
                <img src="/images/logo-init.svg" alt="" />
            </div>
            <div className="profile-section">
                <div className="notification">
                    <CiBellOn />
                </div>
                <div className="profile">
                    {!loading ? (
                        <p>{firstNameInitial}{lastNameInitial}</p>
                    ) : (
                        <p>refreshing...</p>
                    )}
                </div>
                <div className="user-details">
                    <button onClick={openUSerDetails}>
                        <FaAngleDown />
                    </button>
                </div>
            </div>
        </nav>
    )
}

export default Nav
