import React, { useState, useEffect } from 'react'
import DashboardContainerV2 from '../../../components/dashbaord/dashboardContainerV2.jx'
import '../../../styles/employees/employees.css'
import { FaSearch, FaUserPlus } from "react-icons/fa"
import { useGlobalContext } from '../../../context/Context'
import AuthService from '../../../services/auth'
import Nodata from '../../../components/Nodata'
import api from '../../../utils/api'
import axios from 'axios'
import EvaluatePerformance from '../../../components/forms/EvaluatePerformance.jsx'
import { IoClose } from "react-icons/io5";


const EmployeesPagePContent = () => {
    const {
        isPopupOpen,
        closePopup,
        openPopup,
        API_URL,
        itemsPerPage,
        searchQuery,
        message,
        currentPage,
        handlePage,
        handleSearch,
        setMessage
    } = useGlobalContext()

    const [employees, setEmployees] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [positions, setPositions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [userPosition, setUserPosition] = useState('');
    const [isAddingEmployee, setIsAddingEmployee] = useState(false);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [address, setAddress] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState();
    const [joinedDate, setJoinedDate] = useState();
    const [salary, setSalary] = useState();
    const [skills, setSkills] = useState();
    const [activeStatus, setActiveStatus] = useState(false);
    const [position, setPosition] = useState();
    const [manager, setManager] = useState(false);
    const [department, setDepartment] = useState();

    const sortedEmployees = [...employees].sort((a, b) => {
        const nameA = a.user?.first_name.toLowerCase() + ' ' + a.user?.last_name.toLowerCase()
        const nameB = b.user?.first_name.toLowerCase() + ' ' + b.user?.last_name.toLowerCase()
        return nameA.localeCompare(nameB)
    })
    const accessToken = AuthService.getToken()
    const filteredEmployees = sortedEmployees.length > 0 && sortedEmployees.filter((employee) =>
        employee.user ? employee.user.first_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            employee.user.last_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            employee.employee_id.toLowerCase().includes(searchQuery.toLowerCase())
            : ''
    )

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = Math.min(startIndex + itemsPerPage, filteredEmployees.length);
    const currentEmployees = filteredEmployees.length > 0 && filteredEmployees.slice(startIndex, endIndex);

    const handleAddEmployee = async (e) => {
        e.preventDefault()
        const accessToken = AuthService.getToken()
        setIsAddingEmployee(true)

        try {
            const res = await fetch(`${API_URL}/api/employee_api/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                },
                body: JSON.stringify({
                    'name': name,
                    'email': email,
                    'phone': phone,
                    'address': address,
                    'date_of_birth': dateOfBirth,
                    'join_date': joinedDate,
                    'salary': salary,
                    'skills': skills,
                    'active_status': activeStatus,
                    'position_name': position,
                    'manager': manager,
                    'department_name': department
                })
            })
            const data = await res.json()
            console.log(data)
            if (!res.ok || data.error) {
                setMessage(data.error)

                setTimeout(() => {
                    setMessage(data.message)
                    closePopup()
                }, 2000)
            }
            else {
                setName('')
                setEmail('')
                setPhone('')
                setAddress('')
                setDateOfBirth('')
                setJoinedDate('')
                setSalary('')
                setSkills('')
                setActiveStatus('')
                setPosition('')
                setManager('')
                setDepartment('')

                setTimeout(() => {
                    setMessage(data.message)
                    closePopup()
                }, 2000)
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsAddingEmployee(false);
        }
    }

    const [addPerformance, setAddPerformance] = useState(false);
    const handlePerformance = async (e) => {
        e.preventDefault()
        setAddPerformance(true)
    };


    useEffect(() => {
        const fetchEmployees = async () => {
            try {
                const response = await api.get(`${API_URL}/api/list_employees`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`
                    }
                });
                setEmployees(response.data);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        };

        fetchEmployees();
    }, [API_URL, accessToken]);

    useEffect(() => {
        const fetchDepartments = async () => {
            try {
                const response = await api.get(`${API_URL}/api/list_departments`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`
                    }
                });
                setDepartments(response.data);
            } catch (error) {
                console.error(error);
            }
        };

        fetchDepartments();
    }, [API_URL, accessToken]);

    useEffect(() => {
        const fetchPositions = async () => {
            try {
                const response = await api.get(`${API_URL}/api/list_positions`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`
                    }
                });
                setPositions(response.data);
            } catch (error) {
                console.error(error);
            }
        };

        fetchPositions();
    }, [API_URL, accessToken]);

    useEffect(() => {
        const fetchUserInfo = async () => {
            try {
                const response = await api.get(`${API_URL}/api/get_user_info`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`
                    }
                });
                setUserPosition(response.data);
            } catch (error) {
                console.error(error);
            }
        };

        fetchUserInfo();
    }, [API_URL, accessToken]);

    if (loading) {
        return <p>Loading...</p>;
    }






    return loading
        ? <p>Loading...</p> : (
            <section className='employees-section'>
                {isPopupOpen && (
                    <EvaluatePerformance
                        employees={employees}
                        isOpen={isPopupOpen} 
                        onClose={closePopup}
                        handlePerformance={handlePerformance}
                    />
                )}
                <div className="contents-container">
                    <div className='title-contents'>
                        <div className='top'>
                            <div className="col-title">
                                <h4>Employees</h4>
                                <p>{filteredEmployees.length} Employee(s)</p>
                            </div>
                            <div className="input-fields">
                                <div className="input">
                                    <input
                                        type="text"
                                        value={searchQuery}
                                        onChange={handleSearch}
                                        placeholder='Employee Name....'
                                    />
                                    <FaSearch className="icon" />
                                </div>
                            </div>
                            <div className='col-title'>
                                {userPosition.position_name === 'Human resources' && (
                                    <button onClick={openPopup}><span><FaUserPlus /></span> Add employee</button>
                                )}
                            </div>
                            <div className="col-title">
                                <button onClick={openPopup}>
                                    <span>
                                        <FaUserPlus />
                                    </span>{" "}
                                    Evaluate
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className='table-contents'>
                        {loading ? (
                            <div className="loading-container">
                                <div className="loading">Loading</div>
                                <div className="loading-dots">
                                    <span className="loading-dot">.</span>
                                    <span className="loading-dot">.</span>
                                    <span className="loading-dot">.</span>
                                </div>
                            </div>
                        ) : (
                            <>
                                <table>
                                    <thead>
                                        <tr>
                                            {/* <th className='hidden-table-col'>ID</th> */}
                                            <th>Employee Name</th>
                                            <th>Employee Email</th>
                                            <th>Date Joined</th>
                                            <th>Position</th>
                                            <th>Department</th>
                                            {/* <th>Action</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentEmployees && currentEmployees.length > 0 ? (
                                            currentEmployees.map((employee) => (
                                                <tr key={employee.employee_id}>
                                                    {/* <td className='hidden-table-col'>{employee.employee_id.slice(0, 10)}</td> */}
                                                    <td>
                                                        <span>
                                                            {employee.user?.first_name?.charAt(0)}{employee.user?.last_name?.charAt(0)}
                                                        </span>
                                                        {' '}
                                                        {employee.user?.first_name} {employee.user?.last_name}
                                                    </td>
                                                    <td>{employee.user.email}</td>
                                                    <td className='hidden-table-col'>{employee.join_date}</td>
                                                    <td className='hidden-table-col'>{employee.position_name.name}</td>
                                                    <td className='hidden-table-col'>{employee.department_name.name}</td>
                                                    {/* <td>
                                                        <button>
                                                            <BsThreeDotsVertical />
                                                        </button>
                                                        </td> */}
                                                </tr>
                                            ))
                                        ) : <Nodata
                                            style={`no-data`}
                                            title='No employees data available...'
                                        />}
                                    </tbody>
                                </table>
                                {currentEmployees && currentEmployees.length > 0 && (
                                    <div className="pagination">
                                        <button
                                            className='prev-btn'
                                            onClick={() => handlePage(currentPage - 1)}
                                            disabled={currentPage === 1}
                                        >
                                            Previous
                                        </button>
                                        {Array.from({ length: Math.ceil(filteredEmployees.length / itemsPerPage) }, (_, i) => (
                                            <button
                                                key={i + 1}
                                                className={`page ${currentPage === i + 1 ? 'active' : 'pagination-btn'}`}
                                                onClick={() => handlePage(i + 1)}
                                            >
                                                {i + 1}
                                            </button>
                                        ))}
                                        <button
                                            className='next-btn'
                                            onClick={() => handlePage(currentPage + 1)}
                                            disabled={endIndex >= filteredEmployees.length}
                                        >
                                            Next
                                        </button>
                                    </div>
                                )}
                            </>
                        )}
                    </div>



                </div>


            </section>
        )

}
const EmployeesPage = () => {
    return (
        <DashboardContainerV2 content={<EmployeesPagePContent />} />
    )
}

export default EmployeesPage