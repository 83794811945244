import React from 'react'
import DashboardContainerV2 from '../../../components/dashbaord/dashboardContainerV2.jx'

const BlogsCategoriesContent = () => {
    return (
        <div>
            <h1>This is the blogs categories page</h1>
        </div>
    )
}
const BlogsCategories = () => {
    return (
        <DashboardContainerV2 content={<BlogsCategoriesContent />} />
    )
}

export default BlogsCategories