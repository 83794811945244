
import React, { useState, useEffect } from "react";
import LoadingIcon from "../../loadingIcon";
import { Cancel01Icon, Cancel02Icon } from "hugeicons-react";
import { categoriesArray } from "../../../dummyData/categories";
import { tagsArray } from "../../../dummyData/tags";
import axios from "axios";
import { CSR_API_ENDPOINT } from "../../../utils/api";
const BlogTags = ({ setBlogTags, blogTags }) => {
    const [tags, setTags] = useState([]);
    const [gettingTags, setGettingTags] = useState(true);
    const [selectedItems, setSelectedItems] = useState(blogTags);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [suggestions, setSuggestions] = useState([]);
    const [searchString, setSearchString] = useState('');

    const fetchingTags = async () => {
        setGettingTags(true);
        try {
            // Simulating API call
            const response = await axios.get(`${CSR_API_ENDPOINT}/blog/tags/`)
            if (response.status === 200) {
                setTags(response.data);
                setGettingTags(false);
            }
        } catch (error) {
            console.error("Error fetching categories:", error);
            setGettingTags(false);
        }
    };

    const handleSearches = (value) => {
        setSearchString(value);
        setShowSuggestions(true);
        const filteredCategories = tags.filter(tag =>
            tag.name.toLowerCase().includes(value.toLowerCase())
        );
        setSuggestions(filteredCategories);
    };

    const handleSelectedItem = (tag) => {
        if (!selectedItems.includes(tag.name)) {
            setSelectedItems(prev => [...prev, tag]);
        }
        setBlogTags(selectedItems)
        setShowSuggestions(false);
        setSearchString('');
    };

    const handleRemoveSelectedItem = (itemName) => {
        setSelectedItems(prev => prev.filter(cat => cat !== itemName));
        setBlogTags(selectedItems)
    };

    useEffect(() => {
        fetchingTags();
    }, []);
    useEffect(() => {
        setBlogTags(selectedItems);
    }, [selectedItems, setBlogTags]);

    return (
        <div className={`${showSuggestions ? 'popup-selection' : 'input'}`}>
            <div className="card-action">
                <label htmlFor="category-search">Tags</label>
                <Cancel01Icon onClick={() => setShowSuggestions(false)} size={20} className="close-button" />
            </div>
            <input
                id="category-search"
                type="search"
                value={searchString}
                onChange={(e) => handleSearches(e.target.value)}
                placeholder='Search tags'
            />
            <div className="categories-list">
                {gettingTags ? (
                    <LoadingIcon size={18} />
                ) : !showSuggestions && selectedItems.length > 0 ? (
                    selectedItems.map((tag, index) => (
                        <div key={index} className="category-item">
                            <p>{tag.name}</p>
                            <Cancel01Icon
                                size={12}
                                className='remove-item'
                                onClick={() => handleRemoveSelectedItem(tag)}
                            />
                        </div>
                    ))
                ) : (
                    <div className="select-item">No tag selected</div>
                )}
                {showSuggestions && suggestions.length > 0 && (
                    <div className="suggestions">
                        {suggestions.map((suggestion, index) => (
                            <div
                                className='suggestion'
                                key={index}
                                onClick={() => handleSelectedItem(suggestion)}
                            >
                                <p>{suggestion.name}</p>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default BlogTags;
