import React from 'react'
import "../../../styles/projectTracking/projectracking.css"

const ProjectTrackingLoader = () => {
  return (
    <div className='projecttracking'>
      <div className="first-part">
        <div className='gray loader'></div>
        <div className='gray loader'></div>
        <div className='gray loader'></div>
        <div className='gray loader'></div>
      </div>

      <div className="second-part">
        <div className='second-half'>
          <div className="long loader"></div>
          <div className="short loader"></div>
        </div>
        <div className='parttwo-half'>
          <div className="longest loader"></div>
        </div>

        <div className='partthree-half'>
          <div className="whole loader"></div>
          <div className="whole loader"></div>
        </div>

      </div>

      <div className="thrid-part">
      <div className="columns">
        <div className="thrid-half loader">
        </div>
        <div className="thrid-half loader">
        </div>
        <div className="thrid-half loader">
        </div>
        <div className="thrid-half loader">
        </div>
        <div className="thrid-half loader">
        </div>
        <div className="thrid-half loader">
        </div>
        <div className="thrid-half loader">
        </div>
        <div className="thrid-half loader">
        </div>
        </div>

        <div className="columns">
        <div className="thrid-half loader">
        </div>
        <div className="thrid-half loader">
        </div>
        <div className="thrid-half loader">
        </div>
        <div className="thrid-half loader">
        </div>
        <div className="thrid-half loader">
        </div>
        <div className="thrid-half loader">
        </div>
        <div className="thrid-half loader">
        </div>
        <div className="thrid-half loader">
        </div>
        </div>

        <div className="columns">
        <div className="thrid-half loader">
        </div>
        <div className="thrid-half loader">
        </div>
        <div className="thrid-half loader">
        </div>
        <div className="thrid-half loader">
        </div>
        <div className="thrid-half loader">
        </div>
        <div className="thrid-half loader">
        </div>
        <div className="thrid-half loader">
        </div>
        <div className="thrid-half loader">
        </div>
        </div>

        <div className="columns">
        <div className="thrid-half loader">
        </div>
        <div className="thrid-half loader">
        </div>
        <div className="thrid-half loader">
        </div>
        <div className="thrid-half loader">
        </div>
        <div className="thrid-half loader">
        </div>
        <div className="thrid-half loader">
        </div>
        <div className="thrid-half loader">
        </div>
        <div className="thrid-half loader">
        </div>
        </div>
      
      </div>
    </div>
  )
}

export default ProjectTrackingLoader
