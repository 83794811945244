import React, {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";

const OrderItem = ({ order, onOrderClick }) => {
    return (
      <>
        <div
          className="item"
          onClick={() => onOrderClick(order)}
          style={{ cursor: "pointer" }}
        >
          <div className="order-description">
            <h3>Order #{order.id}</h3>
            <p>{order.order_product.name}</p>
          </div>
  
          <p className="price">{order.order_price}</p>
        </div>
      </>
    );
  };
  
  export const AllOrders = ({ setSelectedOrder, setAllOrders }) => {
    const [selectedCategory, setSelectedCategory] = useState("New");
    const [orderData, setOrderData] = useState({ orders: [] });
    const categories = ["New", "Confirmed", "Delivered"];
  
    const Orders = [
        { id: 1, status: "pending", order_product: { name: "Espresso" }, order_price: "2500" },
        { id: 2, status: "confirmed", order_product: { name: "Latte" }, order_price: "3000" },
        { id: 3, status: "delivered", order_product: { name: "Cappuccino" }, order_price: "3500" },
        { id: 4, status: "pending", order_product: { name: "Mocha" }, order_price: "4000" },
        { id: 5, status: "confirmed", order_product: { name: "Americano" }, order_price: "2000" },
      ];

    const getFilteredOrders = () => {
        return Orders.filter((order) => {
            if (selectedCategory === "New") return order.status === "pending";
            if (selectedCategory === "Confirmed") return order.status === "confirmed";
            if (selectedCategory === "Delivered") return order.status === "delivered";
            return true;
          });
      }
  
      
  
    const filteredOrders = getFilteredOrders();
  
    return (
      <>
        <div className="all-orders">
        <div className="categories">
          {categories.map((category, index) => (
            <span
              key={index}
              className={selectedCategory === category ? "active-category" : ""}
              onClick={() => setSelectedCategory(category)}
            >
              {category}
            </span>
          ))}
        </div>
  
        <div className="orders">
          {filteredOrders.map((order) => (
            <OrderItem
              key={order.id}
              order={order}
              onOrderClick={setSelectedOrder}
            />
          ))}
        </div>

        </div>
      </>
    );
  };
  
  const OrderInfo = ({ imageUrl, quantity, price, label, name }) => {
    const handleImageError = (e) => {
      e.target.src = "/images/image-placeholder.svg";
    };
    return (
      <>
        <div className="order-item">
          <div className="order-name">
            <img src={imageUrl} alt={label} onError={handleImageError} />
            <p>{name}</p>
          </div>
          <div className="order-quantity">
            <p>Quantity</p>
            <span>{quantity}</span>
          </div>
          <div className="price">
            <p>{price}</p>
            <span>Rwf</span>
          </div>
        </div>
      </>
    );
  };
  
  export const OrderDetails = ({ order }) => {
    const clientName = order.client?.first_name || 'Anonymous';
    const departmentName = order.location?.name || "N/A";
  
      
    return (
      <div className="order-details-admin">
        <h2>Order details</h2>
  
        <div className="info">
          <div className="name">
            <p>Name</p>
            <span>{clientName}</span>
          </div>
  
          <div className="department">
            <p>Department</p>
            <span>{departmentName}</span>
          </div>
        </div>
  
        <div className="order-info">
          <OrderInfo
            name={order.order_product.name}
            imageUrl={order.order_product.image}
            quantity={order.quantity}
            price={order.order_price}
            label={order.order_product.description}
          />
        </div>
  
        <div className="buttons">
          <div className="cancel">
            <img src="/images/close-circle.svg" alt="close" />
            <span>Cancel</span>
          </div>
          <div className="send">
            <span>Accept Order</span>
            <img src="/images/arrow-right.svg" alt="arrow" />
          </div>
        </div>
      </div>
    );
  };

const Admin = () => {
    const navigate = useNavigate();
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [allOrders, setAllOrders] = useState([]);

    return(
        <>
        <div className="admin-container">
    <div className="admin-first-row">
              <AllOrders
                setSelectedOrder={setSelectedOrder}
              />
            </div>
            <div className="second-row">
            {(selectedOrder ||
                (allOrders.length > 0 && allOrders[allOrders.length - 1])) && (
                <OrderDetails
                  order={selectedOrder || allOrders[allOrders.length - 1]}
                />
              )}
      </div>
            <div className="button">
              <a
                href=""
                onClick={() => {
                  navigate("/adminorder-details");
                }}
              >
                View Order Details
              </a>
            </div>
    </div>
        </>
    )
    
}

export default Admin;