
import React, { useState, useEffect } from "react";
import LoadingIcon from "../../loadingIcon";
import { Cancel01Icon } from "hugeicons-react";
import axios from "axios";
import { CSR_API_ENDPOINT } from "../../../utils/api";

export const getNameInitials = (fullName) => {
    const names = fullName.split(' ');
    if (names.length === 2) {
        return `${names[0].charAt(0)} ${names[1].charAt(0)}`;
    } else if (names.length === 3) {
        return `${names[0].charAt(0)} ${names[1].charAt(0)}.`;
    }
    return '';
}
const BlogAuthors = ({ setBlogAuthors, blogAuthors }) => {
    const [authors, setAuthors] = useState([]);
    const [gettingAuthors, setGettingAuthors] = useState(true);
    const [selectedItems, setSelectedItems] = useState(blogAuthors || []);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [suggestions, setSuggestions] = useState([]);
    const [searchString, setSearchString] = useState('');

    const fetchingAuthors = async () => {
        setGettingAuthors(true);
        try {
            // Simulating API call
            const response = await axios.get(`${CSR_API_ENDPOINT}/blog/authors/`)
            if (response.status === 200) {
                setAuthors(response.data);
                setGettingAuthors(false);
            }
        } catch (error) {
            console.error("Error fetching categories:", error);
            setGettingAuthors(false);
        }
    };

    const handleSearches = (value) => {
        setSearchString(value);
        setShowSuggestions(true);
        const filteredCategories = authors.filter(author =>
            author.first_name.toLowerCase().includes(value.toLowerCase()) ||
            author.last_name.toLowerCase().includes(value.toLowerCase())
        );
        setSuggestions(filteredCategories);
    };

    const handleSelectedItem = (author) => {
        if (!selectedItems.includes(author.name)) {
            setSelectedItems(prev => [...prev, author]);
        }
        setBlogAuthors(selectedItems)
        setShowSuggestions(false);
        setSearchString('');
    };

    const handleRemoveSelectedItem = (itemName) => {
        setSelectedItems(prev => prev.filter(cat => cat !== itemName));
        setBlogAuthors(selectedItems)
    };

    useEffect(() => {
        fetchingAuthors();
    }, []);
    useEffect(() => {
        setBlogAuthors(selectedItems);
    }, [selectedItems, setBlogAuthors]);

    // get a name string, spit and get the first name and last name, then return their initials
    return (
        <div className={`${showSuggestions ? 'popup-selection' : 'input'}`}>
            <div className="action">
                <label htmlFor="category-search">Authors</label>
                <div className="view-all"></div>
            </div>
            <input
                id="category-search"
                type="search"
                value={searchString}
                onChange={(e) => handleSearches(e.target.value)}
                placeholder='Search authors'
            />
            <div className="author-list">
                {gettingAuthors ? (
                    <LoadingIcon size={18} />
                ) : !showSuggestions && selectedItems.length > 0 ? (
                    selectedItems.map((author, index) => (
                        <div key={index} className="author">
                            <p>{getNameInitials(`${author.first_name} ${author.last_name}`)}</p>
                            <div className='remove-item'>
                                <Cancel01Icon
                                    size={14}

                                    onClick={() => handleRemoveSelectedItem(author)}
                                />
                            </div>

                        </div>
                    ))
                ) : (
                    <div className="select-item">No tag selected</div>
                )}
                {showSuggestions && suggestions.length > 0 && (
                    <div className="suggestions">
                        {suggestions.map((suggestion, index) => (
                            <div
                                className='suggestion'
                                key={index}
                                onClick={() => handleSelectedItem(suggestion)}
                            >
                                <p>{suggestion.first_name} {suggestion.last_name}</p>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default BlogAuthors;
