import { useState, useEffect } from 'react'
import { useGlobalContext } from '../context/Context'
import AuthService from '../services/auth'

const LeaveNumbers = () => {
    const {
        API_URL,
        sickLeaves,
        annualLeaves,
        annualLeavesPercentage,
        compassionateLeaves,
        compassionateLeavesPercentage,
        sickLeavesPercentage,
        maternityLeaves,
        maternityLeavesPercentage,
        marriage,
        marriagePercentage,
        paternityLeaves,
        paternityLeavesPercentage,
        setSickLeaves,
        setSickLeavesPercentage,
        setCompassionateLeaves,
        setCompassionateLeavesPercentage,
        setAnnualLeaves,
        setAnnualLeavesPercentage,
        setMaternityLeaves,
        setMaternityLeavesPercentage,
        setMarriage,
        setMarriagePercentage,
        setPaternityLeaves,
        setPaternityLeavesPercentage
    } = useGlobalContext()

    const [leaves, setLeaves] = useState([])
    const [approvedLeaves, setApprovedLeaves] = useState(0)
    const [user, setUser] = useState(null)

    const accessToken = AuthService.getToken()

    // checking the position of authenticated users
    const positions = ['Director', 'Operations', 'Human resources']
    const displayLeaveNumbers = user && positions.includes(user.position_name)

    useEffect(() => {
        const getUser = async () => {
            try {
                const res = await fetch(`${API_URL}/api/get_user_info`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`
                    }
                })
                const data = await res.json()
                setUser(data)
                return data
            } catch (error) {
                console.error(error)
            }
        }

        getUser()
    }, [API_URL, accessToken])

    useEffect(() => {
        const getLeaveData = async () => {

            try {
                const res = await fetch(`${API_URL}/api/employee/leaves/`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`
                    },
                })
                const data = await res.json()
                setLeaves(data)

                // filtering leaves based on authenticated user
                let filteredLeaves = data.filter(leave => leave.status_name === 'approved')

                // total leaves approved
                const totalLeavesApproved = filteredLeaves.length

                // sick leaves 
                const numOfSickLeaves = filteredLeaves.filter(leave => leave.leave_type.name === 'Sick leave').length
                setSickLeaves(numOfSickLeaves)
                setSickLeavesPercentage((numOfSickLeaves * 100) / totalLeavesApproved)

                // compassionate leaves
                const numOfCompassionateLeaves = filteredLeaves.filter(leave => leave.leave_type.name === 'Compassionate leave').length
                setCompassionateLeaves(numOfCompassionateLeaves)
                setCompassionateLeavesPercentage((numOfCompassionateLeaves * 100) / totalLeavesApproved)

                // annual leaves
                const numOfAnnualLeaves = filteredLeaves.filter(leave => leave.leave_type.name === 'Annual leave').length
                setAnnualLeaves(numOfAnnualLeaves)
                setAnnualLeavesPercentage((numOfAnnualLeaves * 100) / totalLeavesApproved)

                // maternity leaves
                const numOfMaternityLeaves = filteredLeaves.filter(leave => leave.leave_type.name === '	Maternity leave').length
                setMaternityLeaves(numOfMaternityLeaves)
                setMaternityLeavesPercentage((numOfMaternityLeaves * 100) / totalLeavesApproved)

                // marriage leaves
                const numOfMarriageLeaves = filteredLeaves.filter(leave => leave.leave_type.name === 'Marriage').length
                setMarriage(numOfMarriageLeaves)
                setMarriagePercentage((numOfMarriageLeaves * 100) / totalLeavesApproved)

                // paternity leaves
                const numOfPaternityLeaves = filteredLeaves.filter(leave => leave.leave_type.name === 'Paternity leave').length
                setPaternityLeaves(numOfPaternityLeaves)
                setPaternityLeavesPercentage((numOfPaternityLeaves * 100) / totalLeavesApproved)


                if (user) {
                    if (user.position_name === 'Director') {
                        filteredLeaves = filteredLeaves.filter(leave => leave.department_name === user.department_name)
                        setApprovedLeaves(totalLeavesApproved)
                    } else if (user.position_name === 'Chief Operations Officer' || user.position_name === 'Human resources') {
                        setApprovedLeaves(totalLeavesApproved)

                    } else {
                        setApprovedLeaves(0)
                    }
                }
                setApprovedLeaves(totalLeavesApproved)

            } catch (error) {
                console.log(error)
            }
        }

        getLeaveData()

    }, [])



    return (
        <>
            {displayLeaveNumbers && (
                <div className="numbers">
                    <div className="numbers-details">
                        <div className="col-details">
                            <h4>{approvedLeaves}</h4>
                            <p>Total leave(s)</p>
                        </div>
                        <div className="col-details">
                            <div className="col-details-numbers">
                                <h4>{annualLeaves ? annualLeaves : '0'}</h4>
                                <div className='percentage'>
                                    <p>
                                        {annualLeavesPercentage ? annualLeavesPercentage.toFixed(1) : '0'}%
                                    </p>
                                </div>
                            </div>
                            <p>Annual leave(s)</p>
                        </div>
                        <div className="col-details">
                            <div className="col-details-numbers">
                                <h4>{sickLeaves ? sickLeaves : '0'}</h4>
                                <div className='percentage'>
                                    <p>
                                        {sickLeavesPercentage ? sickLeavesPercentage.toFixed(1) : '0'}%
                                    </p>
                                </div>
                            </div>
                            <p>Sick leave(s)</p>
                        </div>
                        <div className="col-details">
                            <div className="col-details-numbers">
                                <h4>{maternityLeaves ? maternityLeaves : '0'}</h4>
                                <div className='percentage'>
                                    <p>
                                        {maternityLeavesPercentage ? maternityLeavesPercentage.toFixed(1) : '0'}%
                                    </p>
                                </div>
                            </div>
                            <p>Maternity leave(s)</p>
                        </div>
                        <div className="col-details">
                            <div className="col-details-numbers">
                                <h4>{marriage ? marriage : '0'}</h4>
                                <div className='percentage'>
                                    <p>
                                        {marriagePercentage ? marriagePercentage.toFixed(1) : '0'}%
                                    </p>
                                </div>
                            </div>
                            <p>Marriage</p>
                        </div>
                        <div className="col-details">
                            <div className="col-details-numbers">
                                <h4>{paternityLeaves ? paternityLeaves : '0'}</h4>
                                <div className='percentage'>
                                    <p>
                                        {paternityLeavesPercentage ? paternityLeavesPercentage.toFixed(1) : '0'}%
                                    </p>
                                </div>
                            </div>
                            <p>Paternity leave(s)</p>
                        </div>
                        <div className="col-details">
                            <div className="col-details-numbers">
                                <h4>{compassionateLeaves ? compassionateLeaves : '0'}</h4>
                                <div className='percentage'>
                                    <p>
                                        {compassionateLeavesPercentage ? compassionateLeavesPercentage.toFixed(1) : '0'}%
                                    </p>
                                </div>
                            </div>
                            <p>Compassionate leave(s)</p>
                        </div>
                    </div>
                </div>
            )}
        </>

    )
}

export default LeaveNumbers
