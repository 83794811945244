import React, { useEffect, useState } from 'react';
import DashboardContainerV2 from '../../components/dashbaord/dashboardContainerV2.jx';
import CanteenOrder from '../../components/canteen/canteenOrder';
import '../../styles/canteen/home.css'


const CanteenOrderPageContent = () => {
    return(
        <>
        <CanteenOrder/>
        </>
    )
}

const CanteenOrderPage = () => {
    return(
        <>
            <DashboardContainerV2 content={<CanteenOrderPageContent/>}/>
        
        </>
    )
}

export default CanteenOrderPage;