import ResetPassword from "./components/ResetPassword";
import RequestPasswordReset from "./components/RequestPasswordReset";
import Login from "./components/Login";
import Employess from "./components/Employess";
import LeaveRequests from "./components/LeaveRequests";
import LeaveRecords from "./components/LeaveRecords";
import CanteenPage from "./pages/canteen/canteenPage";
// import Overview from "./components/Overview";
import CalendarDetails from "./components/CalendarDetails";
import NotFound from "./components/NotFound";
import ProtectedRoute from "./components/ProtectedRoute";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ProjectsPage from "./components/Projects";
import ProjectDetails from "./components/ProjectDetails";
import TrainingCenter from "./pages/dashboard/trainingCenter/trainingCenter";
import TrainingCenterApplications from "./pages/dashboard/trainingCenter/trainingCenterApplications";
import DashboardContainerV2 from "./components/dashbaord/dashboardContainerV2.jx";
import OverViewPage from "./pages/dashboard/overviewPage";
import EmployeesPage from "./pages/dashboard/employees/EmployeesPage";
import AllProjectsPage from "./pages/dashboard/projects/ProjectPage";
import LeavesPage from "./pages/dashboard/leavesPage";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./context/msConfig";
import { PublicClientApplication } from "@azure/msal-browser";
// import{LeavesOverview} from "./components/loadders/leaves/overview"

import CanteenAdminPanel from "./pages/canteen/canteenAdminPanel";
import JobMatchOverviewPage from "./pages/dashboard/jobMatch/overview";
import CanteenOrderPage from "./pages/canteen/canteenOrderPage";
import JobsPage from "./pages/dashboard/jobMatch/jobsPage";
import TrainingCenterApplicationsPage from "./pages/dashboard/trainingCenter/trainingCenterApplications";
import TrainingCenterPage from "./pages/dashboard/trainingCenter/trainingCenter";
import CohortDetailsPage from "./pages/dashboard/trainingCenter/cohorts/CohortDetails";
import CohortPage from "./pages/dashboard/trainingCenter/cohorts/cohortPage";
import TransactionPage from "./pages/dashboard/trainingCenter/transactionsPage";
import BlogAnalyticsPage from "./pages/dashboard/blog/BlogAnalyticsPage";
import BlogsCategories from "./pages/dashboard/blog/BlogsCategories";
import RecentBlogsPage from "./pages/dashboard/blog/RecentBlogsPage";
import NewBlogPage from "./pages/dashboard/blog/NewBlogPage";
import BlogDetailsPage from "./pages/dashboard/blog/BlogDetailsPage";
import UpdateBlogPage from "./pages/dashboard/blog/UpdateBlogPage";
// import CohortFullPage from "./pages/dashboard/trainingCenter/cohorts/cohortPage";


function App() {
  const msalInstance = new PublicClientApplication(msalConfig);
  return (
    <MsalProvider instance={msalInstance}>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/new-side-bar/" element={<DashboardContainerV2 />} />
          <Route element={<ProtectedRoute />}>
            {/* <Route path="/" element={<Overview />} /> */}
            <Route path="/employees/" element={<Employess />} exact />
            <Route path="/training-center/" element={<TrainingCenter />} />
            <Route
              path="/training-center/cohorts/:cohortId/applications/"
              element={<TrainingCenterApplications />}
            />
            <Route path="/" element={<OverViewPage />}>
              <Route path="/" element={<LeaveRecords />} />
              <Route path="/leave-requests" element={<LeaveRequests />} />
            </Route>
            <Route path="/projects" element={<ProjectsPage />} />
            <Route path="/project-details/:slug/" element={<ProjectDetails />} />
            <Route path="/calendar/" element={<CalendarDetails />} />
          </Route>
          <Route path="reset-password" element={<ResetPassword />} />
          <Route
            path="request-password-reset"
            element={<RequestPasswordReset />}
          />
          <Route path="*" element={<NotFound />} />

          {/* version2 */}
          <Route path="/v2/" element={<OverViewPage />} />
          <Route path="/v2/login/" element={<Login />} />
          <Route path="/v2/leaves/" element={<LeavesPage />} />
          {/* <Route path="/v2/loadders/leaves/" element={<LeavesOverview/>} /> */}
          <Route path="/v2/employees/" element={<EmployeesPage />} />

          <Route path="/v2/projects/" element={<AllProjectsPage />} />
          <Route path="/v2/projects/:slug/" element={<ProjectDetails />} />
          <Route path="/v2/training-center/" element={<TrainingCenterPage />} />
          <Route path="/v2/training-center/cohorts/:cohortId/" element={<CohortDetailsPage />} />
          {/* <Route path="/v2/training-cohort/" element={<CohortDetailsPage />} /> */}
          <Route
            path="/v2/training-center/applications/"
            element={<TrainingCenterApplicationsPage />}
          />
          <Route path="/v2/canteen/" element={<CanteenPage />} />
          <Route path="/v2/canteen/admin/" element={<CanteenAdminPanel />} />

          <Route path="/v2/job-match/" element={<JobMatchOverviewPage />} />
          <Route path="/v2/canteen/order/" element={<CanteenOrderPage />} />
          <Route path="/v2/job-match/jobs/" element={<JobsPage />} />
          <Route path="/v2/training-center/cohorts/" element={<CohortPage />} />
          {/* <Route path="/v2/training-center/cohort-page/" element={<CohortFullPage />}/> */}
          <Route path="/v2/training-center/transaction/" element={<TransactionPage />} />

          {/* Blog module */}
          <Route path="/v2/blog/analytics/" element={< BlogAnalyticsPage />} />
          <Route path="/v2/blog/new/" element={< NewBlogPage />} />
          <Route path="/v2/blog/:blogSlug/update/" element={< UpdateBlogPage />} />
          <Route path="/v2/blog/details/:blogId/" element={< BlogDetailsPage />} />
          <Route path="/v2/blog/recent-blogs/" element={< RecentBlogsPage />} />
          <Route path="/v2/blog/categories/" element={< BlogsCategories />} />
          {/* End of blog module */}
        </Routes>
      </Router>
    </MsalProvider>
  );
}

export default App;
