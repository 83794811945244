import React from 'react'
import DashboardContainerV2 from '../../components/dashbaord/dashboardContainerV2.jx'
import { Helmet } from 'react-helmet'

const OverViewPageContent = () => {
    return (
        <div className='overview-page'>
            <Helmet>
                <title>Overview | CSR Back Office</title>
            </Helmet>
            <h1>Overview</h1>
            <p>Overview page</p>

        </div>
    )
}

const OverViewPage = () => {
    return (
        <div className='overview-page'>
            <DashboardContainerV2 content={<OverViewPageContent />} />
        </div>
    )

}

export default OverViewPage
