import React from 'react'
import DashboardContainerV2 from '../../../components/dashbaord/dashboardContainerV2.jx'
import { Helmet } from 'react-helmet'

const BlogAnalyticsPageContent = () => {
    return (
        <div>
            <Helmet>New blog</Helmet>
            <h1>Blog Analytics</h1>
        </div>
    )
}
const BlogAnalyticsPage = () => {
    return (
        <DashboardContainerV2 content={<BlogAnalyticsPageContent />} />
    )
}

export default BlogAnalyticsPage
