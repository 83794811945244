import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useGlobalContext } from "../../../context/Context";
import AuthService from "../../../services/auth";
import api, { API_URL } from "../../../utils/api";
import Nodata from "../../Nodata";
import Select from 'react-select';
import { IoClose, IoFilter } from "react-icons/io5";
import ProjectDetailsContainer from "./projectDetailsContainer";
import { FaSearch } from "react-icons/fa";
import { CiCirclePlus } from "react-icons/ci";
import TasksContainer from "./tasksContainer";
import LoadingIcon from "../../loadingIcon";

const ProjectDetailsLoader = () => {
    return (
        <div className="project-details-page-loader">
            <div className="loading-items">
                <LoadingIcon size={32} />
                <p>Getting project details...</p>
            </div>
        </div>
    )
}
const ProjectDetailsContent = () => {
    const { slug } = useParams();

    const { isPopupOpen, openPopup, closePopup } = useGlobalContext();

    const [project, setProject] = useState(null);
    const [message, setMessage] = useState('');
    const [isAdding, setIsAdding] = useState(false);
    const [employees, setEmployees] = useState([]);
    const [statuses, setStatuses] = useState([]);
    const [tasks, setTasks] = useState([]);
    const [taskName, setTaskName] = useState('');
    const [projectName, setProjectName] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [taskDescription, setTaskDescription] = useState('');
    const [taskStatus, setTaskStatus] = useState('');
    const [assignees, setAssignees] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [formData, setFormData] = useState({
        name: '',
        status_name: '',
        end_date: '',
        description: '',
    });


    const accessToken = AuthService.getToken();
    const [gettingProject, setGettingProject] = useState(true)
    const [gettingTasks, setGettingTasks] = useState(true)

    useEffect(() => {
        const getProject = async () => {
            try {
                const response = await api.get(`${API_URL}/api/projects/${slug}/`)
                if (response.status === 200) {
                    console.log(response.data.project)
                    setProject(response.data.project)
                    setGettingProject(false)
                }
            } catch (error) {
                console.log(error)
                setGettingProject(false)
            }
        }

        const getTasks = async () => {
            try {
                const response = await api.get(`${API_URL}/api/projects/${slug}/tasks/`)
                if (response.status === 200) {
                    console.log(response.data.tasks)
                    setTasks(response.data.tasks)
                    setGettingTasks(false)
                }
            } catch (error) {
                console.log(error)
                setGettingTasks(false)
            }
        }
        getTasks()
        getProject()

    }, []);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleTask = async (e) => {
        e.preventDefault();
        setIsAdding(true);

        const assignedTo = assignees.map(a => a.value)

        const taskData = {
            'task_name': taskName,
            'project_name': projectName,
            'description': taskDescription,
            'assigned_to': assignedTo,
            'task_status': taskStatus,
            'start_date': startDate,
            'deadline': endDate
        };

        try {
            const res = await fetch(`${API_URL}/api/create_task`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                },
                body: JSON.stringify(taskData)
            });
            console.log(taskData);

            const data = await res.json();
            if (!res.ok || data.error) {
                setMessage(data.error);
                setIsAdding(false);
                document.getElementById('form').reset();
            } else {
                setMessage(data.message);
                setIsAdding(false);
                setTimeout(() => {
                    setMessage('');
                    closePopup();
                    document.getElementById('form').reset();
                }, 2000);
            }
        } catch (error) {
            console.log(error);
        }
    };



    const employeeOptions = employees.map((employee) => ({
        value: employee.id,
        label: `${employee.user.first_name} ${employee.user.last_name}`
    }));

    const handleSearchChange = (e) => {
        const query = e.target.value;
        setSearchQuery(query);
        if (query.length > 0) {
            const filteredSuggestions = tasks.filter(task =>
                task.name.toLowerCase().includes(query.toLowerCase())
            );
            setSuggestions(filteredSuggestions);
        } else {
            setSuggestions([]);
        }
    };

    const filteredTasks = tasks.filter(task =>
        task ? task.name.toLowerCase().includes(searchQuery.toLowerCase()) : <Nodata style={`no-data`} title={`No task available for this project`} />
    );

    const getStatusStyle = (status_name) => {
        const status = statuses.find(status => status.name === status_name)
        return status ? { color: status.color_code } : ''
    }

    return (

        <>
            {
                gettingProject || gettingTasks
                    ? <ProjectDetailsLoader />
                    : <section className="project-details-section">
                        {isPopupOpen && (
                            <div className="popup">
                                <div className="assign-task">
                                    <div className="title">
                                        <h3>Create Task</h3>
                                        <div className="icon">
                                            <button onClick={closePopup}>
                                                <IoClose />
                                            </button>
                                        </div>
                                    </div>
                                    <form onSubmit={handleTask} id="form">
                                        {message && (
                                            <p className='message'>{message}</p>
                                        )}
                                        <input
                                            type="text"
                                            placeholder="Enter Task Name"
                                            value={taskName}
                                            onChange={(e) => setTaskName(e.target.value)}
                                        />
                                        <input
                                            type="text"
                                            value={project?.name}
                                            disabled
                                        />
                                        <div className="input-fields">
                                            <div className="field">
                                                <label>Start date</label>
                                                <input
                                                    type="date"
                                                    value={startDate}
                                                    onChange={(e) => setStartDate(e.target.value)}
                                                />
                                            </div>
                                            <div className="field">
                                                <label>Due date</label>
                                                <input
                                                    type="date"
                                                    value={endDate}
                                                    onChange={(e) => setEndDate(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <Select
                                            isMulti
                                            name="employees"
                                            options={employeeOptions}
                                            className="basic-multi-select"
                                            classNamePrefix='select'
                                            value={assignees}
                                            onChange={setAssignees}
                                            placeholder='Select Employees'
                                        />
                                        <select
                                            name="status_name"
                                            value={taskStatus}
                                            onChange={(e) => setTaskStatus(e.target.value)}
                                        >
                                            <option disabled>Select a status</option>
                                            {statuses.map((status) => (
                                                <option key={status.id} value={status.name}>
                                                    {status.name}
                                                </option>
                                            ))}
                                        </select>
                                        <textarea
                                            cols="30"
                                            rows="10"
                                            placeholder="Enter task Description"
                                            value={taskDescription}
                                            onChange={(e) => setTaskDescription(e.target.value)}
                                        />
                                        <div className="btns">
                                            <button className="cancel-btn">Cancel</button>
                                            <button className="add-btn">
                                                {isAdding ? (
                                                    <div className="requesting-container">
                                                        <div className='requesting'>Saving task</div>
                                                        <div className="requesting-dots">
                                                            <span className="requesting-dot">.</span>
                                                            <span className="requesting-dot">.</span>
                                                            <span className="requesting-dot">.</span>
                                                        </div>
                                                    </div>
                                                ) : 'Add Task'}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        )}


                        <div className="contents-container">
                            <div className="project-details-container">
                                <ProjectDetailsContainer project={project} />
                                {
                                    gettingTasks ? '..Getting Task details'
                                        : <div className="tabs">
                                            <div className="tasks">
                                                <p>Tasks</p>
                                                <div className="task-count">
                                                    <p>{tasks.length}</p>
                                                </div>
                                            </div>
                                            <div className="input-fields">
                                                <div className="input">
                                                    <input
                                                        type="text"
                                                        placeholder='Search Task....'
                                                        value={searchQuery}
                                                        onChange={handleSearchChange}
                                                    />
                                                    <div className="icon">
                                                        <FaSearch />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="btns">
                                                <button onClick={openPopup} className="add-btn">
                                                    <span className="icon"><CiCirclePlus /></span> New Task
                                                </button>
                                                <button className="recent-btn">
                                                    <span><IoFilter /></span> Recent
                                                </button>
                                            </div>
                                        </div>
                                }
                            </div>

                            <TasksContainer filteredTasks={filteredTasks} tasks={tasks} />

                        </div>
                    </section>
            }
        </>

    );
};


export default ProjectDetailsContent