import React, { useState } from 'react'
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom'
import { CiMail } from 'react-icons/ci'
import { useGlobalContext } from '../context/Context'
import '../styles/resetPassword/reset-password-request.css'

const RequestPasswordReset = () => {
    const { API_URL } = useGlobalContext()
    const navigate = useNavigate()

    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')
    const [isRequesting, setIsRequesting] = useState(false)

    const handleRequestReset = async (e) => {
        e.preventDefault()
        setMessage('')
        setIsRequesting(true)

        try {
            const res = await axios.post(`${API_URL}/api/password_reset/`, { email })

            if (res.data.message) {
                setMessage(res.data.message)
                setTimeout(() => {
                    navigate('/reset-password')
                }, 3000)
                setIsRequesting(false)
            } else {
                setMessage(res.data.error)
                setIsRequesting(false)
            }
        } catch (error) {
            setMessage(error.response?.data?.error)
            setIsRequesting(false)
        }
    };

    return (
        <section className='reset-password'>
            <div className="col">
                <h1>CSR <span className='title'>Back</span> Office</h1>
                <p><span className='subtitle'>Forgot Password?</span> Please enter your email to <br /> access CSR Intranet platform</p>
                <form onSubmit={handleRequestReset}>
                    {message && (
                        <p className='message'>{message}</p>
                    )}
                    <div className='input'>
                        <div className='icon'>
                            <CiMail />
                        </div>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder='Enter your email'
                        />
                    </div>
                    <button type="submit" disabled={isRequesting}>
                        {isRequesting ? (
                            <div className="requesting-container">
                                <div className='requesting'>Requesting</div>
                                <div className="requesting-dots">
                                    <span className="requesting-dot">.</span>
                                    <span className="requesting-dot">.</span>
                                    <span className="requesting-dot">.</span>
                                </div>
                            </div>
                        ) : 'Reset Password'}
                    </button>
                    <button className="login-btn">
                        <Link to='/login'>Back to Login</Link>
                    </button>
                </form>
            </div>
        </section>
    );
};

export default RequestPasswordReset
