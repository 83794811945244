import React from 'react';
import CoursesCard from '../../../../components/trainingCenter/details/coursesCard';
import QuizzesAndExams from '../../../../components/trainingCenter/details/quizzesAndExams';
import DepartmentCard from '../../../../components/trainingCenter/details/departmentCard';
import RecentTransactions from '../../../../components/trainingCenter/details/recentTransactions';
import "../../../../styles/components/trainingcohortoverview.css"

const OverviewContent = ({ cohortDetails }) => {
  return (
    <div className='overview-main'>
        <div className="overview-left">
           <div className="courses-quizzes">
            <CoursesCard  cohortDetails={cohortDetails} />
            <QuizzesAndExams cohortDetails={cohortDetails} />
           </div>
           <div className="trainings-departments">
            <DepartmentCard cohortDetails={cohortDetails}/>
           </div>
        </div>

        <div className="overview-right">
          <RecentTransactions cohortDetails={cohortDetails} />
        </div>
    </div>
  );
};

export default OverviewContent;