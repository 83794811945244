import React, { useEffect, useState } from 'react'

const ApplicationTab = ({selectedApplicant}) => {
  const [activeTab, setActiveTab] = useState('overview');

  const tabContent = {
    overview: <div className='other-info'>
      <div className="personal">
        <h1>Personal Informations</h1>
        <div className="name-phone">
          <div className="text-info">
            <p>Name</p>
            <h1>{selectedApplicant.first_name} {selectedApplicant.last_name}</h1>
          </div>
          <div className="text-info">
            <p>Phone number</p>
            <h1>{selectedApplicant.phone_number}</h1>
          </div>
          <div className="text-info">
            <p>Email</p>
            <h1>{selectedApplicant.email}</h1>
          </div>
        </div>
      </div>

      <div className="personal">
        <h1>Address details</h1>
        <div className="name-phone">
          <div className="text-info">
            <p>District</p>
            <h1>{selectedApplicant.district|| "Not provided"}</h1>
          </div>
          <div className="text-info">
            <p>Sector</p>
            <h1>{selectedApplicant.sector || "Not provided"}</h1>
          </div>
          <div className="text-info">
            <p>Street</p>
            <h1>{selectedApplicant.street || "Not provided"}</h1>
          </div>
        </div>
      </div>
    </div>,
    application:<h1>Hello</h1> ,
    courses: <h1>Hello</h1>,
  
  };
  return (
    <div>
      <div className="cohort-details-tabs">
      <div className="taby">
        <div className={`tab-button ${activeTab === 'overview' ? 'active' : ''}`}
          onClick={() => setActiveTab('overview')}>
          
          <p>
            Other informations
          </p>
        </div>
        <div
          className={`tab-button ${activeTab === 'application' ? 'active' : ''}`}
          onClick={() => setActiveTab('application')}
        >
          
          <p>Courses</p>
        </div>
        <div className={`tab-button ${activeTab === 'courses' ? 'active' : ''}`}
          onClick={() => setActiveTab('courses')}>
         
          <p>
          Exam & Quizzes
          </p>
        </div>
      </div>
      <div className="tab-content">
        {tabContent[activeTab]}
      </div>
    </div>
    </div>
  )
}

export default ApplicationTab
