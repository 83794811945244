import { useState, useEffect } from 'react'
import { useGlobalContext } from '../context/Context'
import { FaSearch } from "react-icons/fa"
import { FaUserPlus } from "react-icons/fa6"
import { BsThreeDotsVertical } from "react-icons/bs"
import { IoClose, IoFilterSharp, IoCalendarOutline } from "react-icons/io5"
import { CgPlayListCheck } from "react-icons/cg";
import CalendarDetails from './CalendarDetails'
import AuthService from '../services/auth'
import LeaveNumbers from './LeaveNumbers'

// component that will be displayed incase we have no data
import Nodata from './Nodata'

const LeaveRecords = () => {
    const {
        openPopup,
        itemsPerPage,
        currentPage,
        handlePage,
        isPopupOpen,
        isCalendarOpen,
        closeCalendar,
        closePopup,
        openCalendar,
        API_URL,
        searchQuery,
        leaves,
        setLeaves,
        handleSearch,
    } = useGlobalContext()

    const accessToken = AuthService.getToken()

    const [message, setMessage] = useState('')
    const [leaveTypes, setLeaveTypes] = useState([])

    // state values for adding leave records
    const [leaveType, setLeaveType] = useState()
    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    const [isAdding, setIsAdding] = useState(false)
    const [loading, setLoading] = useState(true)

    const filterLeaves = leaves.length > 0 && leaves.filter((leave) =>
        leave.leave_type.name.toLowerCase().includes(searchQuery.toLowerCase()
        )
    )


    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = Math.min(startIndex + itemsPerPage, leaves.length);
    const currentLeaves = filterLeaves.length > 0 && filterLeaves.slice(startIndex, endIndex);

    const handleLeave = async (e) => {
        e.preventDefault()
        setIsAdding(true)

        try {
            const res = await fetch(`${API_URL}/api/new-leave/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                },
                body: JSON.stringify({
                    'leave_type': leaveType,
                    'start_date': startDate,
                    'end_date': endDate
                })

            })
            const data = await res.json()
            if (!res.ok || data.error) {
                setMessage(data.error)
                setIsAdding(false)
                document.getElementById('form').reset()
            }
            else {
                setMessage(data.message)
                console.log(data.message)
                setIsAdding(false)
                setTimeout(() => {
                    setMessage('')
                    closePopup()
                    document.getElementById('form').reset()
                }, 2000)

            }

        } catch (error) {
            console.error(error.message)
            setIsAdding(false)
        }
    }

    useEffect(() => {
        const getLeaveData = async () => {

            try {
                const res = await fetch(`${API_URL}/api/employee/leaves/`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`
                    },
                })
                const data = await res.json()
                setLoading(false)
                setLeaves(data)

                // const approvedLeaves = data.filter(leave => (leave.approval_level === 'hr'))

                // if (approvedLeaves) {
                //     setLeaves(approvedLeaves)
                //     setLoading(false)
                // }


            } catch (error) {
                console.log(error)
                setLoading(false)
            }
        }

        getLeaveData()

    }, [])

    useEffect(() => {
        const getLeaveTypes = async () => {
            try {
                const res = await fetch(`${API_URL}/api/list_leavetypes/`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`
                    }
                })
                const data = await res.json()
                setLeaveTypes(data)
            } catch (error) {
                console.log(error)
            }
        }
        getLeaveTypes()
    }, [])


    return (
        <>
            {isPopupOpen && (
                <div className="popup">
                    <div className="add-leave">
                        <div className="title">
                            <h3>Add Leave</h3>
                            <div className="icon">
                                <button onClick={closePopup}>
                                    <IoClose />
                                </button>
                            </div>
                        </div>
                        <form onSubmit={handleLeave} id='form'>
                            {message && (
                                <p className='message'>{message}</p>
                            )}
                            <select
                                name='leave'
                                value={leaveType}
                                onChange={(e) => setLeaveType(e.target.value)}
                            >
                                <option value="" disabled selected>leave type</option>
                                {leaveTypes.map((leave) => (
                                    <option value={leave.name}>{leave.name}</option>
                                ))}
                            </select>
                            <div className="date-input-fields">
                                <div className="date-input">
                                    <div className="icon"></div>
                                    <input
                                        value={startDate}
                                        onChange={(e) => setStartDate(e.target.value)}
                                        type="date"
                                    />
                                </div>
                                <div className="date-input">
                                    <input
                                        value={endDate}
                                        onChange={(e) => setEndDate(e.target.value)}
                                        type="date"
                                    />
                                </div>
                            </div>
                            <div className="btns">
                                <button className='cancel-btn' onClick={closePopup}>Cancel</button>
                                <button className='add-btn'>
                                    {isAdding ? (
                                        <div className="requesting-container">
                                            <div className='requesting'>Requesting</div>
                                            <div className="requesting-dots">
                                                <span className="requesting-dot">.</span>
                                                <span className="requesting-dot">.</span>
                                                <span className="requesting-dot">.</span>
                                            </div>
                                        </div>
                                    ) : 'Add Leave'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
            {/* leave numbers */}
            <LeaveNumbers />

            <div className="contents-container">
                <div className='title-contents'>
                    <div className='top'>
                        <div className="col">
                            <h4>Leaves</h4>
                            <p>{filterLeaves.length} leave(s)</p>
                        </div>
                        {!isCalendarOpen && (
                            <div className="input-fields">
                                <div className="input">
                                    <input
                                        type='text'
                                        value={searchQuery}
                                        onChange={handleSearch}
                                        placeholder='Leave type.....'
                                    />
                                    <div className="icon">
                                        <FaSearch />
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className='col'>
                            <div className="leave-btns">
                                <div className="calendar-list-btns">
                                    <button
                                        onClick={closeCalendar}
                                        className={`
                                        ${isCalendarOpen ? 'list-btn' : 'list-btn active'}
                                        `}
                                    >
                                        <CgPlayListCheck />
                                    </button>
                                    <button
                                        onClick={openCalendar}
                                        className={`
                                        ${isCalendarOpen ? 'calendar-btn active' : 'calendar-btn'}
                                        `}
                                    >
                                        <IoCalendarOutline />
                                    </button>

                                </div>
                                {isCalendarOpen ?
                                    (
                                        <button
                                            onClick={openPopup}
                                            className='add-leave-btn'
                                        >
                                            <span><FaUserPlus /></span> <span className='text'>Request leave</span>
                                        </button>
                                    ) :
                                    (
                                        <button
                                            onClick={openPopup}
                                            className='add-leave-btn'
                                        >
                                            <span><FaUserPlus /></span> Request leave
                                        </button>
                                    )
                                }

                                <button className='recent-btn'>
                                    <span><IoFilterSharp /></span> Recent
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {isCalendarOpen ?
                    <CalendarDetails />
                    :
                    <div className='table-contents'>
                        {loading ? (
                            <div className="loading-container">
                                <div className="loading">Loading</div>
                                <div className="loading-dots">
                                    <span className="loading-dot">.</span>
                                    <span className="loading-dot">.</span>
                                    <span className="loading-dot">.</span>
                                </div>
                            </div>
                        ) : (
                            currentLeaves && currentLeaves.length > 0 ? (
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Employee Name</th>
                                            <th>Leave Type</th>
                                            <th>Start Date</th>
                                            <th>End Date</th>
                                            {/* <th>Leave Status</th> */}
                                            {/* <th>Action</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentLeaves.map((leave) => (
                                            <tr key={leave.id}>
                                                <td>
                                                    <span>
                                                        {leave.employee_name.user.first_name.charAt(0)}{leave.employee_name.user.last_name.charAt(0)}
                                                    </span>
                                                    {' '}
                                                    {leave.employee_name.user.first_name} {leave.employee_name.user.last_name}
                                                </td>
                                                <td>{leave.leave_type.name}</td>
                                                <td>{new Date(leave.start_date).toLocaleDateString()}</td>
                                                <td>{new Date(leave.end_date).toLocaleDateString()}</td>
                                                {/* <td className='hidden-table-col'>{leave.status_name}</td> */}
                                                {/* <td className='hidden-table-col'>
                                                    <button>
                                                        <BsThreeDotsVertical />
                                                    </button>
                                                </td> */}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            ) : (
                                <Nodata
                                    style={`no-data`}
                                    title='No Leaves data available'
                                />
                            )
                        )}
                    </div>
                }
            </div>
            {!isCalendarOpen && (<div className="pagination">
                <button
                    className='prev-btn'
                    onClick={() => handlePage(currentPage - 1)}
                    disabled={currentPage === 1}
                >
                    Previous
                </button>
                {Array.from({ length: Math.ceil(leaves.length / itemsPerPage) }, (_, i) => (
                    <button
                        key={i + 1}
                        className={`page ${currentPage === i + 1 ? 'active' : ''}`}
                        onClick={() => handlePage(i + 1)}>
                        {i + 1}
                    </button>
                ))}
                <button
                    className='next-btn'
                    onClick={() => handlePage(currentPage + 1)}
                    disabled={endIndex >= leaves.length}
                >
                    Next
                </button>
            </div>)}
        </>
    )
}

export default LeaveRecords
