import React from 'react'
import DashboardContainerV2 from "../../../../components/dashbaord/dashboardContainerV2.jx";
// import "../../../../styles/trainingCenter/cohorts/cohort_page_loader.css";
import "../../../../styles/trainingCenter/cohorts/cohorts_page_loader.css";


const CohortPageLoader = () => {
    return (
        <div className='chorts-page-contents'>
            <div className="titles loader">
                <div className="title-text"></div>
                <div className="divider"></div>
                <div className="title-text"></div>
            </div>

            <div className="transaction-filtering">
                <div className="filtering-contents">
                    <div className="transactions loader">
                        <div className="transaction-title"></div>
                        <div className="tansaction-numbers"></div>
                    </div>

                    <div className="search-transactions loader">
                        <div className="search-placeholder"></div>
                        <div className="search-icon"></div>
                    </div>

                    <div className="transaction-filter-button loader">
                        <div className="filter-text"></div>
                        <div className="filter-icon"></div>

                    </div>
                </div>
            </div>

            <div className="cohorts-contents">
                <div className="cohorts-cards">
                    <div className="contents-cards ">
                        <div className="card-text-btn">
                            <div className="text">
                                <div className="card-title loader"></div>
                                <div className="card-date loader"></div>
                            </div>

                            <div className="enrol-btn loader"></div>
                        </div>

                        <div className="btns-nombers">
                            <div className="btn-number loader"></div>
                            <div className="btn-number loader"></div>
                        </div>
                    </div>
                    <div className="contents-cards ">
                        <div className="card-text-btn">
                            <div className="text">
                                <div className="card-title loader"></div>
                                <div className="card-date loader"></div>
                            </div>

                            <div className="enrol-btn loader"></div>
                        </div>

                        <div className="btns-nombers">
                            <div className="btn-number loader"></div>
                            <div className="btn-number loader"></div>
                        </div>
                    </div>
                    <div className="contents-cards ">
                        <div className="card-text-btn">
                            <div className="text">
                                <div className="card-title loader"></div>
                                <div className="card-date loader"></div>
                            </div>

                            <div className="enrol-btn loader"></div>
                        </div>

                        <div className="btns-nombers">
                            <div className="btn-number loader"></div>
                            <div className="btn-number loader"></div>
                        </div>
                    </div>
                    <div className="contents-cards ">
                        <div className="card-text-btn">
                            <div className="text">
                                <div className="card-title loader"></div>
                                <div className="card-date loader"></div>
                            </div>

                            <div className="enrol-btn loader"></div>
                        </div>

                        <div className="btns-nombers">
                            <div className="btn-number loader"></div>
                            <div className="btn-number loader"></div>
                        </div>
                    </div>
                    <div className="contents-cards ">
                        <div className="card-text-btn">
                            <div className="text">
                                <div className="card-title loader"></div>
                                <div className="card-date loader"></div>
                            </div>

                            <div className="enrol-btn loader"></div>
                        </div>

                        <div className="btns-nombers">
                            <div className="btn-number loader"></div>
                            <div className="btn-number loader"></div>
                        </div>
                    </div>
                    <div className="contents-cards ">
                        <div className="card-text-btn">
                            <div className="text">
                                <div className="card-title loader"></div>
                                <div className="card-date loader"></div>
                            </div>

                            <div className="enrol-btn loader"></div>
                        </div>

                        <div className="btns-nombers">
                            <div className="btn-number loader"></div>
                            <div className="btn-number loader"></div>
                        </div>
                    </div>
                    <div className="contents-cards ">
                        <div className="card-text-btn">
                            <div className="text">
                                <div className="card-title loader"></div>
                                <div className="card-date loader"></div>
                            </div>

                            <div className="enrol-btn loader"></div>
                        </div>

                        <div className="btns-nombers">
                            <div className="btn-number loader"></div>
                            <div className="btn-number loader"></div>
                        </div>
                    </div>
                    <div className="contents-cards ">
                        <div className="card-text-btn">
                            <div className="text">
                                <div className="card-title loader"></div>
                                <div className="card-date loader"></div>
                            </div>

                            <div className="enrol-btn loader"></div>
                        </div>

                        <div className="btns-nombers">
                            <div className="btn-number loader"></div>
                            <div className="btn-number loader"></div>
                        </div>
                    </div>
                    <div className="contents-cards ">
                        <div className="card-text-btn">
                            <div className="text">
                                <div className="card-title loader"></div>
                                <div className="card-date loader"></div>
                            </div>

                            <div className="enrol-btn loader"></div>
                        </div>

                        <div className="btns-nombers">
                            <div className="btn-number loader"></div>
                            <div className="btn-number loader"></div>
                        </div>
                    </div>
                    <div className="contents-cards ">
                        <div className="card-text-btn">
                            <div className="text">
                                <div className="card-title loader"></div>
                                <div className="card-date loader"></div>
                            </div>

                            <div className="enrol-btn loader"></div>
                        </div>

                        <div className="btns-nombers">
                            <div className="btn-number loader"></div>
                            <div className="btn-number loader"></div>
                        </div>
                    </div>
                    <div className="contents-cards ">
                        <div className="card-text-btn">
                            <div className="text">
                                <div className="card-title loader"></div>
                                <div className="card-date loader"></div>
                            </div>

                            <div className="enrol-btn"></div>
                        </div>

                        <div className="btns-nombers">
                            <div className="btn-number loader"></div>
                            <div className="btn-number loader"></div>
                        </div>
                    </div>
                    <div className="contents-cards ">
                        <div className="card-text-btn">
                            <div className="text">
                                <div className="card-title loader"></div>
                                <div className="card-date loader"></div>
                            </div>

                            <div className="enrol-btn"></div>
                        </div>

                        <div className="btns-nombers">
                            <div className="btn-number loader"></div>
                            <div className="btn-number loader"></div>
                        </div>
                    </div>
                    <div className="contents-cards ">
                        <div className="card-text-btn">
                            <div className="text">
                                <div className="card-title loader"></div>
                                <div className="card-date loader"></div>
                            </div>

                            <div className="enrol-btn loader"></div>
                        </div>

                        <div className="btns-nombers">
                            <div className="btn-number loader"></div>
                            <div className="btn-number loader"></div>
                        </div>
                    </div>
                    <div className="contents-cards ">
                        <div className="card-text-btn">
                            <div className="text">
                                <div className="card-title loader"></div>
                                <div className="card-date loader"></div>
                            </div>

                            <div className="enrol-btn loader"></div>
                        </div>

                        <div className="btns-nombers">
                            <div className="btn-number loader"></div>
                            <div className="btn-number loader"></div>
                        </div>
                    </div>
                    <div className="contents-cards ">
                        <div className="card-text-btn">
                            <div className="text">
                                <div className="card-title loader"></div>
                                <div className="card-date loader"></div>
                            </div>

                            <div className="enrol-btn loader"></div>
                        </div>

                        <div className="btns-nombers">
                            <div className="btn-numberloader"></div>
                            <div className="btn-number loader"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CohortPageLoader