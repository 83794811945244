import React, { useEffect, useState } from 'react'
import NewSideBar from '../sideBar/sideBar'
import '../../styles/dashboard/dashboardContainer.css'
import '../../styles/dashboard/navigation/navigation.css'
import { ArrowDown01Icon, Menu01Icon, Notification02Icon, Search01Icon, UserIcon } from 'hugeicons-react'


const Navigation = ({ toggleSideBarOpen }) => {

    return (
        <div className='dashboard-header'>
            <div onClick={toggleSideBarOpen} className="open-icon">
                <Menu01Icon size={36} color='#145C9E' />
            </div>
            <h4 className='page-title'>Leave records</h4>
            <div className="search-input">
                <Search01Icon
                    size={20}
                    variant={"stroke"}
                />
                <input type="text" name="globalSearch" id="globalSearch" placeholder='Search anything...' />
            </div>

            <div className="profile-notification">
                <div className="notification">
                    <Notification02Icon
                        size={24}
                        variant={"stroke"}
                    />
                    <div className="dot"></div>
                </div>

                <div className="profile">
                    <div className="profile-image">
                        <UserIcon
                            size={24}
                            variant={"stroke"}
                        />
                    </div>

                    <div className="icon">
                        <ArrowDown01Icon
                            size={24}
                            variant={"stroke"}
                        />
                    </div>
                </div>

            </div>
        </div>
    )
}
const DashboardContainerV2 = ({ content }) => {
    const [sideBarOpen, setSideBarOpen] = useState(false)
    const toggleSideBarOpen = () => {
        setSideBarOpen(!sideBarOpen)
    }
    useEffect(() => {
        localStorage.setItem('Notification', 'Hello there')
    })
    return (
        <div className='dashboard-content-container'>
            <NewSideBar sideBarOpen={sideBarOpen} toggleSideBarOpen={toggleSideBarOpen} />
            <div className="dashboard-content">
                <Navigation sideBarOpen={sideBarOpen} toggleSideBarOpen={toggleSideBarOpen} />
                <div className="content">
                    {content}
                </div>
            </div>
        </div>
    )
}

export default DashboardContainerV2
