import React, { useState, useEffect } from "react";
import LoadingIcon from "../../loadingIcon";
import { Cancel01Icon } from "hugeicons-react";
import { categoriesArray } from "../../../dummyData/categories";
import axios from "axios";
import { CSR_API_ENDPOINT } from "../../../utils/api";

const BlogCategories = ({ setBlogCategories, blogCategories }) => {
    const [categories, setCategories] = useState([]);
    const [gettingCategories, setGettingCategories] = useState(true);
    const [selectedItems, setSelectedItems] = useState(blogCategories);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [suggestions, setSuggestions] = useState([]);
    const [searchString, setSearchString] = useState('');

    const fetchingCategories = async () => {
        setGettingCategories(true);
        try {

            // Simulating API call
            const response = await axios.get(`${CSR_API_ENDPOINT}/blog/categories/`)
            console.log(response)

            if (response.status === 200) {
                setCategories(response.data);
                setGettingCategories(false);
                console.log(response)
            }

        } catch (error) {
            console.error("Error fetching categories:", error);
            setGettingCategories(false);
        }
    };

    const handleSearches = (value) => {
        setSearchString(value);
        setShowSuggestions(true);
        const filteredCategories = categories.filter(category =>
            category.name.toLowerCase().includes(value.toLowerCase())
        );
        setSuggestions(filteredCategories);
    };

    const handleSelectedItem = (category) => {
        if (!selectedItems.includes(category.name)) {
            const newSelectedItems = [...selectedItems, category];
            setSelectedItems(newSelectedItems);
            setBlogCategories(newSelectedItems);
        }
        setShowSuggestions(false);
        setSearchString('');
    };

    const handleRemoveSelectedItem = (itemName) => {
        const newSelectedItems = selectedItems.filter(cat => cat !== itemName);
        setSelectedItems(newSelectedItems);
        setBlogCategories(newSelectedItems);
    };

    useEffect(() => {
        fetchingCategories();
    }, []);

    return (
        <div className={`${showSuggestions ? 'popup-selection' : 'input'}`}>
            <div className="action">
                <label htmlFor="category-search">Categories</label>
                <div className="view-all"></div>
            </div>
            <input
                id="category-search"
                type="search"
                value={searchString}
                onChange={(e) => handleSearches(e.target.value)}
                placeholder='Search categories'
            />
            <div className="categories-list">
                {gettingCategories ? (
                    <LoadingIcon size={18} />
                ) : selectedItems.length > 0 ? (
                    selectedItems.map((category, index) => (
                        <div key={index} className="category-item">
                            <p>{category.name}</p>
                            <Cancel01Icon
                                size={12}
                                className='remove-item'
                                onClick={() => handleRemoveSelectedItem(category)}
                            />
                        </div>
                    ))
                ) : (
                    <div className="select-item">No category selected</div>
                )}
                {showSuggestions && suggestions.length > 0 && (
                    <div className="suggestions">
                        {suggestions.map((suggestion, index) => (
                            <div
                                className='suggestion'
                                key={index}
                                onClick={() => handleSelectedItem(suggestion)}
                            >
                                <p>{suggestion.name}</p>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default BlogCategories;