import React from "react"
import FormattedDate from "../formattedDate"
import { Calendar01Icon, Clock01Icon, Clock02Icon } from "hugeicons-react"

const LeaveDetailsPopup = ({ leave, setShowLeaveDetailsPopup }) => {
    return (
        <div className="leave-details-popup">
            <div className="popup-content">
                <h1>Leave details</h1>
                <div className="title-status">
                    <div className="title-date">
                        <h3>{leave.leave_type.name}</h3>
                        <span><small>Requested on: </small><p><FormattedDate date={leave.created_at} /></p></span>
                    </div>

                    <div className="status">
                        <div className="dot"></div>
                        <p>{leave.status_name}</p>
                    </div>
                </div>

                <div className="leave-info">
                    <div className="info">
                        <div className="label">
                            <Calendar01Icon size={20} />
                            <small>Start date</small>
                        </div>
                        <div className="text">
                            <p><FormattedDate date={leave.start_date} /></p>
                        </div>
                    </div>

                    <div className="info">
                        <div className="label">
                            <Calendar01Icon size={20} />
                            <small>End date</small>
                        </div>
                        <div className="text">
                            <p><FormattedDate date={leave.end_date} /></p>
                        </div>
                    </div>

                    <div className="info">
                        <div className="label">
                            <Clock02Icon size={20} />
                            <small>Leave days</small>
                        </div>
                        <div className="text">
                            <p>0</p>
                        </div>
                    </div>
                </div>

                <div className="employee-details">
                    <div className="header"><p>Employee details</p></div>
                    <div className="employee-info">
                        <div className="initials"></div>
                        <div className="details">
                            <div className="employee-id">
                                <p>Id</p>
                                <div className="id">
                                    <p>{leave.employee_name.id}</p>
                                </div>
                            </div>
                            <p>{leave.employee_name.user.first_name} {leave.employee_name.user.last_name}</p>
                            <div className="phone-number">
                                <small>Phone number</small>
                                <span>{leave.employee_name.phone_number}</span>
                            </div>
                            <div className="email">
                                <small>Email</small>
                                <span>{leave.employee_name.user.email}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="leave-reason">
                    <div className="header"><p>Leave reason</p></div>
                    <div className="reason">
                        <p>{leave.reason || 'This leave has no reason'}</p>
                    </div>
                </div>
                <div className="buttons">
                    <button type="button" onClick={() => setShowLeaveDetailsPopup(false)}>Close</button>
                    <button type="button" className="secondary-button">Reject</button>
                    <button type="button" className="primary-button">Approve</button>
                </div>
            </div>
        </div>
    )
}

export default LeaveDetailsPopup