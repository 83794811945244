import React, { useEffect, useState } from "react";
import axios from "axios";

const OrderItem = ({id, orderName, price, initialQuantity, removeItem, updateQuantity}) => {
    const [quantity, setQuantity] = useState(initialQuantity);
  
    const handleIncrease = () => {
      setQuantity(prevQuantity => {
        const newQuantity = prevQuantity + 1;
        updateQuantity(id, newQuantity);
        return newQuantity;
      });
    }
  
    const handleDecrease = () => {
      setQuantity(prevQuantity => {
        if (prevQuantity > 1) {
          const newQuantity = prevQuantity - 1;
          updateQuantity(id, newQuantity);
          return newQuantity;
        }
        return prevQuantity;
      });
    }
  
  
      return (
          <>
            <div className="order-item">
              <div className="name">
                <h2>{orderName}</h2>
                <img src="/images/close.svg" alt="close icon" onClick={() => removeItem(id)} style={{cursor: 'pointer'}} />
              </div>
              <div className="price">
                <div className="cost">
                  <p>{price}</p>
                  <p>Rwf</p>
                </div>
        
                <div className="quantity">
                  <div className="decrease" onClick={handleDecrease} style={{cursor: 'pointer'}}>
                      <img src="/images/add.svg" alt="substract icon" />
                  </div>
                  <span>{quantity}</span>
                  <div className="increase" onClick={handleIncrease} style={{cursor: 'pointer'}}>
                  <img src="/images/minus.svg" alt="add icon" />
                  </div>
                </div>
              </div>
            </div>
          </>
        );
  }

const DepartmentItem = ({ department, isActive, onClick }) => {
  return (
    <>
      <div
        className={`department-item ${isActive ? "active" : ""}`}
        onClick={onClick}
      >
        <p>{department.name}</p>
      </div>
    </>
  );
};

const OrderDetailsItem = ({ orderName, price, quantity }) => {
  return (
    <>
      <div className="item">
        <p>{orderName}</p>
        <div className="price">
          <p>{price}</p>
          <span>Rwf</span>
        </div>
      </div>
    </>
  );
};

const OrderPopup = ({
  setShowPopup,
  setShowSuccessPopup,
  orders,
  firstName,
  lastName,
}) => {
  const [orderDetails, setOrderDetails] = useState([]);
  const [activeDepartment, setActiveDepartment] = useState(null);
  const [departmentData, setDepartmentData] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [anonymousFirstName, setAnonymousFirstName] = useState('');
  const [anonymousLastName, setAnonymousLastName] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem("role") === "client");

  useEffect(() => {
    setIsLoggedIn(localStorage.getItem("role") === "client");
  }, []);

  const handlePlaceOrder = async () => {
    setShowPopup(false)
    setShowSuccessPopup(true)
  }

//   const handlePlaceOrder = async () => {
//     setIsLoading(true);
//     try {
//       if (orders.length === 0) {
//         setErrorMessage('No orders to place');
//         return;
//       }

//       if (
//         activeDepartment === null ||
//         activeDepartment >= departmentData.length
//       ) {
//         setErrorMessage("Please select a department");
//         return;
//       }

//       const orderProducts = orders.map(order => ({
//         order_product: order.name,
//         quantity: order.quantity
//       }));

//       if (orderProducts.length === 0) {
//         setErrorMessage('No order products found');
//         return;
//       }


//       let orderFirstName, orderLastName;

//       if(isLoggedIn){
//         orderFirstName = localStorage.getItem("firstName");
//       orderLastName = localStorage.getItem("lastName");
//       } else{
//         orderFirstName = anonymousFirstName;
//       orderLastName = anonymousLastName;
//       }

//       if(!orderFirstName || !orderLastName){
//         setErrorMessage('Please enter your first and last name');
//         return;
//       }

      

//     //   const res = await axios.post(`${BASE_URL}/api/dashboard/order/`, {
//     //     first_name: orderFirstName,
//     //     last_name: orderLastName,
//     //     order_products: orderProducts,
//     //     location: departmentData[activeDepartment].name,
//     //   });
//     //   console.log({
//     //     first_name: orderFirstName,
//     //     last_name: orderLastName,
//     //     order_products: orderProducts,
//     //     location: departmentData[activeDepartment].name
//     //   });
//     //   console.log(res.data);
      
      
//     } catch (err) {
//       setErrorMessage('An error occurred while placing the order. Please try again')
//       if (err.response) {
//         console.log(err.response.data);
//       } 
//     } finally{
//       setIsLoading(false);
//     }
//   };

//   useEffect(() => {
//     const fetchDepartmentData = async () => {
//       try {
//         const res = await axios.get(`${BASE_URL}/api/dashboard/location/`);
//         const locations = res.data.locations;
//         if (Array.isArray(locations)) {
//           setDepartmentData(locations);
//         } else {
//           console.log("Fetched department data is empty");
//           setDepartmentData([]);
//         }
//       } catch (err) {
//         console.error("Error fetching data: ", err);
//         setDepartmentData([]);
//       }
//     };

//     fetchDepartmentData();
//   }, []);

  useEffect(() => {
    const savedOrderData = sessionStorage.getItem("orderData");
    if (savedOrderData) {
      const parsedOrderData = JSON.parse(savedOrderData);
      setOrderDetails(parsedOrderData);
    }

    return () => {
      setOrderDetails([]);
    };
  }, []);

  const calculateTotal = () => {
    return orderDetails.reduce(
      (total, order) => total + order.price * order.quantity,
      0
    );
  };

  const handleDepartmentClick = (index) => {
    setActiveDepartment(index);
  };

  const handleCancel = () => {
    const userConfirmation = window.confirm('Are you sure you want to cancel?');
    if(userConfirmation){
      setShowPopup(false);
    }
  }

  return (
    <div className="order-popup">
      <div className="popup-content">
        <div className="first-row">
          <h2>Order Details</h2>
          <div className="order-details-div">
            {orderDetails.map((order, index) => (
              <OrderDetailsItem
                key={index}
                orderName={order.name}
                price={order.price}
                quantity={order.quantity}
              />
            ))}
          </div>

          <div className="popup-total">
            <h3>Order Total</h3>
            <span>{calculateTotal()} Rwf</span>
          </div>
        </div>

        <div className="second-row">
          <div className="first-line">
            <h2>Place Order</h2>
            <img
              src="/images/close.svg"
              alt="close icon"
              className="close-icon"
              onClick={handleCancel}
            />
          </div>
          {!isLoggedIn && (
            <div className="anonymous-user-form">
            <input type="text" placeholder="First Name" value={anonymousFirstName} onChange={(e) => setAnonymousFirstName(e.target.value)} />
            <input type="text" placeholder="Last Name" value={anonymousLastName} onChange={(e) => setAnonymousLastName(e.target.value)} />
          </div>
          )}
          

          <div className="department">
            <h2 className="department-title">Select department</h2>

            <div className="departments-div">
              {departmentData.map((department, index) => (
                <DepartmentItem
                  key={index}
                  department={department}
                  isActive={index === activeDepartment}
                  onClick={() => handleDepartmentClick(index)}
                />
              ))}
            </div>
          </div>

          <div className="buttons">
            <div className="cancel" onClick={() => setShowPopup(false)}>
              <img
                src="/images/close.svg"
                alt="close"
                onClick={() => setShowPopup(false)}
              />
              <span>Cancel</span>
            </div>
            <div className="send" onClick={handlePlaceOrder} style={isLoading ?  { cursor: 'not-allowed', opacity: 0.5}: {}} >
              <span>Send Order</span>
              <img src="/images/arrow-right.svg" alt="arrow" />
            </div>
          </div>
          {errorMessage && <div className="error-message" style={{color: 'tomato', paddingTop: '1 rem'}}>{errorMessage}</div>}
          
        </div>
        
      </div>
      
    </div>
  );
};

const SuccessPopup = ({ setSuccessPopup }) => {
    return (
      <>
        <div className="success-div">
          <div className="success-content">
            <div className="first-line">
              <h3>Place Order</h3>
              <img
                src="/images/close.svg"
                alt="close icon"
                onClick={() => setSuccessPopup(false)}
              />
            </div>
            <div className="content">
              <img src="/images/success.svg" alt="success" />
              <h2>Order Sent Successfully</h2>
              <p>
                Your  order has been successfully created and sent to the canteen
              </p>
            </div>
            <div className="button" onClick={() => setSuccessPopup(false)}>
              <img src="/images/left-arrow.svg" alt="" />
              <a href="#">Back To Menu</a>
            </div>
          </div>
        </div>
      </>
    );
  };

export const Order = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [orders, setOrders] = useState([]);
  const [quantities, setQuantities] = useState({});

  const fetchOrderData = () => {
    const savedOrderData = sessionStorage.getItem("orderData");
    if (savedOrderData) {
      const parsedOrderData = JSON.parse(savedOrderData).map((order) => ({
        ...order,
        quantity: Number(order.quantity),
        price: Number(order.price),
      }));
      setOrders(parsedOrderData);
      setQuantities(
        parsedOrderData.reduce((acc, order) => {
          acc[order.id] = order.quantity;
          return acc;
        }, {})
      );
    } else {
      setOrders([]);
      setQuantities({});
    }
  };

  useEffect(() => {
    fetchOrderData();

    const handleOrderDataUpdated = () => {
      fetchOrderData();
    };

    window.addEventListener("orderDataUpdated", handleOrderDataUpdated);

    return () => {
      window.removeEventListener("orderDataUpdated", handleOrderDataUpdated);
    };
  }, []);

  const formatDate = (date) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(date).toLocaleDateString(undefined, options);
  };

  const removeItem = (id) => {
    setOrders((prevOrders) => {
      const updatedOrders = prevOrders.filter((order) => order.id !== id);

      if (updatedOrders.length === 0){
        sessionStorage.removeItem("orderData");
        const event = new Event("allOrdersRemoved");
        window.dispatchEvent(event);
      }
      setQuantities((prevQuantities) => {
        const newQuantities = { ...prevQuantities };
        delete newQuantities[id];
        return newQuantities;
      });

      sessionStorage.setItem("orderData", JSON.stringify(updatedOrders));

      const event = new Event("orderDataUpdated");
      window.dispatchEvent(event);

      return updatedOrders;
    });
  };

  const handlePopup = (e) => {
    e.preventDefault();
    setShowPopup(true);
    
  };

  const updateQuantity = (id, quantity) => {
    setQuantities((prev) => ({
      ...prev,
      [id]: quantity,
    }));
    const updatedOrders = orders.map((order) =>
      order.id === id ? { ...order, quantity } : order
    );
    setOrders(updatedOrders);
    sessionStorage.setItem("orderData", JSON.stringify(updatedOrders));
  };

  const calculateTotal = () => {
    return orders.reduce(
      (total, order) => total + order.price * quantities[order.id],
      0
    );
  };


  const todayDate = formatDate(new Date());

  const handleOrderButtonClick = (e) => {
    e.preventDefault();
    alert("Please add order items");
    return;
  };

  return (
    <>
      <div className="order-container">
        <div className="title">
          <h2>Order details</h2>
          <div className="date">
            <img src="/images/calendar.svg" alt="calendar icon" />
            {todayDate}
          </div>
        </div>
        <div className="details">
          <div className="orders">
            {orders.map((order, index) => (
              <OrderItem
                key={order.id}
                id={order.id}
                orderName={order.name}
                price={order.price}
                initialQuantity={quantities[order.id]}
                removeItem={removeItem}
                updateQuantity={updateQuantity}
              />
            ))}
          </div>

          <div className="total">
            <h3>Grand Total</h3>
            <div className="price">
              <p>{calculateTotal()}</p>
              <p>Rwf</p>
            </div>

            <div className="button">
              <a
                href="#"
                onClick={
                  orders.length === 0 ? handleOrderButtonClick : handlePopup
                }
                
              >
                Place Order
              </a>
            </div>
          </div>
        </div>
        {showPopup && (
          <OrderPopup
            setShowPopup={setShowPopup}
            setShowSuccessPopup={setShowSuccessPopup}
            orders={orders}
            firstName={localStorage.getItem("firstName" || '')}
            lastName={localStorage.getItem("lastName" || '')}
          />
        )}
        {showSuccessPopup && (
          <SuccessPopup setSuccessPopup={setShowSuccessPopup} />
        )}
      </div>
    </>
  );
};