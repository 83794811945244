import React, { useState, useEffect } from "react";
import DashboardContainerV2 from "../../../components/dashbaord/dashboardContainerV2.jx";
import "../../../styles/dashboard/jobMatch/overview.css";
import {
  BookmarkAdd02Icon,
  ArrowRight02Icon,
  MoreHorizontalCircle01Icon,
} from "hugeicons-react";
import { BarChart } from "@mui/x-charts/BarChart";
import LeavesOverviewLoadder from "../../../components/loadders/leaves/overview";
import { Link } from "react-router-dom";

const OverviewPageContent = () => {
  const [data, setData] = useState({});
  const [isLoadding, setIsLoadding] = useState(true);
  const dummyData = {
    numbers: {
      job_posted: {
        number: 12,
        increase_percentage: 7.5,
      },
      applicants: {
        number: 14,
        increase_percentage: 8.2,
      },
      total_applicants: {
        number: 14500,
        increase_percentage: 6.3,
      },
      active_job_matches: {
        number: 14500,
        increase_percentage: 6.3,
      },
    },
    user_performances: {
      yearly: {
        employers: [320, 290, 310, 340, 330, 360, 310, 330, 320, 43, 54, 43],
        applicants: [
          240, 260, 270, 300, 310, 290, 300, 310, 300, 230, 123, 280,
        ],
        months: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
    },
    applications: [
      {
        applicant: "John Doe",
        email: "johndoe@gmail.com",
        job_posting: "Senior UI/UX Designer",
        company: "Spotify",
        application_date: "17 July 2024",
        application_time: "10:00 AM",
        status: "In Review",
      },
      {
        applicant: "Jane Smith",
        email: "janesmith@gmail.com",
        job_posting: "Frontend Developer",
        company: "Google",
        application_date: "18 July 2024",
        application_time: "11:00 AM",
        status: "In Review",
      },
      {
        applicant: "Alice Johnson",
        email: "alicejohnson@gmail.com",
        job_posting: "Backend Developer",
        company: "Amazon",
        application_date: "19 July 2024",
        application_time: "12:00 PM",
        status: "In Review",
      },
    ],
    recent_sign_ups: [
      {
        name: "Ammar Hernandez",
        email: "ammar.hernandez@gmail.com",
        type: "Applicant",
        date: "17 June 2024",
      },
      {
        name: "Filip Baldwin",
        email: "filipbaldwin@gmail.com",
        type: "Employer",
        date: "17 June 2024",
      },
      {
        name: "Abu Rocha",
        email: "aburocha@gmail.com",
        type: "Applicant",
        date: "17 June 2024",
      },
    ],
  };

  useEffect(() => {
    const fetchData = () => {
      setData(dummyData);
    };

    fetchData();
  }, []);

  setTimeout(() => {
    setIsLoadding(false);
  }, 1000);

  return isLoadding ? (
    <LeavesOverviewLoadder />
  ) : (
    <section className="job-match-container">
      <div className="top-title">
        <span>Job Match</span>
        <span>/</span>
        <span>Overview</span>
      </div>
      <div className="job-match-main-container">
        <div className="job-match-top">
          <div className="grid-cards">
            <Link to={'/v2/job-match/jobs/'} className="card">
              <h4>Jobs posted</h4>
              <div className="job-row">
                <div className="icon-container">
                  <BookmarkAdd02Icon
                    className="card-icon"
                    size={24}
                    color={"#145c9e"}
                    variant={"stroke"}
                  />
                </div>
                <span>{dummyData.numbers.job_posted.number}</span>
              </div>
              <div className="grey-text">
                <span>
                  +{dummyData.numbers.job_posted.increase_percentage}%
                </span>
                <p>Than last month</p>
              </div>
            </Link>
            <div className="card">
              <h4>Applicants</h4>
              <div className="job-row">
                <div className="icon-container">
                  <BookmarkAdd02Icon
                    className="card-icon"
                    size={24}
                    color={"#145c9e"}
                    variant={"stroke"}
                  />
                </div>
                <span>{dummyData.numbers.applicants.number}</span>
              </div>
              <div className="grey-text">
                <span>
                  +{dummyData.numbers.applicants.increase_percentage}%
                </span>
                <p>Than last month</p>
              </div>
            </div>
            <div className="card">
              <h4>Total Employers</h4>
              <div className="job-row">
                <div className="icon-container">
                  <BookmarkAdd02Icon
                    className="card-icon"
                    size={24}
                    color={"#145c9e"}
                    variant={"stroke"}
                  />
                </div>
                <span>{dummyData.numbers.total_applicants.number}</span>
              </div>
              <div className="grey-text">
                <span>
                  +{dummyData.numbers.total_applicants.increase_percentage}%
                </span>
                <p>Than last month</p>
              </div>
            </div>
            <div className="card">
              <h4>Active job matches</h4>
              <div className="job-row">
                <div className="icon-container">
                  <BookmarkAdd02Icon
                    className="card-icon"
                    size={24}
                    color={"#145c9e"}
                    variant={"stroke"}
                  />
                </div>

                <span>{dummyData.numbers.active_job_matches.number}</span>
              </div>
              <div className="grey-text">
                <span>
                  +{dummyData.numbers.active_job_matches.increase_percentage}%
                </span>
                <p>Than last month</p>
              </div>
            </div>
          </div>
          <div className="job-match-chart">
            <BarChart
              borderRadius={33}
              xAxis={[
                {
                  scaleType: "band",
                  data: [
                    "Jan",
                    "Feb",
                    "Mar",
                    "Apr",
                    "May",
                    "Jun",
                    "Jul",
                    "Aug",
                    "Sep",
                    "Oct",
                    "Nov",
                    "Dec",
                  ],
                  barGapRatio: 0.5,
                },
              ]}
              series={[
                {
                  color: "#145C9E",
                  data: [
                    dummyData.user_performances.yearly.applicants[0],
                    dummyData.user_performances.yearly.applicants[1],
                    dummyData.user_performances.yearly.applicants[2],
                    dummyData.user_performances.yearly.applicants[3],
                    dummyData.user_performances.yearly.applicants[4],
                    dummyData.user_performances.yearly.applicants[5],
                    dummyData.user_performances.yearly.applicants[6],
                    dummyData.user_performances.yearly.applicants[7],
                    dummyData.user_performances.yearly.applicants[8],
                    dummyData.user_performances.yearly.applicants[9],
                    dummyData.user_performances.yearly.applicants[10],
                    dummyData.user_performances.yearly.applicants[11],
                  ],
                  label: "Applicants",
                  id: "applicantsId",
                },
                {
                  color: "#FBDED1",
                  data: [
                    dummyData.user_performances.yearly.employers[0],
                    dummyData.user_performances.yearly.employers[1],
                    dummyData.user_performances.yearly.employers[2],
                    dummyData.user_performances.yearly.employers[3],
                    dummyData.user_performances.yearly.employers[4],
                    dummyData.user_performances.yearly.employers[5],
                    dummyData.user_performances.yearly.employers[6],
                    dummyData.user_performances.yearly.employers[7],
                    dummyData.user_performances.yearly.employers[8],
                    dummyData.user_performances.yearly.employers[9],
                    dummyData.user_performances.yearly.employers[10],
                    dummyData.user_performances.yearly.employers[11],
                  ],
                  label: "Employers",
                  id: "employersId",
                },
              ]}
              height={300}
            />
          </div>
        </div>
        <div className="job-match-bottom">
          <div className="job-matches">
            <div className="top-row">
              <h4>Job matches</h4>

              <Link to={"/v2/job-match/jobs/"} className="view-all">
                <span>View all</span>
                <ArrowRight02Icon
                  size={24}
                  color={"#F87C47"}
                  variant={"stroke"}
                />
              </Link>
            </div>
            <div className="table-container">
              <table>
                <thead>
                  <tr>
                    <th>Applicant</th>
                    <th>Job Posting</th>
                    <th className="application-date-th">Application Time</th>
                    <th className="status-th">Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {dummyData.applications.map((match, index) => (
                    <tr key={index}>
                      <td className="applicant-td">
                        <div id="initial">
                          {match.applicant.split(" ")[0].charAt(0)}
                          {match.applicant.split(" ")[1].charAt(0)}
                        </div>
                        <div id="col">
                          <span className="name">{match.applicant}</span>
                          <span className="email">{match.email}</span>
                        </div>
                      </td>

                      <td className="job-posting-td">
                        <div>{match.job_posting}</div>
                        <div className="company">{match.company}</div>
                      </td>

                      <td className="application-date-td">
                        <div>{match.application_date}</div>

                        <div className="time">{match.application_time}</div>
                      </td>
                      <td className="status-td">
                        <span className="status">{match.status}</span>
                      </td>
                      <td id="action">
                        <MoreHorizontalCircle01Icon
                          size={24}
                          color={"#000000"}
                          variant={"stroke"}
                          className="dots"
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="recent-signups">
            <div className="top-row">
              <h4>Recent Signups</h4>

              <div className="view-all">
                <span>View all</span>
                <ArrowRight02Icon
                  size={24}
                  color={"#F87C47"}
                  variant={"stroke"}
                />
              </div>
            </div>
            <div className="table-container">
              <table>
                <thead>
                  <tr>
                    <th>Name & Email</th>
                    <th>Type & Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {dummyData.recent_sign_ups.map((match, index) => (
                    <tr key={index}>
                      <td className="applicant-td">
                        <div id="initial">
                          {match.name.split(" ")[0].charAt(0)}
                          {match.name.split(" ")[1].charAt(0)}
                        </div>
                        <div id="col">
                          <span className="name">{match.name}</span>
                          <span className="email">{match.email}</span>
                        </div>
                      </td>

                      <td>
                        <div className="type">{match.type}</div>
                        <div className="date">{match.date}</div>
                      </td>

                      <td id="action">
                        <MoreHorizontalCircle01Icon
                          size={24}
                          color={"#000000"}
                          variant={"stroke"}
                          className="dots"
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const JobMatchOverviewPage = () => {
  return <DashboardContainerV2 content={<OverviewPageContent />} />;
};

export default JobMatchOverviewPage;
