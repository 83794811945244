import React from 'react'
import "../../styles/trainingCenter/cohorts/cohorts_cards.css"

const CohortCard = ({ cohort }) => {
    return (

     
        <div className="cohorts-archive">
         
            <div className='cohort-card'>
            <div className="cohort-name-date-enrol_btn">

                <div className="cohort-name-dates">
                    <h4>{cohort.name}</h4>
                    <div className="cohort-dates">
                        <p>{cohort.start_date}</p>
                        <p>{cohort.end_date}</p>

                    </div>
                </div>

                <div className="button enroll-btn">Open for Enrolment</div>

            </div>
            {/* <p>{cohort.trainees_count}</p> */}
            <div className="cohort-numbers">
                <div className="button number">
                    <p>Enrolled</p>
                    <h4>{cohort.trainees || 'Not Provided'}</h4>
                </div>
                <div className="button number">
                    <p>Open spots</p>
                    <h4>{cohort.open_spots || 'Not Provided'}</h4>
                </div>
            </div>
        </div>
        </div>
       
    )
}

export default CohortCard
