import React, { useEffect, useState } from "react";
import api, { API_URL } from "../../../utils/api";
import LoadingIcon from "../../loadingIcon";
import { BsThreeDotsVertical } from "react-icons/bs";
import { IoBriefcase, IoCalendarOutline, IoClose, IoFilter } from "react-icons/io5";
import Nodata from "../../Nodata";
import { CheckmarkCircle02Icon, EyeIcon, CancelCircleIcon } from "hugeicons-react";
const TasksContainer = ({ filteredTasks, tasks }) => {
    const [openTaskPopup, setOpenTaskPopup] = useState(false)
    const [message, setMessage] = useState('');
    const [selectedTask, setSelectedTask] = useState({})
    const [taskName, setTaskName] = useState("")
    const [assignedTo, setAssignedTo] = useState([])
    const [taskDescription, setTaskDescription] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const handleTaskPopup = (task, openPopup) => {
        setSelectedTask(task)
        setOpenTaskPopup(openPopup);
    }

    const handleMarkComplete = async ({ taskId }) => {
        setIsLoading(true)
        console.log('task is: ', selectedTask.id)
        try {
            const response = await api.patch(`${API_URL}/api/projects/tasks/${selectedTask.id}/update/`, { 'task_status': 'Completed' })
            console.log(response)
            if (response.status === 200) {
                setMessage("Your task has been submitted for approval")
                setTimeout(() => {
                    setOpenTaskPopup(false)
                    setMessage("")
                    setIsLoading(false)
                }, 5000)
            }
        } catch (error) {
            if (error.response) {
                setMessage(error.response.data.error || error.response.data.message || 'Error while updating')
            } else {
                setMessage("Unknown error")
            }
            setTimeout(() => {
                setIsLoading(false)
            }, 2000)
            console.error(error)
        }
    }
    return (

        <div className="tasks-details">
            {filteredTasks.length > 0 ? (
                <>
                    {filteredTasks.map((task) => (
                        <div className="task" key={task.id}>

                            <div className="task-status">
                                {
                                    openTaskPopup && task === selectedTask
                                        ? <div className="task-actions">
                                            {
                                                isLoading
                                                    ? <button type="button mark-complete"> <LoadingIcon size={16} /> Marking complete...</button>
                                                    : <button onClick={() => handleMarkComplete(selectedTask.id)} type="button" className="mark-complete"> <CheckmarkCircle02Icon size={16} /> Mark complete</button>
                                            }
                                            <button type="button" className="view-details"><EyeIcon size={16} />  View details</button>
                                            <CancelCircleIcon color="red" onClick={() => handleTaskPopup(task, false)} />
                                        </div>
                                        :
                                        <div className="status">
                                            <div className="status-name">
                                                <div className="dot"></div>
                                                <p >{task.task_status}</p>
                                            </div>
                                            <div className="icon">
                                                <button
                                                    className="open-task-details-btn"
                                                    onClick={() => handleTaskPopup(task, true)}
                                                >
                                                    <BsThreeDotsVertical />
                                                </button>
                                            </div>
                                        </div>
                                }
                                <h3>{task.name}</h3>
                                <h3>{task.id}</h3>
                                <p className="task-description">{task.description}</p>
                                <div className="task-row">
                                    <div className="col">
                                        <p><IoCalendarOutline /> <span>{task.deadline}</span></p>
                                    </div>
                                    <div className="col">
                                        <div className="assignees">
                                            {task.assigned_to.map((assignee) => (
                                                <div className="assignee" key={assignee.id}>
                                                    <p>{assignee.name.split(' ').slice(0, 2).map(word => word.charAt(0)).join('')}</p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </>
            ) : <Nodata title='No tasks match your search' />}
        </div>
    )
}

export default TasksContainer