import React, { useState } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { useGlobalContext } from '../context/Context'
import '../styles/resetPassword/reset-password.css'

// icons
import { CiMail } from 'react-icons/ci'
import { FaKey, FaCentercode } from "react-icons/fa"

const ResetPassword = () => {
    const { API_URL } = useGlobalContext()
    const navigate = useNavigate()

    //   state values
    const [email, setEmail] = useState('')
    const [code, setCode] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [message, setMessage] = useState('')
    const [isRequesting, setIsRequesting] = useState(false)


    const handleResetPassword = async (e) => {
        e.preventDefault()
        setMessage('')
        setIsRequesting(true)

        try {
            const res = await axios.post(`${API_URL}/api/reset_success/`, {
                email,
                code,
                new_password: newPassword
            });

            if (res.data.message) {
                setMessage(res.data.message)
                setTimeout(() => {
                    navigate('/login')
                }, 3000)
                setIsRequesting(false)
            } else {
                setMessage(res.data.error)
                setIsRequesting(false)
            }
        } catch (error) {
            setMessage(error.response?.data?.error)
            setIsRequesting(false)
        }
    };

    return (
        <section className='reset-password-section'>
            <div className="col">
                <h1>CSR <span className='title'>Back</span> Office</h1>
                <p><span className='subtitle'>Reset your password.</span> Enter a new password that you’d <br /> like to use, confirm it and click reset password.</p>
                <form onSubmit={handleResetPassword}>
                    {message && (
                        <p className='message'>{message}</p>
                    )}
                    <div className='input'>
                        <div className='icon'>
                            <CiMail />
                        </div>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder='Enter your email'
                        />
                    </div>
                    <div className='input'>
                        <div className="icon">
                            <FaCentercode />
                        </div>
                        <input
                            type="text"
                            value={code}
                            onChange={(e) => setCode(e.target.value)}
                            placeholder='Enter your reset code'
                            required
                        />
                    </div>
                    <div className='input'>
                        <div className='icon'>
                            <FaKey />
                        </div>
                        <input
                            type="password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            placeholder='Enter your new password'
                            required
                        />
                    </div>
                    <button type="submit">
                        {isRequesting ? (
                            <div className="requesting-container">
                                <div className='requesting'>Resetting</div>
                                <div className="requesting-dots">
                                    <span className="requesting-dot">.</span>
                                    <span className="requesting-dot">.</span>
                                    <span className="requesting-dot">.</span>
                                </div>
                            </div>
                        ) : 'Reset password'}
                    </button>
                </form>
            </div>
        </section>
    );
};

export default ResetPassword
