import React, { useEffect, useState } from "react";
import DashboardContainerV2 from "../../components/dashbaord/dashboardContainerV2.jx";
import api, { API_URL } from "../../utils/api";
import "../../styles/dashboard/leaves/leaves.css";
import { useGlobalContext } from "../../context/Context";
import AuthService from "../../services/auth";
import Nodata from "../../components/Nodata";
import { FaSearch, FaUserPlus } from "react-icons/fa";
import { NotebookIcon, Time01Icon } from "hugeicons-react";
import { IoClose } from "react-icons/io5";
import FormattedDate from "../../components/formattedDate";
import LeaveDetailsPopup from "../../components/leaves/leaveDetails";
import PendingLeaves from "../../components/leaves/pendingLeaves";
import LeavesHistory from "../../components/leaves/leavesHistory";

const LeavesPageContent = ({ content }) => {
  const [allLeaves, setAllLeaves] = useState([])
  const [gettingAllLeaves, setGettingAllLeaves] = useState(true)
  const [leaves, setLeaves] = useState([]);
  const [activeTab, setActiveTab] = useState("pendingLeaves");
  const [leavesHistory, setLeavesHistory] = useState([]);
  const [setPendingLeaves] = useState([]);
  const [selectedLeave, setSelectedLeave] = useState({})
  const [showLeaveDetailsPopup, setShowLeaveDetailsPopup] = useState(false)

  const [leaveTypes, setLeaveTypes] = useState([])
  const [leaveType, setLeaveType] = useState()
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [comment, setComment] = useState([])
  const [isAdding, setIsAdding] = useState(false)
  const [loading, setLoading] = useState(true)

  const {
    isPopupOpen,
    closePopup,
    openPopup,
    itemsPerPage,
    searchQuery,
    message,
    currentPage,
    handlePage,
    handleSearch,
    setMessage,
  } = useGlobalContext();


  useEffect(() => {
    const getAllLeaves = async () => {
      try {
        const response = await api.get(`${API_URL}/api/employee/leaves/`)

        if (response.status === 200) {
          setAllLeaves(response.data)
          console.log(response.data)
          setGettingAllLeaves(false)
        }
      } catch (error) {
        console.log(error)
        setGettingAllLeaves(false)
      }
    }

    getAllLeaves()
  }, [])


  const accessToken = AuthService.getToken();

  // useEffect(() => {
  //   if (content) {
  //     // filter and set leaves history and set pending leaves
  //     const filterLeaveHistory = content.filter((el) => {
  //       return el.status_name === "approved" || el.status_name === "rejected";
  //     });
  //     const filterLeavePending = content.filter((el) => {
  //       return el.status_name !== "approved" && el.status_name !== "rejected";
  //     });
  //     setLeavesHistory(filterLeaveHistory);
  //     setPendingLeaves(filterLeavePending);
  //   }
  // }, [content]);




  const filterLeaves = leaves.length > 0 && leaves.filter((leave) =>
    leave.leave_type.name.toLowerCase().includes(searchQuery.toLowerCase()
    )
  );
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, leaves.length);
  const pendingLeaves = filterLeaves.length > 0 && filterLeaves.slice(startIndex, endIndex);

  const handleLeave = async (e) => {
    e.preventDefault()
    setIsAdding(true)
    try {
      const res = await fetch(`${API_URL}/api/new-leave/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify({
          'leave_type': leaveType,
          'start_date': startDate,
          'end_date': endDate,
          'comment': comment
        })
      })
      const data = await res.json()
      if (!res.ok || data.error) {
        setMessage(data.error)
        setIsAdding(false)
        document.getElementById('form').reset()
      }
      else {
        setMessage(data.message)
        console.log(data.message)
        setIsAdding(false)
        setTimeout(() => {
          setMessage('')
          closePopup()
          document.getElementById('form').reset()
        }, 2000)
      }
    } catch (error) {
      console.error(error.message)
      setIsAdding(false)
    }
  };



  // useEffect(() => {
  //   const getLeaveTypes = async () => {
  //     try {
  //       const response = await api.get(`${API_URL}/api/list_leavetypes/`, {
  //         headers: {
  //           'Content-Type': 'application/json',
  //           'Authorization': `Bearer ${accessToken}`
  //         }
  //       });
  //       setLeaveTypes(response.data);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  //   getLeaveTypes();
  // }, [API_URL, accessToken]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return gettingAllLeaves ? '...' : (
    <div className="leaves-page">
      {
        showLeaveDetailsPopup
          ? <LeaveDetailsPopup leave={selectedLeave} setShowLeaveDetailsPopup={setShowLeaveDetailsPopup} />
          : ''
      }
      <div className="tabs">
        <div
          onClick={() => handleTabClick("leavesHistory")}
          className={`tab ${activeTab === "leavesHistory" ? "active" : ""}`}
        >
          <NotebookIcon size={24} variant={"stroke"} />
          <span>Leave history</span>
        </div>
        <div
          onClick={() => handleTabClick("pendingLeaves")}
          className={`tab ${activeTab === "pendingLeaves" ? "active" : ""}`}
        >
          <Time01Icon size={24} variant={"stroke"} />
          <span>Leave Requests</span>
        </div>
      </div>

      <div className="tabs-content">
        {activeTab === "leavesHistory"
          ? <LeavesHistory leaves={allLeaves} />
          : <PendingLeaves leaves={allLeaves} />}
      </div>
    </div>
  );
};

const LeavesPage = () => {
  return <DashboardContainerV2 content={<LeavesPageContent />} />;
};
export default LeavesPage;
