import React, { useRef, useEffect, useState } from 'react';
import axios from 'axios';
import { Editor } from '@tinymce/tinymce-react';
import DashboardContainerV2 from '../../../components/dashbaord/dashboardContainerV2.jx';

import '../../../styles/newBlog/newBlog.css'
import LoadingIcon from '../../../components/loadingIcon';
import { authorsArray } from '../../../dummyData/authors';
import { categoriesArray } from '../../../dummyData/categories';
import { tagsArray } from '../../../dummyData/tags';
import { Cancel01Icon } from 'hugeicons-react';
import BlogCategories from '../../../components/blog/newBlog/BlogCategories';
import BlogTags from '../../../components/blog/newBlog/BlogTag';
import BlogAuthors from '../../../components/blog/newBlog/blogAuthors';
import api, { API_URL, CSR_API_ENDPOINT } from '../../../utils/api';

// dummy_data
const NewBlogPageContent = () => {
    const editorRef = useRef(null);
    const [title, setTitle] = useState("")
    const [shortDescription, setShortDescription] = useState("")
    const [content, setContent] = useState("")
    const [image, setImage] = useState("")
    const [uploadingFeaturedImage, setUploadingFeaturedImage] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [savingDraft, setSavingDraft] = useState(false)
    const [successMessage, setSuccessMessage] = useState("")
    const [errorMessage, setErrorMessage] = useState("")

    // handle featured image
    const handleFeaturedImage = async (event) => {
        const image = event.target.files[0];
        if (image) {
            try {
                setUploadingFeaturedImage(true)
                const formData = new FormData()
                formData.append('file', image)
                const response = await axios.post(`${CSR_API_ENDPOINT}/blog/upload-images/`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });

                if (response.status === 200) {
                    setUploadingFeaturedImage(false)
                    setImage(response.data.urls)
                }
            } catch (error) {
                setUploadingFeaturedImage(false)
                console.log(error)
            }
        }
    }

    // tags
    const [blogTags, setBlogTags] = useState([])

    // categories
    const [blogCategories, setBlogCategories] = useState([])

    // author
    const [blogAuthors, setBlogAuthors] = useState()

    const handleEditorInit = (evt, editor) => {
        editorRef.current = editor;
    };

    const handleEditorChange = (content, editor) => {
        setContent(content);
    };
    const images_upload_handler = async (blobInfo, progress) => {
        try {
            const formData = new FormData();
            formData.append('file', blobInfo.blob(), blobInfo.filename());
            formData.append('folder', 'your-folder-name');

            const response = await axios.post(
                `${CSR_API_ENDPOINT}/blog/upload-images/`,
                formData,

            );
            console.log('image upload response: ', response);
            return response.data.urls;
        } catch (error) {
            throw new Error('Image upload failed: ' + error.message);
        }
    };

    const handleAuthentication = async () => {
        try {
            const response = await api.post(`${API_URL}/api/blog/new/`)
            if (response.status === 200) {
                console.log(response.data)
                console.log('Authentication successful')
                return response.data.access_token
            }
        } catch (error) {

            console.log(error)
            console.log('Authentication failed')
            return null

        }
    }

    const handleBlogSubmit = async () => {

        const blogData = {
            "title": title,
            "content": content,
            "featured_image": image,
            "authors": blogAuthors?.map(author => author),
            "short_description": shortDescription,
            "categories": blogCategories?.map(cat => cat),
            "tags": blogTags?.map(tag => tag)
        }
        console.log('blog', blogData)
        if (!title || !blogAuthors || !blogCategories || !blogTags || !content) {
            setErrorMessage('All fields are required')
            return
        }
        localStorage.setItem('blogContent', JSON.stringify(blogData))

        try {
            setIsLoading(true)
            console.log(blogData)
            // const response = await axios.post(`${CSR_API_ENDPOINT}/blog/new/`, blogData)
            // post the apove, but with access tone and bearer token
            const accessToken = await handleAuthentication()
            // post the blog data
            const response = await axios.post(`${CSR_API_ENDPOINT}/blog/new/`, blogData, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            })
            if (response.status === 201) {
                setSuccessMessage("Blog has been created successfully")
                localStorage.removeItem('blogContent')
                setIsLoading()
            }
        } catch (error) {
            setIsLoading(false)
            if (error.response.status === 400) {
                setErrorMessage(error.response.data.error)
            } else {
                setErrorMessage("Failed to create blog, contact your administrator")
            }
        }
    }

    const handleSaveDraft = () => {

        setSuccessMessage("Your draft has been saved")
        setSavingDraft(true)
        setTimeout(() => { setSavingDraft(false) }, 2000)
    }
    useEffect(() => {
        setTimeout(() => {
            setErrorMessage("")
            setSuccessMessage("")
        }, 15000)
    })

    return (
        <div className='new-blog-page'>
            <h2>Add a new blog</h2>
            {
                successMessage && <div className="success-message">{successMessage}</div>
            }
            {
                errorMessage && <div className="error-message">{errorMessage}</div>
            }
            <div className="form">
                <div className="main-content">
                    <div className="input">
                        <label htmlFor="blogTitle">Blog title</label>
                        <input value={title} onChange={(e) => setTitle(e.target.value)} type="text" placeholder='Blog title' />
                    </div>
                    <div className="input">
                        <label htmlFor="blogShortDescription">Short description</label>
                        <textarea value={shortDescription} onChange={(e) => setShortDescription(e.target.value)} placeholder='Blog short description' rows={5}></textarea>
                    </div>
                    <Editor
                        apiKey='3k3xpw9a7l3y44pt4eu18noai4kqytl7aqqy4z8fehu1193y'
                        onInit={handleEditorInit}
                        init={{
                            height: 500,
                            plugins: [
                                'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                'insertdatetime', 'media', 'table', 'help', 'wordcount'
                            ],
                            toolbar: 'undo redo | blocks | bold italic backcolor | alignleft aligncenter alignright alignjustify | image | removeformat | help',
                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                            images_upload_handler: images_upload_handler
                        }}
                        onEditorChange={handleEditorChange}
                    />
                </div>
                <div className="extra-content">
                    <div className="actions">
                        <button onClick={handleBlogSubmit} className="primary-button">
                            {
                                isLoading ?
                                    <LoadingIcon size={18} />
                                    : 'Publish'
                            }
                        </button>
                        <button onClick={handleSaveDraft} className="secondary-button">
                            {
                                savingDraft ?
                                    <LoadingIcon size={18} />
                                    : 'Save draft'
                            }
                        </button>
                    </div>
                    <div className="blog-featured-image">
                        <label htmlFor="blogFeaturedImage">Featured image</label>
                        <div className="featured-image-placeholder">
                            {
                                image && <img src={image}></img>
                            }
                            {
                                uploadingFeaturedImage ?
                                    <LoadingIcon size={18} />
                                    : ''
                            }
                            <input onChange={(event) => handleFeaturedImage(event)} type="file" id="blogFeaturedImage" name="blogFeaturedImage" accept="image/*" />
                        </div>
                        <img src="" alt="" />
                    </div>


                    <BlogAuthors setBlogAuthors={setBlogAuthors} blogAuthors={blogAuthors} />

                    <BlogCategories setBlogCategories={setBlogCategories} blogCategories={blogCategories} />
                    <BlogTags setBlogTags={setBlogTags} blogTags={blogTags} />

                </div>

            </div>

        </div>
    );
};



const NewBlogPage = () => {
    return (
        <div>
            <DashboardContainerV2 content={<NewBlogPageContent />} />
        </div>
    );
};

export default NewBlogPage;

// create a contact form component
