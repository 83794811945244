import { Navigate, Outlet } from "react-router-dom";
import AuthService from "../services/auth";

const ProtectedRoute = () => {
  return (
    AuthService.isAuthenticated() ? <Outlet /> : <Navigate to='/login' />
  )
};

export default ProtectedRoute;
