import React, { useState, useEffect } from "react";
import FormattedDate from "../formattedDate";
import Nodata from "../Nodata";
import LeaveDetailsPopup from "./leaveDetails";

const PendingLeaves = ({ leaves }) => {
    const [pendingLeaves, setPendingLeaves] = useState([])
    const [showLeaveDetailsPopup, setShowLeaveDetailsPopup] = useState(false)
    const [selectedLeave, setSelectedLeave] = useState({})
    const handleLeavePopup = (leave) => {
        setShowLeaveDetailsPopup(true)
        setSelectedLeave(leave)
    }

    useEffect(() => {
        const pendingLeaves = leaves.filter((leave) => leave.status_name === 'pending')
        setPendingLeaves(pendingLeaves)
    }, [leaves])
    return (
        <section className="employees-section">
            {
                showLeaveDetailsPopup
                    ? <LeaveDetailsPopup leave={selectedLeave} setShowLeaveDetailsPopup={setShowLeaveDetailsPopup} />
                    : ''
            }
            <div className="row">
                <div className="col col-2">
                    <section className="contents">
                        <div className="underline"></div>
                        <div className="contents-container">
                            <div className="title-contents">
                                <div className="top">
                                    <div className="col-title">
                                        <h4>Leave</h4>
                                        <p>{pendingLeaves && pendingLeaves.length} Leave(s)</p>
                                    </div>
                                    {/* <div className="input-fields">
                                        <div className="input">
                                            <input
                                                type="text"
                                                value={searchQuery}
                                                onChange={handleSearch}
                                                placeholder="Employee Name...."
                                            />
                                            <div className="icon">
                                                <FaSearch />
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                            <div className="table-contents">
                                {
                                    <>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Employee Name</th>
                                                    <th>Leave Type</th>
                                                    <th>Start Date</th>
                                                    <th>End Date</th>
                                                    <th>Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {pendingLeaves && pendingLeaves.length > 0 ? (
                                                    pendingLeaves.map((leave) => (
                                                        <tr onClick={() => handleLeavePopup(leave)} key={leave.id}>
                                                            <td className='hidden-table-col'>{leave.employee_name.name}</td>
                                                            <td>{leave.leave_type.name}</td>
                                                            <td><FormattedDate date={leave.start_date} /></td>
                                                            <td className="hidden-table-col"><FormattedDate date={leave.end_date} /></td>
                                                            <td className="hidden-table-col">{leave.status_name}</td>
                                                        </tr>
                                                    ))
                                                ) : <Nodata
                                                    style={`no-data`}
                                                    title="No leave available..."
                                                />
                                                }
                                            </tbody>
                                        </table>

                                    </>
                                }
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </section>
    )
}

export default PendingLeaves
