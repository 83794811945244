import React, {useState, useEffect} from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import '../styles/calendar/calendar.css'
import { useGlobalContext } from '../context/Context'
import AuthService from '../services/auth'
import Nodata from './Nodata'



const CalendarDetails = () => {
  const [leavesApproved, setAprrovedLeaves] = useState([])
  const [currentViewDate, setCurrentViewDate] = useState(new Date())

  const { currentPage, handlePage, API_URL } = useGlobalContext();

  const itemsPerPage = 5

  const startIndex = (currentPage - 1) * itemsPerPage
  const endIndex = Math.min(startIndex + itemsPerPage, leavesApproved.length)
  const currentLeaves = leavesApproved.slice(startIndex, endIndex)
  const accessToken = AuthService.getToken()

  const events = leavesApproved.map((leave) => {
    const startDate = new Date(leave.start_date)
    startDate.setHours(0, 0, 0, 0)
    return {
      title: `${leave.employee_name.user.first_name} ${leave.employee_name.user.last_name}`,
      date: startDate,
      hasEvent: true,
    }
  })
  
  useEffect(() => {
    const getLeavesData = async () => {
      const res = await fetch(`${API_URL}/api/get_leave`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        }
      })
      const data = await res.json()
      setAprrovedLeaves(data)
    }
    getLeavesData()
  }, [])

  const getUpcomingLeaves = () => {
    const nextMonth = new Date(currentViewDate)
    nextMonth.setMonth(nextMonth.getMonth() + 1)
    const startOfNextMonth = new Date(nextMonth.getFullYear(), nextMonth.getMonth(), 1)
    const endOfNextMonth = new Date(nextMonth.getFullYear(), nextMonth.getMonth() + 1, 0)

    return currentLeaves.filter((leave) => {
      const leaveStartDate = new Date(leave.start_date)
      return leaveStartDate >= startOfNextMonth && leaveStartDate <= endOfNextMonth
    })
  }

  const upcomingLeaves = getUpcomingLeaves()

  const eventContent = (eventInfo) => {
    return (
      <div className='custom-event'>
        <p className='event-title'>{eventInfo.event.title}</p>
      </div>
    );
  };




  return (
    <div className="my-calendar">
      <div className="leaves-schedule">
        <div className="table-content">
          <FullCalendar
            plugins={[dayGridPlugin]}
            initialView="dayGridMonth"
            events={events}
            eventContent={eventContent}
          />
        </div>
        <div className='upcoming-leaves'>
          <div className="title">
            <h3>Upcoming leaves</h3>
            <p>{upcomingLeaves.length} Upcoming leave(s)</p>
          </div>
          <div className="leave-details">
            {upcomingLeaves && upcomingLeaves.length > 0 ? (
              upcomingLeaves.map((leave) => (
                <div key={leave.id} className='leave'>
                  <h4>{leave.employee_name.user.first_name} {leave.employee_name.user.last_name}</h4>
                  <p>{new Date(leave.start_date).toLocaleDateString()} - {new Date(leave.end_date).toLocaleDateString()}</p>
                </div>
              ))
            ) : (
              <Nodata style={`no-data`} title='No upcoming leaves' />
            )}
            
          </div>
          <div className="pagination">
            <button
              className='prev-btn'
              onClick={() => handlePage(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            {Array.from({ length: Math.ceil(currentLeaves.length / itemsPerPage) }, (_, i) => (
              <button
                key={i + 1}
                className={`page ${currentPage === i + 1 ? 'active' : ''}`}
                onClick={() => handlePage(i + 1)}
              >
                {i + 1}
              </button>
            ))}
            <button
              className='next-btn'
              onClick={() => handlePage(currentPage + 1)}
              disabled={endIndex >= leavesApproved.length}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CalendarDetails;

