// global context
import { useGlobalContext } from "../context/Context";

// navigation
import { useNavigate } from "react-router-dom";

// icons
import { FaShieldAlt } from "react-icons/fa";
// import { MdOutlineWindow } from "react-icons/md";
import { LiaDumbbellSolid } from "react-icons/lia";
import { IoSettingsOutline, IoFolderOpenOutline } from "react-icons/io5";
import { BsBoxArrowInRight } from "react-icons/bs";

import { Link, useLocation } from 'react-router-dom';
import AuthService from "../services/auth";
import { useEffect, useState } from "react";

const Sidebar = () => {
  const [isLoading, setIsLoading] = useState(false)
  const location = useLocation()
  const { isMobileSidebarOpen, closeSidebar } = useGlobalContext()
  const navigate = useNavigate()

  const handleLogout = () => {
    try {
      AuthService.logout()
      navigate('/login')
    } catch (error) {
      console.error(error)
    }
  }
  const permissions = localStorage.getItem('permissions')
  const userPermissions = permissions ? JSON.parse(permissions) : []
  const canAccessTrainingCenter = userPermissions ? userPermissions.includes('Management') || userPermissions.includes('Director') || permissions.includes('Training center director') || permissions.includes('Developer') : false;

  return (
    <>
      {isMobileSidebarOpen ? (
        <div className='mobile-sidebar'>
          <div className='links-container'>
            <div className='branding'>
              <img src="/images/logo2.svg" alt="logo" />
            </div>
            <ul className='links'>
              {/* <li className='link'>
                <MdOutlineWindow />
                <Link onClick={closeSidebar}>
                  Overview
                </Link>
              </li> */}
              <li className={location.pathname === '/' || location.pathname === '/leave-requests' ? 'link active' : 'link'}>
                <LiaDumbbellSolid />
                <Link to='/' onClick={closeSidebar}> Leave Records</Link>
              </li>
              <li className={location.pathname === '/projects' || location.pathname.startsWith('/project-details') ? 'link active' : 'link'}>
                <IoFolderOpenOutline />
                <Link to='/projects'> Projects</Link>
              </li>
              <li className={location.pathname === '/employees' ? 'link active' : 'link'}>
                <FaShieldAlt />
                <Link to='/employees' onClick={closeSidebar}>
                  Employees Records
                </Link>
              </li>
              {
                canAccessTrainingCenter
                  ? <li className={location.pathname === '/training-center/' ? 'link active' : 'link'}>
                    <FaShieldAlt />
                    <Link to='/training-center/' onClick={closeSidebar}>
                      Training Center
                    </Link>
                  </li>
                  : ''
              }

              <li className='link'>
                <IoSettingsOutline />
                <Link onClick={closeSidebar}>
                  Settings
                </Link>
              </li>
            </ul>
          </div>
          <div className='logout'>
            <button onClick={handleLogout}>
              <BsBoxArrowInRight />
              Logout
            </button>
          </div>
        </div>
      ) : (
        <div className='sidebar'>
          <div className='links-container'>
            <div className='branding'>
              <img src="/images/logo2.svg" alt="logo" />
            </div>
            <ul className='links'>
              {/* <li className='link'>
                <MdOutlineWindow />
                <Link to='/'>Overview</Link>
              </li> */}
              <li className={location.pathname === '/' || location.pathname === '/leave-requests' ? 'link active' : 'link'}>
                <LiaDumbbellSolid />
                <Link to='/'> Leave Records</Link>
              </li>
              <li className={location.pathname === '/projects' || location.pathname.startsWith('/project-details') ? 'link active' : 'link'}>
                <IoFolderOpenOutline />
                <Link to='/projects'> Projects</Link>
              </li>
              <li className={location.pathname === '/employees' ? 'link active' : 'link'}>
                <FaShieldAlt />
                <Link to='/employees'>Employees Records</Link>
              </li>
              {
                canAccessTrainingCenter
                  ? <li className={location.pathname === '/training-center/' ? 'link active' : 'link'}>
                    <FaShieldAlt />
                    <Link to='/training-center/' onClick={closeSidebar}>
                      Training Center
                    </Link>
                  </li>
                  : ''
              }

              <li className='link'>
                <IoSettingsOutline />
                <Link className="/settings">Settings</Link>
              </li>
            </ul>
          </div>
          <div className='logout'>
            <button onClick={handleLogout}>
              <BsBoxArrowInRight />
              Logout
            </button>
          </div>
        </div>
      )}
    </>
  )
}

export default Sidebar
