import React, { useEffect, useState } from 'react'
import DashboardContainerV2 from '../../../../components/dashbaord/dashboardContainerV2.jx'
import api, { API_URL } from '../../../../utils/api'
import { useParams } from 'react-router-dom'
import TrainingDetailsPageHeader from '../../../../components/trainingCenter/details/pageHeader'
import CohortDetailsTabs from '../../../../components/trainingCenter/details/cohortDetailsTabs'

const CohortDetailsPageContent = () => {
  const [cohortDetails, setCohortDetails] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [failedFetching, setFailedFetching] = useState(false)
  const { cohortId } = useParams()
  useEffect(() => {
    // Fetch cohort details from API or local storage
    const fetchCohorts = async () => {

      try {
        const response = await api.get(`${API_URL}/api/training-center/cohorts/${cohortId}/`)
        console.log(response.data)
        if (response.status === 200) {
          setCohortDetails(response.data)
          setIsLoading(false)
        }
      } catch (error) {
        console.log(error)
        setIsLoading(false)
        setFailedFetching(true)
      }
    }
    fetchCohorts()
  }, [])
  return isLoading ? '...Loading' : failedFetching ? '...Error getting data' : (
    <div className='cohort-details-page'>
      <div className="breadcrumbs">
        <span>Home / </span>
        <span>Training Center / </span>
        <span className='current-link'>{cohortDetails.title}</span>
      </div>
      <TrainingDetailsPageHeader 
        cohortDetails={cohortDetails} 
      />
       <CohortDetailsTabs cohortDetails={cohortDetails} />
    </div>
  )
}
const CohortDetailsPage = () => {
  return (
    <DashboardContainerV2 content={<CohortDetailsPageContent />} />
  )
}

export default CohortDetailsPage
