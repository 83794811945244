import React from "react";

// styles
import '../styles/projects/projectDetails.css';
import '../styles/projects/loader/projectDetailsLoader.css';

import DashboardContainerV2 from "./dashbaord/dashboardContainerV2.jx";
import ProjectDetailsContent from "./projects/projectDetals/projectDetailsContent";

const ProjectDetails = () => {
    return (
        <DashboardContainerV2 content={<ProjectDetailsContent />} />
    )
}
export default ProjectDetails;

