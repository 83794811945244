import React from 'react';

const DepartmentCard = ({cohortDetails}) => {
    const departments = cohortDetails?.overview?.departments || [];
    
   
    const lineColors = ['#000000', '#F87C47', '#FBC6B0', '#145C9E', '#5C98CF', "#D2E1EE"];


    const getLineStyle = (color) => ({
        backgroundColor: color,
        width: '8px',  
        height: '16px', 
        borderRadius: '8px'
    });

    return (
        <div className='department-main'>
            <div className="department-header">
                <h1>Department</h1>
                <h4>VIEW ALL</h4>
            </div>
            
            <div className="color-lines">
                <div className="color1"></div>
                <div className="color2"></div>
                <div className="color3"></div>
                <div className="color4"></div>
                <div className="color5"></div>
            </div>
            
            <div className="department-content">
                <div className="department-text">
                <div 
                        className="department-line" 
                        style={getLineStyle(lineColors[0])}
                    ></div>
                    <div className="department-txt-num">
                        <h3>Total <br /> trainees</h3>
                        <div className="depart-num-percentage">
                            <h4>{cohortDetails.trainees}</h4>
                            <p>100%</p>
                        </div>
                    </div>
                </div>
                {departments.map((department, index) => (
                    <div className="department-text" key={index}>
                       <div 
                            className="department-line" 
                            style={getLineStyle(lineColors[(index + 1) % lineColors.length])}
                        ></div>
                        <div className="department-txt-num">
                            <h3>{department.name}</h3>
                            <div className="depart-num-percentage">
                                <h4>{department.trainees}</h4>
                                <p>{department.completion_percentage}%</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default DepartmentCard;
