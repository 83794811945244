import axios from "axios";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { useAuthentication } from "../context/authContext";
import { useState } from "react";

// production api
const API_URL = process.env.REACT_APP_API_URL

const AuthService = {
    login: async (email, password) => {
        try {
            const res = await axios.post(`${API_URL}/api/token/`, { email, password });

            if (res.data.access && res.data.refresh) {
                Cookies.set('accessToken', res.data.access, { secure: true });
                Cookies.set('refreshToken', res.data.refresh, { secure: true });
                AuthService.startTokenExpiryChecker()
                return res.data;
            } else {
                return { error: res.data.error };
            }
        } catch (error) {
            if (error.response && error.response.data && error.response.data.error) {
                return { error: error.response.data.error };
            } else {
                return { error: 'Something went wrong' };
            }
        }
    },
    checkPermissions: async (setIsLoading) => {
        const accessToken = AuthService.getToken();
        if (accessToken) {
            setIsLoading(true);
            try {
                console.log('Checking permissions')
                const res = await axios.get(`${API_URL}/api/get_user_info/v2/`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`
                    }
                });
                console.log("Got permissions")
                localStorage.setItem('permissions', JSON.stringify(res.data.permissions))
                return res.data.permissions;
            } catch (error) {
                console.error(error);
                return [];
            } finally {
                setIsLoading(false);
            }
        } else {
            return [];
        }
    },

    logout: () => {
        Cookies.remove('accessToken');
        Cookies.remove('refreshToken');
        clearInterval(AuthService.tokenExpiryInterval)
    },

    getToken: () => {
        return Cookies.get('accessToken');
    },

    isAuthenticated: () => {
        const accessToken = Cookies.get('accessToken');
        return accessToken && AuthService.isTokenValid(accessToken)
    },

    isTokenValid: (token) => {
        if (!token) return false;

        try {
            const decoded = jwtDecode(token);
            const expiryTime = Date.now() / 1000
            return decoded.exp > expiryTime;
        } catch (error) {
            return false;
        }
    },

    checkTokenExpiry: () => {
        const token = Cookies.get('accessToken')
        if (token && !AuthService.isTokenValid(token)) {
            console.log("Token expired. Logging out user...")
            AuthService.logout()
            alert('Your session has expired. You need to login')
        }
    },

    startTokenExpiryChecker: () => {
        AuthService.checkTokenExpiry()
        AuthService.tokenExpiryInterval = setInterval(AuthService.checkTokenExpiry, 60000)
    },

    tokenExpiryInterval: null
};

export default AuthService;