import React from 'react';
import '../../styles/canteen/category.css'

export const CategoryItem = ({ imageSource, label, isActive, onClick }) => {
    const handleImageError = (e) => {
        e.target.src = '/images/image-placeholder.svg'

    }
    console.log("image source: ", imageSource)
    return (
        <div className={`category-item ${isActive ? 'active' : ''}`} onClick={onClick}>
            <div className="image">
                <img src={imageSource} alt={`${label} icon`} onError={handleImageError}  />
            </div>
            <a href="#">{label}</a>
        </div>
    )
}


const categoryData = {
    categories: [{name: 'All', image: '/images/'}, {name: 'Fanta', image: '/images/'}, {name: 'Juice', image: '/images/'}, {name: 'Coffee', image: '/images/'}, {name: 'Snacks', image: '/images/'}]
}
export const Categories = ({activeCategory, setActiveCategory}) => {
    const handleCategoryClick = (index) => {
        setActiveCategory(index);
      }
      if (!Array.isArray(categoryData.categories)) {
        console.error('categoryData is not an array:', categoryData);
        return null;
      }
  
    return (
      <>
        <div className="category">
          {categoryData.categories.map((category, index) => (
            <CategoryItem
              key={index}
              imageSource={category.image}
              label={category.name}
              isActive={index === activeCategory}
              onClick = {() => handleCategoryClick(index)}
            />
          ))}
        </div>
      </>
    );
  };

export default Categories;